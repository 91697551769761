import { Badge } from "react-bootstrap";
import noImage from "../../../../images/no-image.jpg";

export function UnitDetailsComponent({
  PropertyData,
  showAllUnits,
  handleHideAllClick,
  handleSeeAllClick,
}) {
  // console.log(PropertyData);
  return (
    <div className="col-12">
      <div className="">
        <div className="card-header mb-0 border-0 pb-0 d-flex justify-content-between align-items-center">
          <h3 className="fs-20 text-black mb-0">Rooms Details</h3>
          {PropertyData.units?.length > 2 && (
            <p
              className="text-blue light text-decoration-underline c-pointer"
              onClick={showAllUnits ? handleHideAllClick : handleSeeAllClick}
            >
              {showAllUnits ? "Hide All" : "See All"}
            </p>
          )}
        </div>
        <div className="row card-body">
          {PropertyData.units && PropertyData.units.length > 0 ? (
            PropertyData.units.map((unit, index) => (
              // <Link
              <div
                // to={`unit-details/${unit?._id}`}
                className="col-xl-3 col-xxl-4 col-md-6 col-sm-6 col-lg-4 m-b30"
                key={index}
              >
                <div
                  className={` border rounded py-2 ${
                    showAllUnits || index < 2 ? "visible" : "hidden"
                  }`}
                >
                  <img
                    alt=""
                    src={unit?.images ? unit?.images : noImage}
                    style={{
                      width: "100%",
                      height: "250px",
                      objectFit: `${unit?.images ? "cover" : "contain"}`,
                    }}
                  />
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="mb-0 title">Room name</span> :
                      <span className="text-black ms-2">{unit?.name}</span>
                    </li>
                    <li className="list-group-item">
                      <span className="mb-0 title">Status</span> :
                      <span className="text-black ms-2">
                        <Badge
                          href=""
                          bg="badge-rounded"
                          className={`${
                            unit?.active
                              ? "badge-outline-success"
                              : "badge-outline-danger"
                          }`}
                        >
                          {unit?.active ? "Active" : "Inactive"}
                        </Badge>
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span className="mb-0 title">Type</span> :
                      <span className="text-black desc-text ms-2">
                        {unit?.type}
                      </span>
                    </li>
                    <li className="list-group-item">
                      <span className="mb-0 title">Description</span> :
                      <span className="text-black desc-text ms-2">
                        <a
                          href={PropertyData?.location_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {unit?.description}
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            ))
          ) : (
            <div className="col-lg-12">
              <div
                className={`card-body d-flex justify-content-center align-items-center h-100`}
              >
                <p className="d-flex justify-content-center align-items-center h-100">
                  No units found.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
