// dashboardReducer.js

import { REQUEST_START, UPDATE_USERS_LIST_DATA } from "../actions/UsersAction";

const initialState = {
  loading: false,
  usersList: [],
};

const usersReducer = (state = initialState, action) => {
  if (action.type === REQUEST_START) {
    return {
      ...state,
      loading: true,
    };
  } else if (action.type === UPDATE_USERS_LIST_DATA) {
    return {
      ...state,
      usersList: action.payload,
      loading: false,
    };
  } else {
    return {
      ...state,
    };
  }
};

export default usersReducer;
