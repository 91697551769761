import axiosInstance from "./AxiosInstance";

export function getSupervisorListData() {
  return axiosInstance.get("/api/supervisors");
}

export function getSupervisorDetails(id) {
  return axiosInstance.get(`/api/supervisors/${id}`);
}

export function getSupervisorTransactions(id, type, pgNo) {
  return axiosInstance.get(
    `api/supervisors/transactions/${id}?type=${
      type ? "expense" : "income"
    }&page=${pgNo}`
  );
}

export function getSupervisorPropertyList(id, pgNo) {
  return axiosInstance.get(`/api/property?page=${pgNo}&user=${id}`);
}

export function addMoneyToSupervisorWallet(userData) {
  return axiosInstance.post(`/api/add-money`, userData);
}

export function getNotApprovedTransactionList() {
  return axiosInstance.get(`/api/transactions/not-approved`);
}

export function approveTransaction(postData) {
  console.log(
    `/api/transactions/approve?id=${postData.id}${
      !postData.approved ? "&reject=true" : ""
    }`
  );
  return axiosInstance.get(
    `/api/transactions/approve?id=${postData.id}${
      !postData.approved ? "&reject=true" : ""
    }`
  );
}
