import React, { useEffect, useState } from "react";
import "./filtering.css";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PropertyAction } from "../../../../store/actions/PropertryActions";
import {
  AssignUserToProperty,
  getUserDetails,
} from "../../../../services/UsersServices";

const SelectPropertyForUsers = () => {
  const dispatch = useDispatch();
  const { properties, loading } = useSelector((state) => state.properties);
  const nav = useNavigate();

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useEffect(() => {
    dispatch(PropertyAction("all"));
    getUserDetails(path)
      .then((res) => {
        console.log(res);
        // Assuming the user details include the initially selected properties
        const initialProperties = res.data?.properties || [];
        setSelectedPropertyIds(
          initialProperties.map((property) => property._id)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  const [selectedPropertyIds, setSelectedPropertyIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSelectionChange = (propertyId) => {
    setSelectedPropertyIds((prevSelectedPropertyIds) => {
      if (prevSelectedPropertyIds.includes(propertyId)) {
        return prevSelectedPropertyIds.filter((id) => id !== propertyId);
      } else {
        return [...prevSelectedPropertyIds, propertyId];
      }
    });
  };

  const filteredProperties = properties?.data?.filter((property) => {
    return (
      property.property_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      property.property_number
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      property._id.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const onSubmitProperty = () => {
    setIsLoading(true);
    if (selectedPropertyIds.length === 0) {
      setIsLoading(false);
      return toast.error("Select at least one property");
    }
    const postData = {
      user: path,
      properties: selectedPropertyIds,
    };
    console.log(postData);

    AssignUserToProperty(postData)
      .then((res) => {
        console.log(res);
        toast.success("Properties Assigned Successfully");
        nav("/users-list");
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  };

  if (loading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card-header ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            width: "100%",
          }}
        >
          <div
            className="d-flex gap-4 align-items-center"
            style={{ width: "350px" }}
          >
            <h4 className="card-title">Search</h4>

            <input
              type="text"
              className="form-control input-rounded"
              placeholder="Search by Name, Number, or ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="text-end toolbar toolbar-bottom p-2">
            <button
              className={`${
                selectedPropertyIds.length > 0 ? "" : "disabled"
              } btn btn-success px-4`}
              onClick={() => onSubmitProperty()}
            >
              Assign
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="w-100 table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th></th> {/* Checkbox column */}
                <th>Property Name</th>
                <th>Property Number</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {filteredProperties?.map((property) => (
                <tr
                  key={property._id}
                  onClick={() => handleSelectionChange(property._id)}
                >
                  <td>
                    <input
                      type="checkbox"
                      onClick={() => handleSelectionChange(property._id)}
                      checked={selectedPropertyIds.includes(property._id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectionChange(property._id);
                      }}
                    />
                  </td>
                  <td>{property?.property_name}</td>
                  <td>{property?.property_number}</td>
                  <td>
                    {property?.users
                      .map((user) =>
                        Array.isArray(user?.name)
                          ? user.name.join(",")
                          : user.name
                      )
                      .join(", ")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SelectPropertyForUsers;
