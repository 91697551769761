import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { UsersListAction } from "../../../store/actions/UsersAction";
import ConfirmUsersActionModal from "./ConfirmUsersActionModal";
import { deleteUser, freezeUser } from "../../../services/UsersServices";

function UsersList() {
  const dispatch = useDispatch();
  const { usersList, loading } = useSelector((state) => state.users);
  const [modalCentered, setModalCentered] = useState(false);
  const [modalType, setModalType] = useState([]);
  const [selectedUser, setselectedUser] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const [sortBy, setSortBy] = useState("all");
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    dispatch(UsersListAction(searchQuery, pageNo, sortBy));
  }, [dispatch, pageNo, searchQuery, sortBy]);

  console.log(usersList);

  const totalPage = Math.ceil(usersList?.total_documents / 10);

  const nav = useNavigate();

  // Pagination logic
  const handlePageChange = (isPrev) => {
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1); // Ensure page number is not less than 1
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //actions

  const onFreeze = (id) => {
    freezeUser(id).then((res) => {
      //   console.log(res);
      toast.success(res.data.message);
      dispatch(UsersListAction(sortBy));
    });
  };

  const onDelete = (id) => {
    deleteUser(id).then((res) => {
      toast.success("User deleted successfully");
      dispatch(UsersListAction(sortBy));
    });
  };

  // if (loading) {
  //   return (
  //     <div id="preloader">
  //       <div className="sk-three-bounce">
  //         <div className="sk-child sk-bounce1"></div>
  //         <div className="sk-child sk-bounce2"></div>
  //         <div className="sk-child sk-bounce3"></div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center ">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Users List</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link>Users</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/users-list">Users List</Link>
            </li>
          </ol>
        </div>
        <div className="d-inline-flex gap-3">
          {/* <UserExportButton />
           */}

          <Link to="export">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="excel"
              style={{ width: "28px", height: "38px" }}
            >
              <path
                fill="#303c42"
                d="M23.5 3H14V.5a.5.5 0 0 0-.59-.5l-13 2.5A.5.5 0 0 0 0 3v18a.5.5 0 0 0 .41.49l13 2.5h.09a.5.5 0 0 0 .5-.5V21h9.5a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5ZM8.95 16.28a.5.5 0 0 1-.89.45L6.5 13.62l-1.55 3.1a.5.5 0 0 1-.89-.45l1.88-3.77-1.89-3.78a.5.5 0 0 1 .89-.45l1.56 3.11 1.55-3.1a.5.5 0 0 1 .89.45L7.06 12.5ZM23 20h-9v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14V7h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14V4h9Z"
              ></path>
              <rect
                width="4"
                height="2"
                x="18"
                y="5"
                fill="#303c42"
                rx=".5"
                ry=".5"
              ></rect>
              <rect
                width="4"
                height="2"
                x="18"
                y="8"
                fill="#303c42"
                rx=".5"
                ry=".5"
              ></rect>
              <rect
                width="4"
                height="2"
                x="18"
                y="11"
                fill="#303c42"
                rx=".5"
                ry=".5"
              ></rect>
              <rect
                width="4"
                height="2"
                x="18"
                y="14"
                fill="#303c42"
                rx=".5"
                ry=".5"
              ></rect>
              <rect
                width="4"
                height="2"
                x="18"
                y="17"
                fill="#303c42"
                rx=".5"
                ry=".5"
              ></rect>
            </svg>
          </Link>

          <Link
            onClick={() => {
              window.location.reload();
            }}
            to="/fund-requests"
            className="btn btn-primary rounded light me-3"
          >
            Refresh
          </Link>
        </div>
        {/* <Link to="/fund-requests" className="btn btn-primary rounded">
          <i className="flaticon-381-settings-2 me-0" />
        </Link> */}
      </div>

      <div className="row">
        <div className="col-12">
          <div className="widget-stat card">
            <div className="card-body p-4 card-d gap-4">
              <div className="media ai-icon ">
                <span className="me-3 bgl-success text-success">
                  <span className="">
                    <i className="flaticon-381-user-7"></i>
                  </span>
                </span>
                <div className="media-body">
                  <p className="mb-1">Total Users</p>
                  <h4 className="mb-0">{usersList?.users?.length}</h4>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => {
                    nav("add-user");
                  }}
                >
                  <span className="btn-icon-start text-success">
                    <i className="fa fa-plus color-success" />
                  </span>
                  Add new user
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <Row className="mb-2 w-100 col-12 justify-content-end">
            <ConfirmUsersActionModal
              modalCentered={modalCentered}
              setModalCentered={setModalCentered}
              modalType={modalType}
              onFreeze={onFreeze}
              selectedUser={selectedUser}
              onDelete={onDelete}
            />

            <div className="d-lg-inline-flex  h-100 justify-content-between align-content-center w-100">
              <div
                className="d-flex  gap-4 align-items-center justify-content-start py-4 "
                style={{ width: "100%" }}
              >
                <h4 className="card-title">Search</h4>

                <input
                  type="text"
                  className="form-control input-rounded w-100"
                  placeholder="Search by Email"
                  value={searchQuery}
                  onChange={(e) => {
                    setPageNo(1);
                    setSearchQuery(e.target.value);
                  }}
                />
              </div>
              <Dropdown className="w-100 d-inline-flex justify-content-end  align-items-center">
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="sm"
                  className="mt-1 mb-2 text-uppercase"
                >
                  Sort by : {sortBy}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSortBy("all")}>
                    Sort by : ALL{" "}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortBy("sales")}>
                    Sort by : Sales{" "}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortBy("supervisor")}>
                    Sort by : Petty user{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Row>
          {loading ? (
            // <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          ) : (
            // </div>
            <div className="table-responsive table-hover fs-14 order-list-table h-auto  pb-5 ">
              <div id="orderList" className="dataTables_wrapper no-footer">
                <table
                  className="table display table-hover display mb-4 dataTablesCard card-table dataTable no-footer"
                  id="example5"
                >
                  <thead>
                    <tr role="row">
                      {/* <th className='orderList_sorting_asc'>
                                            <div className='form-check custom-checkbox ms-2'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='checkAll'
                                                    required
                                                // onClick={() => chackboxFun('all')}
                                                />
                                                <label
                                                    className='form-check-label'
                                                    htmlFor='checkAll'
                                                />
                                            </div>
                                        </th> */}
                      <th className="">Name</th>
                      <th className="">Email</th>
                      {/* <th className=''>Customer</th> */}
                      <th className="">Role</th>
                      {/* <th className=''>Location</th> */}

                      <th className="">Wallet balance</th>
                      <th className="">Status</th>
                      {/* <th className=''>Type</th> */}
                      {/* <th className="">Properties</th> */}
                      {/* <th className="sorting">Status</th> */}
                      <th className="">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.users?.length > 0 ? (
                      usersList?.users?.map((item, index) => (
                        <tr
                          key={index}
                          // className={`${item?.active ? "" : "disabled-row"}`}
                        >
                          <td>{item?.user?.name}</td>
                          <td>{item?.user?.email}</td>
                          {/* <td>{item.customer}</td> */}
                          {/* <td>{item?.active ? "Active " : "not active"}</td> */}

                          {/* <td>{item.location}</td> */}
                          <td>{item?.user?.role}</td>
                          {/* <td>{item.type}</td> */}
                          {/* <td>{item.agent}</td> */}
                          <td
                            className={
                              item?.wallet_balance < 0
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {item?.wallet_balance?.toFixed(2)}
                          </td>
                          <td>
                            <span
                              className={`text-${
                                item?.user?.active ? "success" : "danger"
                              }`}
                            >
                              {item?.user?.active ? "Active " : "Deactivate"}
                            </span>
                          </td>

                          <td>
                            <Dropdown className="ms-auto">
                              <Dropdown.Toggle
                                variant=""
                                className="btn-link i-false"
                              >
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                                    stroke="#3E4954"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-top">
                                {item?.user?.active && (
                                  <>
                                    <Dropdown.Item
                                      className="text-black"
                                      onClick={() => {
                                        nav(
                                          `/users-list/assign-property/${item?.user?._id}`
                                        );
                                      }}
                                    >
                                      Assign property
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      className="text-black"
                                      onClick={() => {
                                        nav(
                                          `/users-list/edit/${item?.user?._id}`
                                        );
                                      }}
                                    >
                                      Edit User
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="text-black"
                                      onClick={() => {
                                        setModalCentered(true);

                                        setModalType("delete_user");

                                        setselectedUser(item.user?._id);
                                      }}
                                    >
                                      Delete User
                                    </Dropdown.Item>
                                  </>
                                )}
                                <Dropdown.Item
                                  className="text-black"
                                  onClick={() => {
                                    setModalCentered(true);
                                    if (item.user?.active) {
                                      setModalType("deactivate_user");
                                    } else {
                                      setModalType("activate_user");
                                    }
                                    setselectedUser(item.user?._id);
                                  }}
                                >
                                  {item.user?.active
                                    ? "Deactivate Account"
                                    : "Activate Account"}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No Requests</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* Pagination */}
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {usersList?.length} of {usersList?.total_documents}{" "}
                    entries
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers">
                    <Link
                      className="paginate_button previous"
                      to="#"
                      onClick={() => handlePageChange(true)}
                    >
                      <i className="fa fa-angle-double-left" />
                    </Link>
                    <span>
                      {(() => {
                        let pages = [];
                        if (totalPage <= 5) {
                          // If total pages are 5 or less, show all
                          for (let i = 1; i <= totalPage; i++) {
                            pages.push(i);
                          }
                        } else {
                          // Always show first page
                          pages.push(1);

                          // If current page is among first 3 pages
                          if (pageNo <= 3) {
                            for (let i = 2; i <= 5; i++) {
                              pages.push(i);
                            }
                          }
                          // If current page is among last 3 pages
                          else if (pageNo > totalPage - 3) {
                            pages.push("...");
                            for (let i = totalPage - 3; i <= totalPage; i++) {
                              pages.push(i);
                            }
                          }
                          // For all other cases
                          else {
                            pages.push("...");
                            for (let i = pageNo - 1; i <= pageNo + 1; i++) {
                              pages.push(i);
                            }
                            if (pageNo + 2 < totalPage) {
                              pages.push("...");
                            }
                            if (pageNo + 1 < totalPage) {
                              pages.push(totalPage);
                            }
                          }
                        }

                        return pages.map((page, index) => (
                          <Link
                            key={index}
                            to="#"
                            className={`paginate_button ${
                              pageNo === page ? "current" : ""
                            }`}
                            onClick={() => {
                              if (typeof page === "number") {
                                setPageNo(page);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }
                            }}
                          >
                            {page}
                          </Link>
                        ));
                      })()}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="#"
                      onClick={() => {
                        if (usersList && totalPage > pageNo) {
                          handlePageChange(false);
                        }
                      }}
                    >
                      <i className="fa fa-angle-double-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UsersList;
