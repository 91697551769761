import { useState } from "react";
import Select from "react-select";

export default function EditRentForm({
  rent,
  setRent,
  onSubmit,
  nav,
  properties,
}) {
  // console.log(properties);
  const [errors, setErrors] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(
    properties.find((prop) => prop.value === rent.property_id) || null
  );

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case "billing_date":
        if (!value) fieldErrors.billing_date = "Billing date is required";
        else delete fieldErrors.billing_date;
        break;
      case "amount":
        if (!value) fieldErrors.amount = "Amount is required";
        else delete fieldErrors.amount;
        break;
      case "property_name":
        if (!value) {
          fieldErrors.property_name = "Property name is required";
        } else {
          delete fieldErrors.property_name;
        }
        break;

      default:
        break;
    }

    setErrors(fieldErrors);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    setErrors(newErrors);
    return isValid;
  };

  const handleFormSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  const handleSelectProperty = (selectedOption) => {
    setSelectedProperty(selectedOption);
    setRent({
      ...rent,
      property_id: selectedOption ? selectedOption.value : "",
    });
    validateField("property_id", selectedOption ? selectedOption.value : "");
  };

  const formatProperties = properties.map((prop) => ({
    value: prop.value,
    label: prop.label,
  }));

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit rent details</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="mb-3 col-lg-6">
                    <label className="form-label">
                      Billing date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control react-datepicker-popper"
                      value={rent?.billing_date || ""}
                      onChange={(e) => {
                        setRent({
                          ...rent,
                          billing_date: e.target.value,
                        });
                        validateField("billing_date", e.target.value);
                      }}
                      placeholder="Enter billing date"
                    />
                    {errors.billing_date && (
                      <div className="text-danger">{errors.billing_date}</div>
                    )}
                  </div>
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">Amount</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      value={rent?.amount || ""}
                      onChange={(e) => {
                        setRent({
                          ...rent,
                          amount: e.target.value,
                        });
                        validateField("amount", e.target.value);
                      }}
                      className="form-control"
                      placeholder="Enter amount"
                    />
                    <span className="text-danger">{errors.amount}</span>
                  </div>
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label"> </label>{" "}
                    {/* <span className="text-danger">*</span> */}
                    <textarea
                      value={rent?.description || ""}
                      autoComplete="off"
                      onChange={(e) => {
                        setRent({
                          ...rent,
                          description: e.target.value,
                        });
                        validateField("description", e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Enter description"
                    />
                    <span className="text-danger">{errors.description}</span>
                  </div>
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">Property name</label>{" "}
                    <span className="text-danger">*</span>
                    <Select
                      className="custom-react-select"
                      placeholder="Search by Property Name"
                      options={formatProperties}
                      onChange={handleSelectProperty}
                      value={selectedProperty}
                    />
                    <span className="text-danger">{errors.property_id}</span>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-sm-12 pt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary me-2"
                    onClick={handleFormSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger light"
                    onClick={() => nav("/rent-list")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
