import toast from "react-hot-toast";
import {
  getSecurityDepositList,
  refundDeposit,
} from "../../services/SecurityDepositServices";

export const SECURITY_DEPOSIT_LIST = "SECURITY_DEPOSIT_LIST";
export const REQUEST_START = "REQUEST_START";

export function securityDepositAction(searchQuery) {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getSecurityDepositList(searchQuery)
      .then((response) => {
        const data = response.data.data;
        console.log("here", data);

        dispatch(updateSecurityDepositData(data));
      })
      .catch((error) => {});
  };
}

export function refundDepositAction(data, setModalCentered) {
  return (dispatch) => {
    refundDeposit(data)
      .then((response) => {
        toast.success("Refunded successfully");
        dispatch(securityDepositAction());
      })
      .catch((error) => {
        toast.error(`${error?.response?.data?.error}`);
        console.log(error);
      })
      .finally(() => {
        setModalCentered(false);
      });
  };
}

// FundActions.js

export function updateSecurityDepositData(data) {
  return {
    type: SECURITY_DEPOSIT_LIST,
    payload: data,
  };
}
