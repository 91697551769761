import axiosInstance from "./AxiosInstance";

export function getAllUsers(searchQuery, PageNo, role) {
  let url = `/api/users`;
  const queryParams = [];
  if (role && role !== "all") {
    queryParams.push(`role=${role}`);
  }
  if (searchQuery) {
    queryParams.push(`search=${searchQuery}`);
  }
  if (PageNo) {
    queryParams.push(`page=${PageNo}`);
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }
  }

  console.log(url);
  return axiosInstance.get(url);
}

export function freezeUser(id) {
  return axiosInstance.get(`/api/users/status-change/${id}`);
}

export function getUserDetails(id) {
  return axiosInstance.get(`/api/users/${id}`);
}

export function AssignUserToProperty(postData) {
  return axiosInstance.post(`/api/users/assign-property`, postData);
}

export function editUser(id, data) {
  return axiosInstance.post(`/api/users/update/${id}`, data);
}

export function deleteUser(id, data) {
  return axiosInstance.get(`/api/users/delete/${id}`);
}

export function addNewUser(data) {
  return axiosInstance.post(`/api/users/create`, data);
}
