import { GET_DEALS_DATA, REQUEST_START } from "../actions/DealsAction";

const initialState = {
  loading: false,
  Deals: [],
};

const DealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    case GET_DEALS_DATA:
      return {
        ...state,
        Deals: action.payload, // Assuming action.payload is an array
        loading: false,
      };
    default:
      return state;
  }
};

export default DealsReducer;
