import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

export default function AddNewRentForm({
  rent = {},
  setRent,
  selectedPropertyUnits,
  errors,
  setErrors,
}) {
  const [previews, setPreviews] = useState([]);

  const paymentMethods = [
    { value: "online", label: "Online" },
    { value: "cash", label: "Cash" },
    { value: "card", label: "Card" },
    { value: "cheque", label: "Cheque" },
  ];

  useEffect(() => {
    if (!rent.payment_method) {
      setRent({ ...rent, payment_method: "cash" });
    }
  }, [rent, setRent]);

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };
    switch (name) {
      case "billing_date":
        fieldErrors.billing_date = value ? "" : "Billing date is required";
        break;
      case "amount":
        fieldErrors.amount = value ? "" : "Amount is required";
        break;
      case "payment_method":
        fieldErrors.payment_method = value ? "" : "Payment method is required";
        break;
      case "unit_id":
        fieldErrors.unit_id = value ? "" : "Unit is required";
        break;
      default:
        break;
    }
    setErrors(fieldErrors);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const newPreviews = acceptedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setPreviews((prev) => [...prev, ...newPreviews]);
      setRent({
        ...rent,
        images: [...(rent.images || []), ...acceptedFiles],
      });
    },
  });

  const removeImage = (index) => {
    setPreviews((prev) => prev.filter((_, i) => i !== index));
    setRent({
      ...rent,
      images: rent.images.filter((_, i) => i !== index),
    });
  };

  return (
    <div className="col-12">
      <div className="card-body">
        <form>
          <div className="row">
            <div className="mb-3 col-lg-6">
              <label className="form-label">
                Billing date <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                value={rent.billing_date || ""}
                onChange={(e) => {
                  setRent({
                    ...rent,
                    billing_date: e.target.value,
                  });
                  validateField("billing_date", e.target.value);
                }}
                placeholder="Enter billing date"
              />
              {errors.billing_date && (
                <div className="text-danger">{errors.billing_date}</div>
              )}
            </div>
            <div className="mb-3 col-lg-6">
              <label className="form-label">
                Amount <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                value={rent.amount || ""}
                onChange={(e) => {
                  setRent({
                    ...rent,
                    amount: e.target.value,
                  });
                  validateField("amount", e.target.value);
                }}
                className="form-control"
                placeholder="Enter amount"
              />
              {errors.amount && (
                <div className="text-danger">{errors.amount}</div>
              )}
            </div>

            <div className="mb-3 col-lg-6">
              <label className="form-label">Voucher Number</label>
              <input
                type="text"
                value={rent.voucher_number || ""}
                onChange={(e) =>
                  setRent({ ...rent, voucher_number: e.target.value })
                }
                className="form-control"
                placeholder="Enter voucher number"
              />
            </div>

            <div className="mb-3 col-lg-6">
              <label className="form-label">
                Unit <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                value={rent.unit_id || ""}
                onChange={(e) => {
                  setRent({ ...rent, unit_id: e.target.value });
                  validateField("unit_id", e.target.value);
                }}
              >
                <option value="">Select a unit</option>
                {selectedPropertyUnits?.map((unit) => (
                  <option key={unit._id} value={unit._id}>
                    {unit?.name}
                  </option>
                ))}
              </select>
              {errors.unit_id && (
                <div className="text-danger">{errors.unit_id}</div>
              )}
            </div>

            <div className="mb-3 col-lg-6">
              <label className="form-label">Images</label>
              <div {...getRootProps()} className="drop-zone p-3 border rounded">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the images here...</p>
                ) : (
                  <p>Drag 'n' drop images here, or click to select images</p>
                )}
              </div>
              {previews.length > 0 && (
                <div className="mt-3">
                  <p>Previews:</p>
                  <div className="d-flex flex-wrap">
                    {previews.map((preview, index) => (
                      <div
                        key={index}
                        className=" me-2 mb-2"
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src={preview}
                          alt={`Preview ${index}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                          className="rounded position-relative"
                        />
                        <span
                          className=" btn-close text-danger "
                          onClick={() => removeImage(index)}
                          style={{
                            fontSize: "18px",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            cursor: "pointer",
                          }}
                        ></span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="mb-3 col-lg-6">
              <label className="form-label">
                Payment Method <span className="text-danger">*</span>
              </label>
              <div className="">
                {paymentMethods.map((method) => (
                  <div
                    key={method.value}
                    className="form-check form-check-inline"
                  >
                    <input
                      type="radio"
                      className="form-check-input"
                      name="payment_method"
                      value={method.value}
                      checked={rent.payment_method === method.value}
                      onChange={() => {
                        setRent({ ...rent, payment_method: method.value });
                        validateField("payment_method", method.value);
                      }}
                    />
                    <label className="form-check-label">{method.label}</label>
                  </div>
                ))}
              </div>
              {errors.payment_method && (
                <div className="text-danger">{errors.payment_method}</div>
              )}
            </div>
          </div>

          {rent.payment_method === "cheque" && (
            <div className="row">
              <div className="mb-3 col-lg-6">
                <label className="form-label">Cheque Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={rent.cheque_date || ""}
                  onChange={(e) =>
                    setRent({ ...rent, cheque_date: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label className="form-label">Cheque Amount</label>
                <input
                  type="number"
                  className="form-control"
                  value={rent.cheque_amount || ""}
                  onChange={(e) =>
                    setRent({ ...rent, cheque_amount: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label className="form-label">Signed By</label>
                <input
                  type="text"
                  className="form-control"
                  value={rent.signed_by || ""}
                  onChange={(e) =>
                    setRent({ ...rent, signed_by: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label className="form-label">Received Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={rent.received_date || ""}
                  onChange={(e) =>
                    setRent({ ...rent, received_date: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 col-lg-12">
                <label className="form-label">Cheque Notes</label>
                <textarea
                  className="form-control"
                  value={rent.cheque_notes || ""}
                  onChange={(e) =>
                    setRent({ ...rent, cheque_notes: e.target.value })
                  }
                />
              </div>
            </div>
          )}

          {rent.payment_method === "card" && (
            <div className="row">
              <div className="mb-3 col-lg-6">
                <label className="form-label">Card Amount</label>
                <input
                  type="number"
                  className="form-control"
                  value={rent.card_amount || ""}
                  onChange={(e) =>
                    setRent({ ...rent, card_amount: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 col-lg-12">
                <label className="form-label">Card Notes</label>
                <textarea
                  className="form-control"
                  value={rent.card_notes || ""}
                  onChange={(e) =>
                    setRent({ ...rent, card_notes: e.target.value })
                  }
                />
              </div>
            </div>
          )}

          {rent.payment_method === "online" && (
            <div className="row">
              <div className="mb-3 col-lg-6">
                <label className="form-label">Online Transfer Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={rent.online_transfer_name || ""}
                  onChange={(e) =>
                    setRent({ ...rent, online_transfer_name: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label className="form-label">Online Transfer Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={rent.online_transfer_date || ""}
                  onChange={(e) =>
                    setRent({ ...rent, online_transfer_date: e.target.value })
                  }
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label className="form-label">Online Transfer Amount</label>
                <input
                  type="number"
                  className="form-control"
                  value={rent.online_transfer_amount || ""}
                  onChange={(e) =>
                    setRent({ ...rent, online_transfer_amount: e.target.value })
                  }
                />
              </div>
            </div>
          )}

          <div className="mb-3 col-12">
            <label className="form-label">Description</label>
            <textarea
              value={rent.description || ""}
              onChange={(e) => {
                setRent({
                  ...rent,
                  description: e.target.value,
                });
              }}
              className="form-control"
              placeholder="Enter description"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
