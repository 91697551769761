export function StatusHistoryComponent(
  PropertyData,
  showAllStatus,
  handleHideAllStatusClick,
  handleSeeAllStatusClick
) {
  return (
    <div className="col-12">
      <div className="card-header mb-0 border-0 pb-0 d-flex justify-content-between align-items-center">
        <h3 className="fs-20 text-black mb-0">Status History</h3>
        {PropertyData.documents?.length > 2 && (
          <p
            className="text-blue light text-decoration-underline c-pointer"
            onClick={
              showAllStatus ? handleHideAllStatusClick : handleSeeAllStatusClick
            }
          >
            {showAllStatus ? "Hide All" : "See All"}
          </p>
        )}
      </div>
      <div className="row card-body">
        {PropertyData?.status_history ? (
          PropertyData?.status_history.map((item, ind) => (
            <div className="col-lg-6">
              <div
                className={` border ${
                  showAllStatus || ind < 2 ? "visible" : "hidden"
                }`}
              >
                <div className={`card-body pt-2 pb-3 `}>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="mb-0 title">Status</span> :
                      <span className="text-black ms-2">{item?.status}</span>
                    </li>
                    <li className="list-group-item">
                      <span className="mb-0 title">Comment</span> :
                      <span className="text-black ms-2">{item?.comment}</span>
                    </li>
                    <li className="list-group-item">
                      <span className="mb-0 title">User</span> :
                      <span className="text-black desc-text ms-2">
                        {new Date(item?.date).toUTCString()}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-lg-12">
            <div className="  h-100">
              <div
                className={`card-body d-flex justify-content-center align-items-center h-100`}
              >
                <p className="d-flex justify-content-center align-items-center h-100">
                  No status history found for this property.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
