import { useEffect, useState } from "react";
import { Col, Card, Table } from "react-bootstrap";
import { getPropertyDeals } from "../../../../services/PropertyService";

const PropertyDealsTable = () => {
  const path = window.location.pathname.split("/");
  const propertyId = path[path.length - 1];

  const [pageNo, setPageNo] = useState(1);
  const [deals, setDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await getPropertyDeals(propertyId);
        setDeals(res.data?.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [propertyId]);

  console.log(deals);

  const tableTitle = [
    "No.",
    "Title",
    "Description",
    "Amount ",
    "Customer name",
    // "Property",
    "Created by",
    "Created at",
  ];

  const totalPages = Math.ceil(deals?.length / pageSize);

  const handlePageChange = (isPrev) => {
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1); // Ensure page number is not less than 1
    newPageNo = Math.min(newPageNo, totalPages); // Ensure page number does not exceed total pages
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const displayedDeals = deals?.slice(
    (pageNo - 1) * pageSize,
    pageNo * pageSize
  );

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <Col lg={12}>
      <Card>
        <Card.Header>
          <Card.Title>Property Deals</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table responsive bordered>
            <thead>
              <tr>
                {tableTitle?.map((title, index) => (
                  <th key={index}>{title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedDeals.map((deal, index) => (
                <tr key={index}>
                  <th>{(pageNo - 1) * pageSize + index + 1}</th>
                  <td>{deal?.title}</td>
                  <td>{deal?.description}</td>
                  <td>{deal?.amount}</td>
                  <td>{deal?.customer?.name}</td>
                  {/* <td>
                    {deal?.property?.property_name
                      ? deal?.property?.property_name
                      : "Not Available"}
                  </td> */}
                  <td>{deal?.created_by?.name}</td>
                  <td>
                    {new Date(deal?.created_by?.created_at).toUTCString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14 order-list-table">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {displayedDeals.length} of {deals.length} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <div
                    className="paginate_button previous"
                    onClick={() => handlePageChange(true)}
                  >
                    <i className="fa fa-angle-double-left" />
                  </div>
                  <span>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <div
                        key={i}
                        className={`paginate_button ${
                          pageNo === i + 1 ? "current" : ""
                        }`}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          setPageNo(i + 1);
                        }}
                      >
                        {i + 1}
                      </div>
                    ))}
                  </span>
                  <div
                    className="paginate_button next"
                    onClick={() => handlePageChange(false)}
                  >
                    <i className="fa fa-angle-double-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default PropertyDealsTable;
