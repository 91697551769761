import { FUND_REQUESTS_LIST, REQUEST_START } from "../actions/FundActions";

const initialState = {
  loading: false,
  funds: {},
};

const fundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    case FUND_REQUESTS_LIST:
      return {
        ...state,
        funds: action.payload,
       
        loading: false,
      };
    default:
      return state;
  }
};

export default fundsReducer;
