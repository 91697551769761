import axiosInstance from "./AxiosInstance";

export function getInvestorsList() {
  return axiosInstance.get(`/api/investors`);
}

export function AddInvestor(postData) {
  return axiosInstance.post(`/api/investors/create`, postData);
}

export function getInvestorDetails(id) {
  return axiosInstance.get(`/api/investors/${id}`);
}

export function updateInvestor(id, postData) {
  return axiosInstance.post(`api/investors/update/${id}`, postData);
}

export function deleteInvestor(id) {
  return axiosInstance.get(`/api/investors/delete/${id}`);
}

export function onFreezeInvestor(id) {
  return axiosInstance.get(`/api/investors/status-change/${id}`);
}

export function createInvestmentOnProperty(postData) {
  return axiosInstance.post(`/api/investments/create`, postData);
}
