import {
  UPDATE_PROPERTY_DATA,
  REQUEST_START,
} from "../actions/PropertryActions";

const initialState = {
  loading: false,
  properties: [],
};

const PropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PROPERTY_DATA:
      return {
        ...state,
        properties: action.payload, // Assuming action.payload is an array
        loading: false,
      };
    default:
      return state;
  }
};

export default PropertyReducer;
