import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getAllUsers } from "../../../services/UsersServices";
import { toast } from "react-hot-toast";
import { assignLeads } from "../../../services/LeadsServices";

function LeadAssignUserList({
  largeModal,
  setLargeModal,
  selectedLead,
  setSelectedLead,
  LeadsAction,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [UsersList, setUsersList] = useState([]);

  const handleSelectionChange = (User) => {
    setSelectedUser(selectedUser === User ? null : User);
  };

  useEffect(() => {
    getAllUsers("all").then((res) => {
      const salesUsers =
        res?.data?.users?.filter((user) => user?.user?.role === "sales") || [];
      setUsersList(salesUsers);
      console.log(res.data.users);
    });
  }, []);

  const handleAssignLead = () => {
    if (!selectedUser) {
      toast.error("Please select a user");
      return;
    } else if (!selectedLead) {
      toast.error("Please select a lead");
      return;
    }
    const postData = {
      user_id: selectedUser,
      lead_id: selectedLead,
    };
    assignLeads(postData)
      .then((res) => {
        // console.log(res.data);
        setSelectedLead(null);
        setSelectedUser(null);
        toast.success("Lead assigned successfully");
        setLargeModal(false);
        LeadsAction();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || "Something went wrong");
        setSelectedLead(null);
        setSelectedUser(null);
        setLargeModal(false);
      });
  };

  const isRowDisabled = (User) =>
    selectedUser !== null && selectedUser !== User;

  const filteredUsersList = UsersList?.filter((user) => {
    return (
      user?.user?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.user?.role?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <Modal
      className="fade bd-example-modal-lg"
      show={largeModal}
      size="lg"
      centered
      onHide={setLargeModal}
    >
      <Modal.Header>
        <Modal.Title>Select user</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => setLargeModal(false)}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="card-header ">
            {/* <h4 className="card-title">Select user</h4> */}
            <div
              className=" d-flex justify-content-between align-items-center"
              style={{
                width: "100%",
              }}
            >
              {/* Search Input */}
              <div
                className="d-flex  gap-4 align-items-center "
                style={{ width: "350px" }}
              >
                <h4 className="card-title">Search</h4>

                <input
                  type="text"
                  className="form-control input-rounded"
                  placeholder="Search by Name, Number, or ID..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th></th> {/* Checkbox column */}
                    <th>Name</th>
                    <th>Role</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsersList?.map((user, index) => (
                    <tr
                      key={index}
                      onClick={() => handleSelectionChange(user?.user?._id)}
                      className={
                        isRowDisabled(user?.user?._id) ? "disabled-row" : ""
                      }
                    >
                      <td>
                        <input
                          type="radio"
                          checked={selectedUser === user?.user?._id}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleSelectionChange(user?.user?._id);
                          }}
                        />
                      </td>
                      <td>{user?.user?.name}</td>
                      <td>{user?.user?.role}</td>
                      <td>{user?.user?.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light" onClick={() => setLargeModal(false)}>
          Close
        </Button>
        <Button
          variant="success"
          type="button"
          className="btn success "
          onClick={() => handleAssignLead()}
        >
          Assign lead
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LeadAssignUserList;
