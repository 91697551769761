import { RENT_COLLECTION_LIST, RENT_START } from "../actions/RentActions";

const initialState = {
  loading: false,
  rents: {},
};

const rentReducer = (state = initialState, action) => {
  switch (action.type) {
    case RENT_START:
      return {
        ...state,
        loading: true,
      };

    case RENT_COLLECTION_LIST:
      return {
        ...state,
        rents: action.payload,

        loading: false,
      };
    default:
      return state;
  }
};

export default rentReducer;
