import React, { useEffect, useState } from "react";
import "./filtering.css";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PropertyAction } from "../../../../store/actions/PropertryActions";
import { Button, Modal } from "react-bootstrap";
import { createInvestmentOnProperty } from "../../../../services/InvestorsServices";

const AddInvestments = () => {
  const dispatch = useDispatch();
  const { properties, loading } = useSelector((state) => state.properties);
  const nav = useNavigate();

  //   console.log(properties);

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useEffect(() => {
    dispatch(PropertyAction());
  }, [dispatch, path]);

  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [selectedPropertyName, setSelectedPropertyName] = useState(null);
  const [neededInvestmentAmount, setNeededInvestmentAmount] = useState(null);

  const [modalCentered, setModalCentered] = useState(false);

  const [investmentAmount, setInvestmentAmount] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSelectionChange = (
    propertyId,
    propertyName,
    total_value,
    received_investments_amount
  ) => {
    setSelectedPropertyId(propertyId);
    setSelectedPropertyName(propertyName);
    console.log(total_value, received_investments_amount);
    const neededValue = total_value - received_investments_amount;
    setNeededInvestmentAmount(neededValue);
  };

  const filteredProperties = properties?.data?.filter((property) => {
    return (
      property.property_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      property.property_number
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      property._id.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const onSubmitPropertyInvestments = () => {
    setIsLoading(true);
    if (!selectedPropertyId) {
      setIsLoading(false);
      return toast.error("Select a property");
    }

    if (neededInvestmentAmount < investmentAmount) {
      setIsLoading(false);
      return toast.error("Amount should be lesser than required amount");
    }

    if (!investmentAmount) {
      setIsLoading(false);
      return toast.error("Enter a valid Amount");
    }
    const postData = {
      property_id: selectedPropertyId,
      amount: investmentAmount,
      //   amount: Number(investmentAmount),
      investor_id: path,
    };
    console.log(postData);

    createInvestmentOnProperty(postData)
      .then((res) => {
        console.log(res);
        toast.success("Added Investment Successfully");
        nav("/investors-list");
        setIsLoading(false);
        setInvestmentAmount(null);
        setSelectedPropertyId(null);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  };

  if (loading || isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        className="fade"
        show={modalCentered}
        onHide={setModalCentered}
        centered
      >
        <Modal.Header>
          <Modal.Title>Send amount</Modal.Title>
          <Button
            onClick={() => {
              setModalCentered(false);
            }}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon gap-3">
                  <span className="pb-3 bg-primary text-primary">
                    <svg
                      width={80}
                      height={80}
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31.8333 79.1667H4.16659C2.33325 79.1667 0.833252 77.6667 0.833252 75.8333V29.8333C0.833252 29 1.16659 28 1.83325 27.5L29.4999 1.66667C30.4999 0.833332 31.8333 0.499999 32.9999 0.999999C34.3333 1.66667 34.9999 2.83333 34.9999 4.16667V76C34.9999 77.6667 33.4999 79.1667 31.8333 79.1667ZM7.33325 72.6667H28.4999V11.6667L7.33325 31.3333V72.6667Z"
                        fill="white"
                      />
                      <path
                        d="M75.8333 79.1667H31.6666C29.8333 79.1667 28.3333 77.6667 28.3333 75.8334V36.6667C28.3333 34.8334 29.8333 33.3334 31.6666 33.3334H75.8333C77.6666 33.3334 79.1666 34.8334 79.1666 36.6667V76C79.1666 77.6667 77.6666 79.1667 75.8333 79.1667ZM34.9999 72.6667H72.6666V39.8334H34.9999V72.6667Z"
                        fill="white"
                      />
                      <path
                        d="M60.1665 79.1667H47.3332C45.4999 79.1667 43.9999 77.6667 43.9999 75.8334V55.5C43.9999 53.6667 45.4999 52.1667 47.3332 52.1667H60.1665C61.9999 52.1667 63.4999 53.6667 63.4999 55.5V75.8334C63.4999 77.6667 61.9999 79.1667 60.1665 79.1667ZM50.6665 72.6667H56.9999V58.8334H50.6665V72.6667Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Property</p>
                    <h4 className="mb-0">{selectedPropertyName}</h4>
                    <p className="my-2">
                      Required Amount : {neededInvestmentAmount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative">
            <div className="input-group input-group-lg">
              <span
                className="input-group-text label-primary label-xl"
                id="basic-addon1"
              >
                AED
              </span>
              <input
                type="number"
                value={investmentAmount}
                onChange={(e) => {
                  setInvestmentAmount(e.target.value);
                  // console.log(postData);
                }}
                placeholder="Amount"
                aria-label="Amount"
                aria-describedby="basic-addon1"
                className="form-control text-black text-xxl-left"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalCentered(false);
              setInvestmentAmount();
            }}
            variant="danger"
          >
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              onSubmitPropertyInvestments();
            }}
          >
            Send amount
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card-header">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{
            width: "100%",
          }}
        >
          <div
            className="d-flex gap-4 align-items-center"
            style={{ width: "350px" }}
          >
            <h4 className="card-title">Search</h4>

            <input
              type="text"
              className="form-control input-rounded"
              placeholder="Search by Name, Number, or ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="text-end toolbar toolbar-bottom p-2">
            <button
              className={`${
                selectedPropertyId ? "" : "disabled"
              } btn btn-success px-4`}
              onClick={() => {
                if (neededInvestmentAmount <= 0) {
                  return toast(
                    "All investment needs are fully met. No further investments are needed for this property!",
                    {
                      icon: "👏",
                      //   style: {
                      //     borderRadius: "10px",
                      //     background: "blue",
                      //     color: "#fff",
                      //   },
                    }
                  );
                }
                setModalCentered(true);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="w-100 table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th></th> {/* Radio button column */}
                <th>Property Name</th>
                <th>Property Number</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {filteredProperties?.map((property) => (
                <tr
                  key={property._id}
                  onClick={() =>
                    handleSelectionChange(
                      property?._id,
                      property?.property_name,
                      property?.total_value,
                      property?.received_investments_amount
                    )
                  }
                  className={
                    selectedPropertyId && selectedPropertyId !== property._id
                      ? "disabled-row"
                      : ""
                  }
                >
                  <td>
                    <input
                      type="radio"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectionChange(
                          property?._id,
                          property?.property_name,
                          property?.total_value,
                          property?.received_investments_amount
                        );
                      }}
                      checked={selectedPropertyId === property._id}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectionChange(
                          property?._id,
                          property?.property_name,
                          property?.total_value,
                          property?.received_investments_amount
                        );
                      }}
                    />
                  </td>
                  <td>{property?.property_name}</td>
                  <td>{property?.property_number}</td>
                  <td>
                    {property?.users
                      .map((user) =>
                        Array.isArray(user?.name)
                          ? user.name.join(",")
                          : user.name
                      )
                      .join(", ")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AddInvestments;
