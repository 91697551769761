import { Button, Modal } from "react-bootstrap";

export default function ConfirmUsersActionModal({
  modalCentered,
  setModalCentered,
  modalType,
  onFreeze,
  selectedUser,
  onDelete,
}) {
  return (
    <Modal
      className="fade"
      show={modalCentered}
      onHide={setModalCentered}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          {modalType === "activate_user"
            ? "Activate user"
            : modalType === "deactivate_user"
            ? "Deactivate user"
            : "Delete user"}
        </Modal.Title>
        {/* <Button
            onClick={() => {
              setModalCentered(false);
            }}
            variant=""
            className="btn-close"
          ></Button> */}
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative">
          {" "}
          <h4 className="subtitle">
            {modalType === "unfreeze_user"
              ? "Are you sure you want to unfreeze this user?"
              : modalType === "freeze_user"
              ? "Are you sure you want to freeze this user?"
              : "Are you sure you want to delete this user?"}
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setModalCentered(false);
          }}
          variant="success"
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            if (modalType === "deactivate_user") {
              onFreeze(selectedUser);
              setModalCentered(false);
            } else if (modalType === "activate_user") {
              onFreeze(selectedUser);
              setModalCentered(false);
            } else if (modalType === "delete_user") {
              onDelete(selectedUser);
              setModalCentered(false);
            }
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
