import {
  REQUEST_START,
  SECURITY_DEPOSIT_LIST,
} from "../actions/SecurityDepositAction";

const initialState = {
  loading: false,
  securityDeposit: {},
};

const securityDepositReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    case SECURITY_DEPOSIT_LIST:
      return {
        ...state,
        securityDeposit: action.payload,

        loading: false,
      };
    default:
      return state;
  }
};

export default securityDepositReducer;
