import { getInvestorsList } from "../../services/InvestorsServices";

export const UPDATE_INVESTORS_LIST_DATA = "[UPDATE_INVESTORS_LIST_DATA]";
export const REQUEST_START = "REQUEST_START";

export function investorsListAction() {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getInvestorsList()
      .then((response) => {
        dispatch(updateInvestorsListData(response.data));
        // console.log(response);
      })
      .catch((error) => {});
  };
}

export function updateInvestorsListData(data) {
  return {
    type: UPDATE_INVESTORS_LIST_DATA,
    payload: data,
  };
}
