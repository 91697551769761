/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import "react-medium-image-zoom/dist/styles.css";
import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { getPropertyInitialExpense } from "../../../services/PropertyService";

function InitialTransactions() {
  const [initialTransactionList, setInitialTransactionsList] = useState([]);
  const path = window.location.pathname.split("/");
  const propertyId = path[path.length - 1];

  const [totalDocuments, setTotalDocuments] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const itemsPerPage = 12;
  const totalPage = Math.ceil(totalDocuments / itemsPerPage);

  useEffect(() => {
    setIsLoading(true);
    getPropertyInitialExpense(propertyId, pageNo)
      .then((res) => {
        console.log(res.data);
        setIsLoading(false);
        setInitialTransactionsList(res?.data?.data);
        setTotalDocuments(res?.data?.total_documents);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [pageNo]);

  const handlePageChange = (isPrevious) => {
    setPageNo((prev) =>
      isPrevious ? Math.max(prev - 1, 1) : Math.min(prev + 1, totalPage)
    );
    console.log(pageNo);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row h-100">
        <div className="col-xl-12 h-100 gap-4">
          <h3 className="text-center">Initial Transactions</h3>

          <div className="table-responsive fs-14 order-list-table">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <table
                className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                id="example5"
              >
                <thead>
                  <tr role="row">
                    <th className="">Billing Date</th>
                    <th className="">Posting Date</th>
                    <th className="">Added by</th>
                    <th className="">Voucher no.</th>
                    <th className="">Amount</th>
                    <th className="">Type</th>
                    <th className="">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {initialTransactionList.length > 0 ? (
                    initialTransactionList.map((item, index) => (
                      <tr key={index}>
                        <td>{new Date(item?.billing_date).toUTCString()}</td>
                        <td>{new Date(item?.posting_date).toUTCString()}</td>

                        <td>
                          {item?.user?.name
                            ? item?.user?.name
                            : "Not Available"}
                        </td>
                        <td>{item?.voucher_number}</td>
                        <td>{item?.amount}</td>
                        <td>
                          <Badge
                            bg=""
                            className={`badge-${
                              item?.type === "expense" ? "warning" : "green"
                            } badge-xs`}
                          >
                            {item?.type === "expense" ? "Expense" : "Income"}
                          </Badge>
                        </td>
                        <td>{item?.description}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No Transactions</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-5">
                <div className="dataTables_info">
                  Showing {initialTransactionList.length} of {totalDocuments}{" "}
                  entries
                </div>

                {totalPage > 1 && (
                  <div className="dataTables_paginate paging_simple_numbers">
                    <Link
                      className="paginate_button previous"
                      to="#"
                      onClick={() => handlePageChange(true)}
                    >
                      <i className="fa fa-angle-double-left" />
                    </Link>
                    <span>
                      {(() => {
                        let pages = [];
                        if (totalPage <= 5) {
                          // If total pages are 5 or less, show all
                          for (let i = 1; i <= totalPage; i++) {
                            pages.push(i);
                          }
                        } else {
                          // Always show first page
                          pages.push(1);

                          // If current page is among first 3 pages
                          if (pageNo <= 3) {
                            for (let i = 2; i <= 5; i++) {
                              pages.push(i);
                            }
                          }
                          // If current page is among last 3 pages
                          else if (pageNo > totalPage - 3) {
                            pages.push("...");
                            for (let i = totalPage - 3; i <= totalPage; i++) {
                              pages.push(i);
                            }
                          }
                          // For all other cases
                          else {
                            pages.push("...");
                            for (let i = pageNo - 1; i <= pageNo + 1; i++) {
                              pages.push(i);
                            }
                            if (pageNo + 2 < totalPage) {
                              pages.push("...");
                            }
                            if (pageNo + 1 < totalPage) {
                              pages.push(totalPage);
                            }
                          }
                        }

                        return pages.map((page, index) => (
                          <Link
                            key={index}
                            to="#"
                            className={`paginate_button ${
                              pageNo === page ? "current" : ""
                            }`}
                            onClick={() => {
                              if (typeof page === "number") {
                                setPageNo(page);
                                // window.scrollTo({
                                //   top: 0,
                                //   behavior: "smooth",
                                // });
                              }
                            }}
                          >
                            {page}
                          </Link>
                        ));
                      })()}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="#"
                      onClick={() => {
                        if (initialTransactionList && totalPage > pageNo) {
                          handlePageChange(false);
                        }
                      }}
                    >
                      <i className="fa fa-angle-double-right" />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InitialTransactions;
