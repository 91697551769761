// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled-row td:not(:first-child) {
  color: #ccc; /* Grey out the text to indicate disabled */
  pointer-events: auto; 
  /* Prevents clicking on the cells */
}
`, "",{"version":3,"sources":["webpack://./src/jsx/components/Inverstors/AddInvestments/filtering.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,2CAA2C;EACxD,oBAAoB;EACpB,mCAAmC;AACrC","sourcesContent":[".disabled-row td:not(:first-child) {\r\n  color: #ccc; /* Grey out the text to indicate disabled */\r\n  pointer-events: auto; \r\n  /* Prevents clicking on the cells */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
