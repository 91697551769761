import { useState } from "react";

const BankTransferDetailsForm = ({
  bankTransferDetails = {},
  setBankTransferDetails,
  onSubmit,
}) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const REQUIRED_FIELDS = [
    "reference_number",
    "amount",
    "date",
    "type",
    "notes",
  ];

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case "reference_number":
        if (!value) {
          fieldErrors[name] = "Reference number is required";
        } else if (value.length < 3) {
          fieldErrors[name] = "Reference number must be at least 3 characters";
        } else {
          delete fieldErrors[name];
        }
        break;

      case "amount":
        if (!value) {
          fieldErrors[name] = "Amount is required";
        } else if (isNaN(value) || value <= 0) {
          fieldErrors[name] = "Please enter a valid positive amount";
        } else {
          delete fieldErrors[name];
        }
        break;

      case "date":
        if (!value) {
          fieldErrors[name] = "Date is required";
        } else {
          delete fieldErrors[name];
        }
        break;

      case "type":
        if (!value) {
          fieldErrors[name] = "Transfer type is required";
        } else {
          delete fieldErrors[name];
        }
        break;

      case "notes":
        if (value && value.length > 500) {
          fieldErrors[name] = "Notes cannot exceed 500 characters";
        } else {
          delete fieldErrors[name];
        }
        break;

      default:
        break;
    }

    setErrors(fieldErrors);
    return Object.keys(fieldErrors).length === 0;
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    REQUIRED_FIELDS.forEach((key) => {
      if (key === "notes") return; // Notes is optional
      if (!bankTransferDetails[key]) {
        isValid = false;
        newErrors[key] = `${key.replace(/_/g, " ")} is required`;
      } else {
        // Validate the field
        if (!validateField(key, bankTransferDetails[key])) {
          isValid = false;
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (validateForm()) {
        await onSubmit();
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankTransferDetails((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Add Bank Transfer Details</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Reference number <span className="text-danger">*</span>
                  </label>
                  <input
                    value={bankTransferDetails?.reference_number || ""}
                    onChange={handleInputChange}
                    type="text"
                    className={`form-control ${
                      errors.reference_number ? "is-invalid" : ""
                    }`}
                    name="reference_number"
                    placeholder="Enter reference number"
                  />
                  {errors.reference_number && (
                    <div className="invalid-feedback">
                      {errors.reference_number}
                    </div>
                  )}
                </div>

                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Amount <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    value={bankTransferDetails?.amount || ""}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.amount ? "is-invalid" : ""
                    }`}
                    name="amount"
                    min="0"
                    step="0.01"
                    placeholder="Enter amount here"
                  />
                  {errors.amount && (
                    <div className="invalid-feedback">{errors.amount}</div>
                  )}
                </div>

                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    name="date"
                    value={bankTransferDetails?.date || ""}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.date ? "is-invalid" : ""
                    }`}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  {errors.date && (
                    <div className="invalid-feedback">{errors.date}</div>
                  )}
                </div>

                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Transfer Type <span className="text-danger">*</span>
                  </label>
                  <div className={errors.type ? "is-invalid" : ""}>
                    <input
                      type="radio"
                      id="incoming"
                      name="type"
                      value="incoming"
                      onChange={handleInputChange}
                      checked={bankTransferDetails?.type === "incoming"}
                    />
                    <label htmlFor="incoming" className="ms-2 me-4">
                      Incoming
                    </label>

                    <input
                      type="radio"
                      id="outgoing"
                      name="type"
                      value="outgoing"
                      onChange={handleInputChange}
                      checked={bankTransferDetails?.type === "outgoing"}
                    />
                    <label htmlFor="outgoing" className="ms-2">
                      Outgoing
                    </label>
                  </div>
                  {errors.type && (
                    <div className="invalid-feedback d-block">
                      {errors.type}
                    </div>
                  )}
                </div>

                <div className="mb-3 col-12">
                  <label className="form-label">Notes</label>
                  <textarea
                    name="notes"
                    value={bankTransferDetails?.notes || ""}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.notes ? "is-invalid" : ""
                    }`}
                    rows="3"
                    placeholder="Enter additional notes here"
                    maxLength="500"
                  />
                  <div className="form-text">
                    {bankTransferDetails?.notes?.length || 0}/500 characters
                  </div>
                  {errors.notes && (
                    <div className="invalid-feedback">{errors.notes}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 pt-3">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTransferDetailsForm;
