import axiosInstance from "./AxiosInstance";

export function refundDeposit(postData) {
  return axiosInstance.post(`/api/transactions/refund-deposit`, postData);
}

export function getSecurityDepositList(searchQuery) {
  console.log(
    "api here",
    `/api/transactions/security-deposits ${
      searchQuery ? `?search=${searchQuery}` : ""
    }`
  );
  return axiosInstance.get(
    `/api/transactions/security-deposits${
      searchQuery ? `?search=${searchQuery}` : ""
    }`
  );
}
