import { useState } from "react";

export default function RentRequestEditForm({
  cashHandOver,
  setCashHandOver,
  onSubmit,
  nav,
}) {
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case "billing_date":
        if (!value) fieldErrors.billing_date = "Date is required";
        else delete fieldErrors.billing_date;
        break;
      case "amount":
        if (!value) fieldErrors.amount = "Amount is required";
        else delete fieldErrors.amount;
        break;

      default:
        break;
    }

    setErrors(fieldErrors);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    setErrors(newErrors);
    return isValid;
  };

  const handleFormSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit rent request details</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control react-datepicker-popper"
                      value={cashHandOver?.billing_date || ""}
                      onChange={(e) => {
                        setCashHandOver({
                          ...cashHandOver,
                          billing_date: e.target.value,
                        });
                        validateField("billing_date", e.target.value);
                      }}
                      placeholder="Enter billing date"
                    />
                    {errors.billing_date && (
                      <div className="text-danger">{errors.billing_date}</div>
                    )}
                  </div>
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">Amount</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      value={cashHandOver?.amount || ""}
                      onChange={(e) => {
                        setCashHandOver({
                          ...cashHandOver,
                          amount: e.target.value,
                        });
                        validateField("amount", e.target.value);
                      }}
                      className="form-control"
                      placeholder="Enter amount"
                    />
                    <span className="text-danger">{errors.amount}</span>
                  </div>
                  <div className="mb-3 col-12 ">
                    <label className="form-label">Description</label>
                    <textarea
                      value={cashHandOver?.description || ""}
                      className="form-control"
                      readOnly // This disables editing
                    />
                    <span className="text-danger">{errors.description}</span>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-sm-12 pt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary me-2"
                    onClick={handleFormSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger light"
                    onClick={() => nav("/rent-requests")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
