import axios from "axios";
// import axiosInstance from './AxiosInstance';
// import swal from "sweetalert";
// import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";
// import { store } from '../store/store';

// export function signUp(email, password) {
//     //axios call
//     const postData = {
//         email,
//         password,
//         // returnSecureToken: true,
//     };
//     return axiosInstance.defaults.headers.post(
//         `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
//         postData,
//     );
// }
//login
export function login(email, password) {
  const postData = {
    email,
    password,
    // returnSecureToken: true,
  };
  return axios.post(`${process.env.REACT_APP_API}/api/auth/sign-in`, postData);
}

export function formatError(errorResponse) {
  try {
    switch (errorResponse) {
      case "Invalid email or password":
        //return 'Email already exists';
        // swal("Oops", "Email already exists", "error");
        toast.error("Invalid email or password");
        break;
      case "Invalid login details":
        toast.error("Email not found");

        break;
      //   case "INVALID_PASSWORD":
      //return 'Invalid Password';
      // swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
      //     Swal.fire({
      //       icon: "error",
      //       title: "Oops",
      //       text: "Invalid Password",
      //     });
      //     break;
      //   case "USER_DISABLED":
      //     return "User Disabled";

      default:
        return toast.error(errorResponse.replace(/['"]+/g, ""));
    }
  } catch (error) {
    return toast.error(error);
  }
}

// export function saveTokenInLocalStorage(tokenDetails) {

//     tokenDetails.expireDate = new Date(
//         new Date().getTime() + tokenDetails.expiresIn * 1000,
//     );
//     localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
// }

export function saveTokenInLocalStorage(tokenDetails) {
  // Set expireDate to 1 month from the current date
  const oneMonthFromNow = new Date();
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

  // Set the expireDate property in the tokenDetails
  tokenDetails.expireDate = oneMonthFromNow;

  // Save the tokenDetails to local storage
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

// export function checkAutoLogin(dispatch, navigate) {
//     const tokenDetailsString = localStorage.getItem('userDetails');
//     let tokenDetails = '';
//     if (!tokenDetailsString) {
//         dispatch(Logout(navigate));
//         return;
//     }

//     tokenDetails = JSON.parse(tokenDetailsString);
//     let expireDate = tokenDetails.expireDate
//     let todaysDate = JSON.parse(new Date());

//     console.log(todaysDate);
//     console.log(expireDate);
//     console.log(todaysDate < expireDate);

//     if (todaysDate > expireDate) {
//         dispatch(Logout(navigate));
//         return;
//     }

//     dispatch(loginConfirmedAction(tokenDetails));

//     // const timer = expireDate.getTime() - todaysDate.getTime();
//     // runLogoutTimer(dispatch, timer, navigate);
// }

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("userDetails");

  // console.log(tokenDetailsString);

  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  // const tokenDetails = JSON.parse(tokenDetailsString)

  const tokenDetails = JSON.parse(tokenDetailsString);
  const expireDate = new Date(tokenDetails.expireDate);
  const todaysDate = new Date();

  console.log(todaysDate);
  console.log(expireDate);
  console.log(todaysDate < expireDate);

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const path = window.location.href;
  if (path.includes("/login")) {
    navigate("/");
  }
}
