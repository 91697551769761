import { navtoggle } from "../../../store/actions/AuthActions";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/// React router dom
import { Link } from "react-router-dom";

//

/// images
// import logo from "../../../images/logo-lt-01.png";
// import logoText from "../../../images/logo-text.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };

  const logo =
    process.env.REACT_APP_LOGO_PATH || "/public-images/logo-lt-01.png";

  console.log(logo);
  return (
    <div className="nav-header ">
      <Link to="/dashboard" className="overflow-hidden">
        {/* <img className="logo-abbr " src={logo} alt="" /> */}
        <div className="h-100 overflow-hidden w-auto px-2">
          <img
            style={{
              objectFit: "contain",
              height: "100%",
              width: "100%",
              scale: "1.5",
            }}
            src={logo}
            alt=""
          />
        </div>
        {/* <img className="brand-title" src={logoText} alt="" /> */}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          handleToogle();
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
