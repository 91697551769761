import { Button, Modal } from "react-bootstrap";
import { EditProperty } from "../../../../services/PropertyService";
import toast from "react-hot-toast";
import { isEqual, property } from "lodash";
import { mediaUpload } from "../../../../services/MediaUploadService";

export function EditPropertyModal(
  modalCentered,
  setModalCentered,
  unitName,
  setUnitName,
  errors,
  setUnitType,
  unitDescription,
  setUnitDescription,
  setErrors,
  handleAddUnit,
  documentModalCentered,
  setDocumentModalCentered,
  documentName,
  setDocumentName,
  documentErrors,
  documentDescription,
  setDocumentDescription,
  getDocRootProps,
  getDocInputProps,
  isDocDragActive,
  documentPreview,
  documentFile,
  setDocumentFile,
  setDocumentErrors,
  handleAddDocument,
  // documentIndex,
  setDocumentIndex,
  setDocumentPreview,
  setUnitIndex,
  unitType
) {
  return (
    <>
      <Modal
        className="fade"
        show={modalCentered}
        onHide={() => {
          setUnitName(null);
          setUnitType(null);
          setUnitDescription(null);
          setErrors({});
          setUnitIndex(null);
          setModalCentered(false);
        }}
        centered
      >
        <Modal.Header>
          <Modal.Title>Add Room</Modal.Title>
          <Button
            onClick={() => {
              setUnitName(null);
              setUnitType(null);
              setUnitDescription(null);
              setErrors({});
              setUnitIndex(null);
              setModalCentered(false);
            }}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            {/* name Label */}
            <div className="form-group mb-3 row">
              <label className="col-lg-4 col-form-label" htmlFor="val-name">
                Name <span className="text-danger">*</span>
              </label>
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name of the room"
                  value={unitName}
                  onChange={(e) => setUnitName(e.target.value)}
                />
                {errors.unitName && (
                  <div className="text-danger">{errors?.unitName}</div>
                )}
              </div>
            </div>
            {/* type label */}
            <div className="form-group mb-3 row">
              <label className="col-lg-4 col-form-label" htmlFor="val-type">
                Type <span className="text-danger">*</span>
              </label>
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name of the room"
                  value={unitType}
                  onChange={(e) => setUnitType(e.target.value)}
                />
                {/* <select
                  defaultValue={"option"}
                  onChange={(e) => setUnitType(e.target.value)}
                  className="form-control"
                >
                  <option value="option" disabled>
                    Choose...
                  </option>
                  <option value="bedroom">Bedroom</option>
                  <option value="hall-property">hall property</option>
                </select> */}
                {errors.unitType && (
                  <div className="text-danger">{errors?.unitType}</div>
                )}
              </div>
            </div>

            {/* Description */}
            <div className="form-group mb-3 row">
              <label className="col-lg-4 col-form-label" htmlFor="val-name">
                Description
              </label>
              <div className="col-lg-6">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Add a description of the room"
                  value={unitDescription}
                  onChange={(e) => setUnitDescription(e.target.value)}
                />
                {errors.unitDescription && (
                  <div className="text-danger">{errors?.unitDescription}</div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setUnitName(null);
              setUnitType(null);
              setUnitDescription(null);
              setErrors({});
              setUnitIndex(null);
              setModalCentered(false);
            }}
            variant="danger light"
          >
            Close
          </Button>
          <Button onClick={() => handleAddUnit()} variant="success">
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="fade"
        show={documentModalCentered}
        onHide={() => {
          setDocumentModalCentered(false);
          setDocumentName("");
          setDocumentDescription("");
          setDocumentFile(null); // Ensure this is null to reset any existing file
          setDocumentPreview(""); // Clear any existing preview
          setDocumentErrors({});
          setDocumentIndex(null);
        }}
        centered
      >
        <Modal.Header>
          <Modal.Title>Add Document</Modal.Title>
          <Button
            onClick={() => {
              setDocumentModalCentered(false);
              setDocumentName("");
              setDocumentDescription("");
              setDocumentFile(null); // Ensure this is null to reset any existing file
              setDocumentPreview(""); // Clear any existing preview
              setDocumentErrors({});
              setDocumentIndex(null);
            }}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="form-group mb-3 row">
              <label className="col-lg-4 col-form-label" htmlFor="val-name">
                Label <span className="text-danger">*</span>
              </label>
              <div className="col-lg-6">
                <input
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Label for the document"
                />{" "}
                {documentErrors.documentName && (
                  <div className="text-danger">
                    {documentErrors.documentName}
                  </div>
                )}
              </div>
            </div>
            {/* Description */}
            <div className="form-group mb-3 row">
              <label className="col-lg-4 col-form-label" htmlFor="val-name">
                Description
              </label>
              <div className="col-lg-6">
                <textarea
                  value={documentDescription}
                  onChange={(e) => setDocumentDescription(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Add a description for the file"
                />{" "}
                {documentErrors.documentDescription && (
                  <div className="text-danger">
                    {documentErrors.documentDescription}
                  </div>
                )}
              </div>
            </div>
            {/* {documentIndex === null && ( */}
            <div className="form-group mb-3 row">
              <label className="col-lg-4 col-form-label" htmlFor="val-name">
                File <span className="text-danger">*</span>
              </label>
              <div {...getDocRootProps()} className="drop-zone ">
                <input {...getDocInputProps()} />
                {isDocDragActive ? (
                  <p>Drop the document here ...</p>
                ) : (
                  <p>
                    Drag 'n' drop a document here, or click to select a document
                  </p>
                )}
                {/* Document Preview */}
                {documentPreview ? (
                  <div>
                    <p>Preview:</p>

                    <p
                      style={{
                        color: "blue",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        lineClamp: 1,
                      }}
                    >
                      {documentFile?.name}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>Preview:</p>

                    <p
                      style={{
                        color: "blue",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        lineClamp: 1,
                      }}
                    >
                      {documentFile ? documentFile.split("/").pop() : ""}
                    </p>
                  </div>
                )}
              </div>
              {documentErrors.documentFile && (
                <div className="text-danger">{documentErrors.documentFile}</div>
              )}
            </div>
            {/* )} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setDocumentModalCentered(false);
              setDocumentName("");
              setDocumentDescription("");
              setDocumentFile(null); // Ensure this is null to reset any existing file
              setDocumentPreview(""); // Clear any existing preview
              setDocumentErrors({});
              setDocumentIndex(null);
            }}
            variant="danger light"
          >
            Close
          </Button>
          <Button onClick={() => handleAddDocument()} variant="success">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function OnSubmitEditProperty(
  setIsLoading,
  units,
  documents,
  deletedUnits,
  deletedDocument,
  oldPropertyData,
  PropertyData,
  nav,
  files
) {
  return async () => {
    setIsLoading(true);

    const hasUnitChanges = [...units, ...deletedUnits].some(
      (unit) => unit.isNew || unit.isEdited || unit.isDeleted
    );
    const hasDocChanges = [...documents, ...deletedDocument].some(
      (doc) => doc.isNew || doc.isEdited || doc.isDeleted
    );

    const hasImageChanges = files.some((file) => file.isNew || file.isDeleted);
    const hasPropertyChanges = !isEqual(oldPropertyData, PropertyData);
    if (
      !hasPropertyChanges &&
      !hasUnitChanges &&
      !hasDocChanges &&
      !hasImageChanges
    ) {
      toast.error(
        "No changes made. Please edit the details before submitting."
      );
      setIsLoading(false);
      return;
    }

    if (PropertyData.return_percentage) {
      if (
        PropertyData.return_percentage < 1 ||
        PropertyData.return_percentage > 100
      ) {
        toast.error("Return percentage must be between 1-100");
        setIsLoading(false);
        return;
      }
    }

    if (
      !PropertyData.property_name ||
      !PropertyData.property_number ||
      !PropertyData.type ||
      !PropertyData.location ||
      !PropertyData.city ||
      !PropertyData.total_value
      // PropertyData.return_percentage
    ) {
      const missingFields = [];
      if (!PropertyData.property_name) missingFields.push("Property Name");
      if (!PropertyData.property_number) missingFields.push("Property Number");
      if (!PropertyData.type) missingFields.push("Type");
      if (!PropertyData.location) missingFields.push("Location");
      if (!PropertyData.city) missingFields.push("City");
      if (!PropertyData.total_value) missingFields.push("Total Value");

      let errorMessage =
        `The fields ${missingFields} is required.` +
        " Please ensure no field is left empty except Images,Documents and Units.";

      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    try {
      const documentUploads = [...documents, ...deletedDocument]
        .filter((doc) => doc.isNew || doc.isEdited || doc.isDeleted)
        .map(async (doc) => {
          if (!doc.isDeleted) {
            if (doc.file instanceof File) {
              const formData = new FormData();
              formData.append("file", doc.file);
              const response = await mediaUpload(formData);
              return {
                ...doc,
                document_link: response.data.files[0],
                isNew: false,
              };
            } else {
              return doc;
            }
          } else {
            return doc;
          }
        });

      const imageUploads = files.map(async (file) => {
        if (file.isDeleted) {
          return { ...file };
        } else if (file.isNew) {
          const formData = new FormData();
          formData.append("file", file.file);
          try {
            const response = await mediaUpload(formData);
            return { ...file, url: response.data.files[0], isNew: true };
          } catch (error) {
            console.error("Error uploading new image:", error);
            return null;
          }
        }
        return null;
      });

      const uploadedDocuments = await Promise.all(documentUploads);
      const uploadedImages = await Promise.all(imageUploads);

      const updatedFiles = uploadedImages
        .filter((file) => file !== null)
        .map((file) => ({
          ...(file.isNew || file.isDeleted ? { url: file.url } : {}),
          ...(file.isDeleted ? { is_deleted: file.isDeleted } : {}),
        }));

      const propertyPayload = {
        _id: PropertyData._id,
        property_name: PropertyData.property_name,
        city: PropertyData.city,
        type: PropertyData.type,
        location: PropertyData.location,
        location_link: PropertyData.location_link || "",
        property_number: PropertyData.property_number,
        total_value: PropertyData.total_value,
        return_percentage: PropertyData.return_percentage,
        units: [...units, ...deletedUnits]
          .filter((unit) => unit.isNew || unit.isEdited || unit.isDeleted)
          .map((unit) => ({
            name: unit.name,
            type: unit.type,
            description: unit.description,
            ...(unit._id ? { _id: unit._id } : {}),
            ...(unit.isDeleted ? { is_deleted: unit.isDeleted } : {}),
          })),
        documents: uploadedDocuments.map((doc) => ({
          label: doc.label,
          description: doc.description,
          document_link: doc.document_link,
          ...(doc._id ? { _id: doc._id } : {}),
          ...(doc.isDeleted ? { is_deleted: doc.isDeleted } : {}),
        })),

        images: updatedFiles.map((file) => ({
          url: file.url,
          ...(file.is_deleted ? { is_deleted: file.is_deleted } : {}),
        })),
      };

      console.log(propertyPayload);

      await EditProperty(propertyPayload);
      toast.success("Property updated successfully");
      nav("/property-list");
    } catch (error) {
      console.log("Error during property update:", error);

      const errorDetails = error?.response?.data?.details;
      if (Array.isArray(errorDetails)) {
        errorDetails.forEach((err) => {
          const sanitizedMessage = err?.message
            ?.replace(/["/,]/g, "")
            .replace(/_/g, " ");
          toast.error(sanitizedMessage);
        });
      } else {
        toast.error("Failed to update property. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
}
