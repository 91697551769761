import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
//** Import Image */
// import FrontViewSlider from "./FrontViewSlider";
import { getFundRequestDetails } from "../../../services/FundServices";
// import CarousalSlider from "./CarousalSlider";

function FundRequestDetails() {
  const nav = useNavigate();

  const path = window.location.pathname.split("/");
  const fundId = path[path.length - 1];
  const [FundDetails, setFundDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get property details from API
    getFundRequestDetails(fundId)
      .then((res) => {
        setFundDetails(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        nav("/page-error-400");
        setIsLoading(false);
      });
  }, [fundId, nav]);

  console.log(FundDetails);

  const basicInfo = [
    {
      heading: {
        name: "Fund details",
      },
      details: [
        {
          title: "Requested date",
          subtitle: new Date(FundDetails?.requested_date).toUTCString(),
        },
        { title: "Requested amount", subtitle: FundDetails?.requested_amount },
        {
          title: "Status",
          subtitle: FundDetails?.status,
        },
        {
          title: "Comment",
          subtitle: FundDetails?.comment,
        },
      ],
    },
    {
      heading: {
        name: "Requested by",
      },
      details: [
        { title: "Name", subtitle: FundDetails?.requested_by?.name },
        { title: "Email", subtitle: FundDetails?.requested_by?.email },
        { title: "Role", subtitle: FundDetails?.requested_by?.role },
        {
          title: "Status",
          subtitle: FundDetails?.requested_by?.active ? "Active" : "Inactive",
        },
        {
          title: "Created Date",
          subtitle: new Date(
            FundDetails?.requested_by?.created_at
          ).toUTCString(),
        },
      ],
    },
    {
      heading: {
        name: `${
          FundDetails?.accepted_by
            ? "Accepted by"
            : FundDetails?.rejected_by && "Rejected by"
        }`,
      },
      details: [
        {
          title: "Name",
          subtitle: `${
            FundDetails?.accepted_by
              ? `${FundDetails?.accepted_by?.name}`
              : FundDetails?.rejected_by && `${FundDetails?.rejected_by?.name}`
          }`,
        },
        {
          title: "Email",
          subtitle: `${
            FundDetails?.accepted_by
              ? `${FundDetails?.accepted_by?.email}`
              : FundDetails?.rejected_by && `${FundDetails?.rejected_by?.email}`
          }`,
        },
        {
          title: "Role",
          subtitle: `${
            FundDetails?.accepted_by
              ? `${FundDetails?.accepted_by?.role}`
              : FundDetails?.rejected_by && `${FundDetails?.rejected_by?.role}`
          }`,
        },
        {
          title: "Status",
          subtitle: `${
            FundDetails?.accepted_by
              ? `${FundDetails?.accepted_by?.active ? "Active" : "De-active"}`
              : FundDetails?.rejected_by &&
                `${FundDetails?.rejected_by?.active ? "Active" : "De-active"}`
          }`,
        },
        {
          title: "Created Date",
          subtitle: new Date(
            `${
              FundDetails?.accepted_by
                ? `${FundDetails?.accepted_by?.created_at}`
                : FundDetails?.rejected_by &&
                  `${FundDetails?.rejected_by?.created_at}`
            }`
          ).toUTCString(),
        },
      ],
    },
  ];

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Fund Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/fund-requests">Fund requests</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/fund-requests/${fundId}`}>{FundDetails?._id}</Link>
            </li>
          </ol>
        </div>
        {/* <Link to="/property-details" className="btn btn-danger rounded me-3">
          Update Info
        </Link> */}
        <Link
          onClick={() => window.location.reload()}
          className="btn btn-primary rounded light"
        >
          Refresh
        </Link>
      </div>
      <div className="row d-flex justify-content-center align-content-center ">
        {basicInfo.map((customerSet, index) => (
          <div className="col-xl-4 col-lg-4 col-sm-12" key={index}>
            <div className="card overflow-hidden">
              <div className="text-center p-3 bg-success">
                <h3 className="mt-3 mb-1 text-white">
                  {customerSet.heading.name}
                </h3>
                {/* <p className="text-white mb-0">
                      {customerSet.heading.phone}
                    </p> */}
              </div>
              <ul className="list-group list-group-flush">
                {customerSet.details.map((item, detailIndex) => (
                  <li
                    className="list-group-item d-flex justify-content-between"
                    key={detailIndex}
                  >
                    <span className="mb-0">{item.title}</span>
                    <strong className="text-black text-uppercase">
                      {item.title === "Status" ? (
                        <Badge
                          style={{
                            cursor: "default",
                          }}
                          // as="a"
                          // href=""
                          bg="badge-rounded"
                          className={` badge-${
                            item.subtitle === "Active"
                              ? "success"
                              : item.subtitle === "accepted"
                              ? "outline-success"
                              : FundDetails?.status === "rejected"
                              ? "outline-danger"
                              : "outline-warning"
                          }`}
                        >
                          {item.subtitle}
                        </Badge>
                      ) : (
                        item.subtitle
                      )}
                    </strong>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
export default FundRequestDetails;
