import React, { useState } from "react";
import UserForm from "./UserForm";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PageTitle from "../../layouts/PageTitle";
import { addNewUser } from "../../../services/UsersServices";

const AddNewUser = () => {
  const nav = useNavigate();

  const [userAccount, setUserAccount] = useState({});
  //   const [isLoading, setIsLoading] = useState(true);

  const onSubmit = () => {
    const postData = {
      name: userAccount?.name,
      staff_id: userAccount?.staff_id,
      email: userAccount?.email,
      password: userAccount?.password,
      role: userAccount?.role,
    };

    addNewUser(postData)
      .then((res) => {
        toast.success("User added successfully");
        nav("/users-list");
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Something went wrong"
        );
      });
  };

  //   if (isLoading) {
  //     return (
  //       <div id="preloader">
  //         <div className="sk-three-bounce">
  //           <div className="sk-child sk-bounce1"></div>
  //           <div className="sk-child sk-bounce2"></div>
  //           <div className="sk-child sk-bounce3"></div>
  //         </div>
  //       </div>
  //     );
  //   }
  return (
    <>
      <PageTitle
        activeMenu={"Add User"}
        activePath={"add-user"}
        motherMenu={"User List"}
        motherPath={"users-list"}
      />
      <UserForm
        userAccount={userAccount}
        setUserAccount={setUserAccount}
        onSubmit={onSubmit}
        nav={nav}
      />
    </>
  );
};

export default AddNewUser;
