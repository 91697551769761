import React from "react";
import { Modal, Button } from "react-bootstrap";

const RentRequestModal = ({ modalType, setModalType, postData, onAction }) => {
  const modalProps = {
    accept: {
      title: "Confirm Rent Request Acceptance",
      subtitle: "Are you sure you want to accept this rent request?",
      confirmButton: "Accept",
      variant: "success",
    },
    reject: {
      title: "Confirm Rent Request Rejection",
      subtitle:
        "Are you sure you want to reject this rent request? This action cannot be undone.",
      confirmButton: "Reject",
      variant: "danger",
    },
  };

  const handleClose = () => setModalType(null);

  if (!modalType) return null;

  return (
    <Modal show onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalProps[modalType].title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-lg font-medium mb-4">
          {modalProps[modalType].subtitle}
        </h4>
        {modalType === "accept" && postData && (
          <div className="mt-4 space-y-2">
            <p className="mb-2">
              <span className="font-medium">Amount:</span>{" "}
              {postData.amount?.toLocaleString()}
            </p>
            {postData.description && (
              <p className="mb-0">
                <span className="font-medium">Description:</span>{" "}
                {postData.description}
              </p>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant={modalProps[modalType].variant}
          onClick={() => {
            onAction(
              postData?._id,
              modalType === "accept" ? "Approve" : "Reject"
            );
            handleClose();
          }}
        >
          {modalProps[modalType].confirmButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RentRequestModal;
