import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import dashboardReducer from "./reducers/DashboardReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import fundsReducer from "./reducers/FundReducers";
import PropertyReducer from "./reducers/PropertyReducer";
import supervisorReducer from "./reducers/SupervisorReducer";
import rentReducer from "./reducers/RentReducer";
import LeadsReducer from "./reducers/LeadsReducer";
import DealsReducer from "./reducers/DealsReducer";
import usersReducer from "./reducers/UsersReducers";
import toggleMenu from "./reducers/ToggleReducer";
import investorsReducer from "./reducers/InvestorsReducers";
import notApprovedTransactionReducer from "./reducers/NotApprovedTransactionReducer";
import chequeReducer from "./reducers/ChequeReducer";
import securityDepositReducer from "./reducers/SecurityDepositReducer";
import rentRequestReducer from "./reducers/RentRequestReducer";
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
  sideMenu: toggleMenu,
  dashboard: dashboardReducer,
  auth: AuthReducer,
  funds: fundsReducer,
  properties: PropertyReducer,
  supervisors: supervisorReducer,
  rent: rentReducer,
  leads: LeadsReducer,
  deals: DealsReducer,
  users: usersReducer,
  investors: investorsReducer,
  notApprovedTransactions: notApprovedTransactionReducer,
  chequeList: chequeReducer,
  securityDepositList: securityDepositReducer,
  rentRequestList: rentRequestReducer,
});

export const store = createStore(reducers, composeEnhancers(middleware));
