// dashboardReducer.js

import {
  REQUEST_START,
  UPDATE_INVESTORS_LIST_DATA,
} from "../actions/InvestorsAction";

const initialState = {
  loading: false,
  investorsList: [],
};

const investorsReducer = (state = initialState, action) => {
  if (action.type === REQUEST_START) {
    return {
      ...state,
      loading: true,
    };
  } else if (action.type === UPDATE_INVESTORS_LIST_DATA) {
    return {
      ...state,
      investorsList: action.payload,
      loading: false,
    };
  } else {
    return {
      ...state,
    };
  }
};

export default investorsReducer;
