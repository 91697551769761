import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  // baseURL: `https://landtech-api.iotics.me`,
  baseURL: process.env.REACT_APP_API,

  // baseURL: `http://localhost:8080`,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.token;
  if (token) {
    config.headers.Authorization = `${token}`;
  }

  return config;
});

export default axiosInstance;
