import axiosInstance from "./AxiosInstance";

export function approveRent(id, type) {
  return axiosInstance.get(
    `/api/cash-hand-over/manage?id=${id}&action=${type}`
  );
}

export function getNotApprovedList() {
  return axiosInstance.get(`/api/cash-hand-over`);
}

export function getCashHandOverDetails(id) {
  return axiosInstance.get(`/api/cash-hand-over/${id}`);
}

export function updateCashHandOverDetails(id, postData) {
  console.log(postData);
  return axiosInstance.post(`/api/cash-hand-over/edit/${id}`, postData);
}
