import React from "react";
import { Modal, Button } from "react-bootstrap";

const ChequeRequestModal = ({
  modalCentered,
  setModalCentered,
  postData,
  onAccept,
}) => {
  return (
    <Modal show={modalCentered} onHide={() => setModalCentered(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Close Cheque</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="subtitle">
          Are you sure you want to close this cheque?
        </h4>
        <div className="mt-3">
          <h4 className="mb-2 subtitle">
            Amount : {postData?.amount?.toLocaleString()}
          </h4>
          {postData?.comment && (
            <div>
              <strong>Comment:</strong> {postData?.comment}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setModalCentered(false)}>
          Cancel
        </Button>
        <Button variant="success" onClick={onAccept}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChequeRequestModal;
