import { closeCheque, getChequeList } from "../../services/ChequeServices";
import toast from "react-hot-toast";

export const CHEQUE_LIST = "CHEQUE_LIST";
export const REQUEST_START = "REQUEST_START";

export function chequeListAction() {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getChequeList()
      .then((response) => {
        const data = response.data.data;

        dispatch(updateChequeListData(data));
      })
      .catch((error) => {});
  };
}

export function closeChequeAction(data, setModalCentered) {
  return (dispatch) => {
    closeCheque(data)
      .then((response) => {
        toast.success("Cheque closed successfully");
        dispatch(chequeListAction(data));
      })
      .catch((error) => {
        toast.error(`${error?.response?.data?.error}`);
        console.log(error);
      })
      .finally(() => {
        setModalCentered(false);
      });
  };
}

// FundActions.js

export function updateChequeListData(data) {
  return {
    type: CHEQUE_LIST,
    payload: data,
  };
}
