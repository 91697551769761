import { Badge, Button, Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";

import noImage from "../../../images/no-image.jpg";

export default function RentDetailsModal(props) {
  return (
    <Modal
      className="fade bd-example-modal-lg"
      size="lg"
      show={props.modalCentered}
      onHide={props.setModalCentered}
      centered
    >
      <Modal.Header>
        <Modal.Title>Details</Modal.Title>
        <Button
          onClick={() => {
            props.setModalCentered(false);
          }}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <div className="row">
          <div
            className="col-12" // key={ind}
          >
            <div className="property-card style-1">
              <div className={`dz-media `}>
                <Zoom>
                  <img alt="" src={props.viewDetails?.images?.[0] || noImage} />
                </Zoom>
              </div>

              <div className={`card-body pt-2 pb-3`}>
                <ul className="list-group list-group-flush">
                  {props.viewDetails?.unit && (
                    <li className="list-group-item">
                      <span className="mb-0 title">Room no</span> :
                      <span className="text-black ms-2">
                        {props.viewDetails?.unit
                          ? props.viewDetails?.unit?.name
                          : "No Unit Available"}
                      </span>
                    </li>
                  )}

                  <li className="list-group-item">
                    <span className="mb-0 title">Property</span> :
                    <span className="text-black ms-2">
                      {props.viewDetails?.property
                        ? props.viewDetails?.property?.property_name
                        : "No property Available"}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <span className="mb-0 title">Description</span> :
                    <span className="text-black ms-2">
                      {props.viewDetails?.description}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <Modal.Title>User details</Modal.Title>
                  </li>

                  <li className="list-group-item">
                    <span className="mb-0 title">Email</span> :
                    <span className="text-black desc-text ms-2">
                      {props.viewDetails?.user?.email}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <span className="mb-0 title">Role</span> :
                    <span className="text-black desc-text ms-2">
                      {props.viewDetails?.user?.role}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <span className="mb-0 title">Status</span> :
                    <span className="text-black desc-text ms-2">
                      <Badge
                        bg=""
                        className={`badge-${
                          props.viewDetails?.user?.active ? "success" : "danger"
                        } badge-xs`}
                      >
                        {props.viewDetails?.user?.active
                          ? "Active"
                          : "Inactive"}
                      </Badge>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="flex-column d-flex justify-content-center">
       <Button
         variant="success"
         onClick={() => {
           setModalCentered(false);
         }}
       >
         Close
       </Button>
      </Modal.Footer> */}
    </Modal>
  );
}
