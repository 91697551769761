import React, { useEffect, useState } from "react";
import noImage from "../../../images/no-image.jpg";
import PageTitle from "../../layouts/PageTitle";
import { Badge, Dropdown } from "react-bootstrap";
import { Tab } from "react-bootstrap";

import { getSupervisorDetails } from "../../../services/SupervisorService";
import { useNavigate } from "react-router-dom";

const SupervisorDetails = () => {
  const path = window.location.pathname.split("/");
  const supervisorId = path[path.length - 1];
  const [supervisorDetails, setSupervisorDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // const [isPropertyLoading, setIsPropertyLoading] = useState(true);

  const [pageNo, setPageNo] = useState(1);
  const nav = useNavigate();

  useEffect(() => {
    getSupervisorDetails(supervisorId)
      .then((res) => {
        // console.log(res.data);
        setSupervisorDetails(res.data);

        setIsLoading(false);
      })
      .then((err) => {
        console.log(err);
      });
  }, [supervisorId, pageNo]);

  console.log(supervisorDetails);

  const user = supervisorDetails?.user;
  const properties = supervisorDetails?.properties;
  const propertiesLength = supervisorDetails?.properties?.length;

  console.log(properties);

  // const activePropertiesCount = properties?.filter(
  //   (property) => property.active === true
  // ).length;

  // const percentage = (activePropertiesCount / propertiesLength) * 100;
  const totalPages = Math.ceil(supervisorDetails?.properties?.length / 12);

  const bioList = [
    { title: "Email", value: user?.email },

    { title: "Status", value: user?.active ? "Active" : "Inactive" },

    // { title: "Number of properties", value: propertiesLength } ,
  ];

  // const handlePageChange = (isPrev) => {
  //   setIsPropertyLoading(true);
  //   let newPageNo = pageNo + (isPrev ? -1 : 1);
  //   newPageNo = Math.max(newPageNo, 1);
  //   setPageNo(newPageNo);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        motherMenu={"Supervisor list"}
        motherPath={"supervisor-list"}
        activeMenu={"Supervisor details"}
      />
      <div className="row gx-lg-3">
        <div className="col-xl-9 col-xxl-12">
          <div className="row gx-lg-3">
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="card overflow-hidden">
                <div
                  className="text-center p-3 overlay-box "
                  //   style={{ backgroundImage: `url(${bigimg})` }}
                >
                  {/* <div className="profile-photo">
                    <img
                      src={profile}
                      width="100"
                      className="img-fluid rounded-circle"
                      alt="/"
                    />
                  </div> */}
                  <h3 className="mt-3 mb-1 text-white"> {user?.name} </h3>
                  <p className="text-white mb-0">{user?.role}</p>
                </div>
                <ul className="list-group list-group-flush">
                  {bioList?.length > 0 &&
                    bioList?.map((item, ind) => (
                      <li
                        className="list-group-item d-flex justify-content-between"
                        key={ind}
                      >
                        <span className="mb-0">{item.title}</span>{" "}
                        <strong
                          className={`${
                            item.value === "Active" || item.value === "Inactive"
                              ? item.value === "Active"
                                ? "text-success"
                                : "text-danger"
                              : "text-black"
                          }`}
                        >
                          {item.value}
                        </strong>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-sm-12">
              <div className="card overflow-hidden">
                <div className="card-header">
                  <h4 className="title my-3">Property Status</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="card property-card border">
                        <div className="card-body">
                          <div className="media align-items-center">
                            <div className="media-body me-2">
                              <h2 className="fs-28 text-black font-w600">
                                {propertiesLength}
                              </h2>
                              <p className="property-p mb-0 text-black font-w500">
                                Total Properties
                              </p>
                            </div>
                            {/* <div className="d-inline-block position-relative donut-chart-sale">
                              <DonutChart
                                backgroundColor="rgb(60, 76, 184)"
                                backgroundColor2="rgba(236, 236, 236, 1)"
                                value={Math.floor(percentage)}
                              />
                              <small className="text-primary">
                                {Math.floor(percentage)}%
                              </small>
                              <span className="circle bgl-primary"></span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {properties?.length > 0 && (
            <>
              <h4 className="title my-3">Supervisor property listing</h4>

              {isLoading ? (
                <div className="sk-three-bounce h-100">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              ) : (
                <Tab.Container defaultActiveKey={"Grid"}>
                  <Tab.Content>
                    <Tab.Pane eventKey={"Grid"}>
                      <div className="row">
                        {properties?.map((item, ind) => (
                          <div
                            className={`col-lg-4 m-b30 ${item.column}`}
                            key={ind}
                          >
                            <div className="property-card style-1">
                              <div className="dz-media">
                                <ul>
                                  <a
                                    href={item.location_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <li className="badge badge-sm badge-primary light">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-map-pin"
                                      >
                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                        <circle cx="12" cy="10" r="3"></circle>
                                      </svg>{" "}
                                      {item.location}, {item.city}
                                    </li>
                                  </a>
                                  <li className="rent badge badge-sm badge-primary">
                                    {item.type}
                                  </li>
                                </ul>
                                <img
                                  src={
                                    item.images && item.images.length > 0
                                      ? item.images[0]
                                      : noImage
                                  }
                                  alt="/"
                                />
                              </div>

                              <div
                                className={`card-body pt-2 pb-3 ${
                                  !item?.active ? "disabled" : ""
                                }`}
                              >
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item">
                                    <span className="mb-0 title">
                                      Property name
                                    </span>{" "}
                                    :
                                    <span className="text-black ms-2">
                                      {item?.property_name}
                                    </span>
                                  </li>
                                  <li className="list-group-item">
                                    <span className="mb-0 title">
                                      Property number
                                    </span>{" "}
                                    :
                                    <span className="text-black ms-2">
                                      {item.property_number}
                                    </span>
                                  </li>
                                  <li className="list-group-item">
                                    <span className="mb-0 title">Status</span> :
                                    <span className={`text-black ms-2 `}>
                                      <Badge
                                        href=""
                                        bg="badge-rounded"
                                        className={` ${
                                          item.active
                                            ? "badge-outline-success"
                                            : "badge-outline-danger"
                                        } `}
                                      >
                                        {item.active ? "Active" : "Inactive"}
                                      </Badge>
                                    </span>
                                  </li>
                                  {/* <li className="list-group-item">
                                    <span className="mb-0 title">Users</span> :
                                    <span className="text-black desc-text ms-2">
                                      <td>
                                        {item?.users
                                          ?.map((user) => user?.name)
                                          .join(", ")}
                                      </td>
                                    </span>
                                  </li> */}
                                  <li className="list-group-item">
                                    <span className="mb-0 title">Rooms</span> :
                                    <span className="text-black desc-text ms-2">
                                      {item.units.length
                                        ? item.units.length
                                        : 0}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="list-group-item flex-row d-flex justify-content-end">
                                <Dropdown className="">
                                  <Dropdown.Toggle
                                    variant=""
                                    className="btn-link i-false"
                                  >
                                    <svg
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                                        stroke="#3E4954"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                                        stroke="#3E4954"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                                        stroke="#3E4954"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="dropdown-menu-right">
                                    <Dropdown.Item
                                      className="text-black"
                                      onClick={() => {
                                        nav(`property-details/${item._id}`);
                                      }}
                                    >
                                      View property
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              )}

              {/* Pagination */}
              {/* <div className="row">
                <div className="col-xl-12 ">
                  <div className="table-responsive table-hover fs-14 order-list-table">
                    <div
                      id="orderList"
                      className="dataTables_wrapper no-footer"
                    >
                      <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                          Showing {propertiesLength} entries
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                          <Link
                            className="paginate_button previous"
                            to="#"
                            onClick={() => handlePageChange(true)}
                          >
                            <i className="fa fa-angle-double-left" />
                          </Link>
                          <span>
                            {Array.from({ length: totalPages }, (_, i) => (
                              <Link
                                key={i}
                                to="#"
                                className={`paginate_button ${
                                  pageNo === i + 1 ? "current" : ""
                                }`}
                                onClick={() => {
                                  setIsPropertyLoading(true);

                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                  setPageNo(i + 1);
                                }}
                              >
                                {i + 1}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className="paginate_button next"
                            to="#"
                            onClick={() => handlePageChange(false)}
                          >
                            <i className="fa fa-angle-double-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SupervisorDetails;
