/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { PropertyAction } from "../../../store/actions/PropertryActions";
import { UsersListAction } from "../../../store/actions/UsersAction";
import { onUserExport } from "../../../services/ExportExcelService";
import DateRangePicker from "react-bootstrap-daterangepicker";

import { Button, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const formatDate = (date) => {
  const d = new Date(date);
  let day = "" + d.getDate();
  let month = "" + (d.getMonth() + 1);
  const year = d.getFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return [day, month, year].join("/");
};

const UserExport = () => {
  const { usersList } = useSelector((state) => state.users);

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [selectedUser, setSelectedUser] = useState();
  const [dateRange, setDateRange] = useState({
    startDate: formatDate(new Date()), // Initial start date as today's date
    endDate: formatDate(new Date()), // Initial end date as today's date
  });
  const [isDateRangeDisabled, setIsDateRangeDisabled] = useState(false);
  const [isUserDisabled, setIsUserDisabled] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [exportFormat, setExportFormat] = useState("excel");
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const dispatch = useDispatch();

  const [incomeTotal, setIncomeTotal] = useState(0);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [rentTotal, setRentTotal] = useState(0);

  const [incomeFormattedContent, setIncomeFormattedContent] = useState([]);
  const [expenseFormattedContent, setExpenseFormattedContent] = useState([]);
  const [rentFormattedContent, setRentFormattedContent] = useState([]);

  useEffect(() => {
    dispatch(UsersListAction("", "All"));
  }, [dispatch]);

  useEffect(() => {
    const userOptions = usersList?.users?.map((user) => ({
      value: user?.user?._id,
      label: `${user?.user?.name} (${user?.user?.role})`,
    }));
    setFilteredUsers(userOptions);
  }, [usersList]);

  //   console.log(usersList);

  const handleSelectUser = (selectedOptions) => {
    setSelectedUser(selectedOptions);
    setShowDownloadButton(false);
  };

  const handleCheckboxChange = (event) => {
    setDateRange((prevState) => ({
      ...prevState,
      all: event.target.checked,
    }));
    setIsDateRangeDisabled(event.target.checked);
    setShowDownloadButton(false);
  };

  const handleUserCheckboxChange = (event) => {
    setSelectedUser([]);
    setIsUserDisabled(event.target.checked);
    console.log(isUserDisabled);
    setShowDownloadButton(false);
  };

  const handleDateRangeChange = (start, end) => {
    setDateRange({
      startDate: formatDate(start._d),
      endDate: formatDate(end._d),
    });
    setShowDownloadButton(false);
  };

  //

  const validateForm = () => {
    if (!selectedUser) {
      toast.error("User is required");
      return false;
    }

    return true;
  };

  const onSubmitRequestExport = async () => {
    if (!validateForm()) return;

    // const queryParams = {
    //   type: selectedType.value,
    //   ...(selectedUser.length > 0 && {
    //     user: selectedUser.map((user) => user.value),
    //   }),
    //   ...(selectedProperty && { property: selectedProperty.value }),
    // };

    // if (!dateRange.all) {
    //   queryParams.date_range = `${dateRange.startDate}-${dateRange.endDate}`;
    // }

    try {
      const res = await onUserExport(selectedUser.value, dateRange);

      console.log(res.data);

      const { expense_transactions, income_transactions, rent_transactions } =
        res.data;

      console.log(expense_transactions);
      console.log(income_transactions);
      console.log(rent_transactions);

      const formatTransactionData = (transactions) =>
        transactions.map((row, index) => ({
          No: index + 1,
          voucher_number: row.voucher_number || "",
          date: row.date ? new Date(row.date).toDateString() : "",
          room_number: row.unit?.name || "",
          amount: typeof row.amount === "number" ? row.amount.toFixed(2) : "",
          description: row.description || "",
        }));

      if (
        expense_transactions?.length > 0 ||
        income_transactions?.length > 0 ||
        rent_transactions?.length > 0
      ) {
        setIncomeFormattedContent(formatTransactionData(income_transactions));
        setExpenseFormattedContent(formatTransactionData(expense_transactions));
        setRentFormattedContent(formatTransactionData(rent_transactions));

        setIncomeTotal(
          res.data.total_income && !isNaN(res.data.total_income)
            ? Number(res.data.total_income).toFixed(2)
            : "0.00"
        );
        setExpenseTotal(
          res.data.total_expense && !isNaN(res.data.total_expense)
            ? Number(res.data.total_expense).toFixed(2)
            : "0.00"
        );
        setRentTotal(
          res.data.total_rent && !isNaN(res.data.total_rent)
            ? Number(res.data.total_rent).toFixed(2)
            : "0.00"
        );

        setIsDataAvailable(true);
      } else {
        setIsDataAvailable(false);
        toast.error("No data found");
      }

      setShowDownloadButton(true);
    } catch (error) {
      console.log("Error during export:", error);
      setShowDownloadButton(false);
      toast.error("Failed to export data");
    }
  };

  const onDownloadExport = async () => {
    if (exportFormat === "excel") {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet(`${selectedUser.label} Report`);

      const columns = [
        { header: "No", key: "No", width: 15 },
        { header: "Voucher number", key: "voucher_number", width: 15 },
        { header: "Date", key: "date", width: 15 },
        { header: "Room Number", key: "room_number", width: 15 },
        { header: "Amount", key: "amount", width: 15 },
        { header: "Description", key: "description", width: 35 },
      ];

      // Set columns without adding headers
      sheet.columns = columns.map((col) => ({
        key: col.key,
        width: col.width,
      }));

      // Add Title Row "
      const titlePropertyRow = sheet.addRow([selectedUser.label]);
      titlePropertyRow.font = { bold: true, size: 16 };
      titlePropertyRow.height = 25;
      sheet.mergeCells(
        `A${titlePropertyRow.number}:D${titlePropertyRow.number}`
      );
      titlePropertyRow.alignment = { vertical: "middle", horizontal: "center" };

      // Add Date Range Row
      let dateStr = dateRange.all
        ? "All Dates"
        : `${dateRange.startDate} - ${dateRange.endDate}`;
      const dateRangeRow = sheet.addRow([dateStr]);
      dateRangeRow.font = { bold: true, size: 12 };
      dateRangeRow.height = 20;
      sheet.mergeCells(`A${dateRangeRow.number}:D${dateRangeRow.number}`);
      dateRangeRow.alignment = { vertical: "middle", horizontal: "center" };

      const alignCellsCenter = (row) => {
        row.eachCell((cell) => {
          cell.alignment = {
            horizontal: "center",
            vertical: "center",
            wrapText: true,
          };

          cell.border = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          };
        });
      };

      // Function to Set Column Widths Based on Content
      const getMaxColumnWidth = (data, columns) => {
        const widths = columns.reduce((acc, col) => {
          acc[col.key] = col.header.length;
          return acc;
        }, {});

        data.forEach((row) => {
          Object.keys(row).forEach((key) => {
            if (row[key]) {
              const cellLength = row[key].toString().length;
              if (cellLength > (widths[key] || 0)) {
                widths[key] = cellLength;
              }
            }
          });
        });

        return widths;
      };

      const setColumnWidths = (widths) => {
        sheet.columns.forEach((col) => {
          col.width = Math.max(widths[col.key] + 2, col.width);
        });
      };

      const addSection = (title, data, total) => {
        let headerClr;
        let bodyClr;
        if (title === "Expense") {
          headerClr = "FF935F";
          bodyClr = "E6FFBE9F";
        } else {
          headerClr = "0476D0";
          bodyClr = "E696B9DC";
        }

        if (data.length > 0 || total !== undefined) {
          //   Add section title
          const headerRow = sheet.addRow([title]);
          headerRow.font = { bold: true };
          headerRow.height = 30;
          headerRow.alignment = { vertical: "middle", horizontal: "center" };
          sheet.mergeCells(`A${headerRow.number}:D${headerRow.number}`);

          //   Add column headers for the section
          const columnsHeaderRow = sheet.addRow(
            columns.map((col) => col.header)
          );
          columnsHeaderRow.height = 20;
          columnsHeaderRow.font = { bold: true, color: { argb: "FFFFFF" } };
          columnsHeaderRow.eachCell((cell) => {
            cell.border = {
              top: { style: "double" },
              bottom: { style: "double" },
              left: { style: "double" },
              right: { style: "double" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: headerClr }, //  background color for headers
            };
          });
          alignCellsCenter(columnsHeaderRow);

          // Add data rows
          data.forEach((row, index) => {
            const dataRow = sheet.addRow(row);
            dataRow.eachCell((cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: index % 2 === 0 ? bodyClr : "FFFFFF" }, // Alternating colors: light gray and white
              };
            });
            alignCellsCenter(dataRow);
          });

          // Add total row if applicable
          if (total !== undefined) {
            const totalRow = sheet.addRow({
              No: "",
              voucher_number: "",
              date: "Total Amount",
              room_number: "",
              amount: `${total}`,
              description: "",
            });
            totalRow.font = { bold: true };
            alignCellsCenter(totalRow);
          }

          // Set column widths
          const widths = getMaxColumnWidth(data, columns);
          setColumnWidths(widths);
        }
      };

      // Add User Transactions
      if (incomeFormattedContent.length > 0) {
        addSection("Income", incomeFormattedContent, incomeTotal);
      }
      if (expenseFormattedContent.length > 0) {
        addSection("Expense", expenseFormattedContent, expenseTotal);
      }
      if (rentFormattedContent.length > 0) {
        addSection("Rent", rentFormattedContent, rentTotal);
      }

      // Export Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const fileName = `Report ${new Date().toLocaleDateString()}.xlsx`;
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } else if (exportFormat === "pdf") {
      const doc = new jsPDF();

      const addTableWithTotal = (title, data, startY, lastY) => {
        const totalAmount = data
          .reduce((sum, row) => sum + parseFloat(row.amount || 0), 0)
          .toFixed(2);

        const pageWidth = doc.internal.pageSize.getWidth();
        const tableWidth = 170; // Adjust this value to change the overall table width
        const margin = (pageWidth - tableWidth) / 2;

        const columnWidths = [15, 30, 40, 30, 40, 80]; // Adjust these values to change individual column widths
        const headers = [
          "No.",
          "Voucher Number",
          "Date",
          "Room Number",
          "Amount",
          "Description",
        ];

        // Set title before the table
        doc.setFontSize(16);
        doc.setFont("Helvetica", "bold");
        const titleX = pageWidth / 2;
        const titleY = doc.lastAutoTable
          ? doc.lastAutoTable.finalY + lastY
          : 40;

        const availableHeight = doc.internal.pageSize.getHeight() - titleY;
        const estimatedTableHeight = 30;
        if (availableHeight < estimatedTableHeight) {
          doc.addPage();
          startY = 40;
        }

        doc.text(title, titleX, titleY, {
          align: "center",
        });

        let currentY = titleY + 10;

        // Ensure tableData is correctly formatted
        const tableData = data.map((row) => [
          row.No,
          row.voucher_number,
          row.date,
          row.room_number,
          row.amount,
          row.description,
        ]);

        // Add the table
        doc.autoTable({
          head: [headers],
          body: tableData,
          startY: currentY,
          margin: { left: margin },
          columnStyles: {
            0: { cellWidth: columnWidths[0] },
            1: { cellWidth: columnWidths[1] },
            2: { cellWidth: columnWidths[2] },
            3: { cellWidth: columnWidths[3] },
          },
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: "#0476D0",
            textColor: "white",
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
          },
          bodyStyles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
          },
        });

        // Add total row
        doc.autoTable({
          body: [["", "", "", "", "Total Amount", totalAmount]],
          startY: doc.lastAutoTable.finalY + 2,
          margin: { left: margin },
          columnStyles: {
            0: { cellWidth: columnWidths[0] },
            1: { cellWidth: columnWidths[1] },
            2: { cellWidth: columnWidths[2] },
            3: { cellWidth: columnWidths[3] },
          },
          styles: {
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            font: "bold",
          },
        });

        return doc.lastAutoTable.finalY + 20;
      };

      const addHeaderBox = () => {
        const pageWidth = doc.internal.pageSize.getWidth();
        const boxHeight = 20;

        doc.setFillColor("white");
        doc.rect(0, 0, pageWidth, boxHeight, "F");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(16);
        doc.setFont("Helvetica", "bold");

        const textStartY = 10;
        const textCenterX = pageWidth / 2;

        doc.text(selectedUser?.label || "User", textCenterX, textStartY, {
          align: "center",
        });

        let dateStr = dateRange.all
          ? "All date"
          : `${dateRange.startDate} - ${dateRange.endDate}`;
        doc.text(dateStr, textCenterX, textStartY + 10, { align: "center" });
      };

      addHeaderBox();

      let startY = 40;
      let lastY = 15;

      if (incomeFormattedContent.length > 0) {
        startY = addTableWithTotal(
          "Income",
          incomeFormattedContent,
          startY,
          lastY
        );
      }

      if (expenseFormattedContent.length > 0) {
        startY = addTableWithTotal(
          "Expense",
          expenseFormattedContent,
          startY,
          lastY
        );
      }

      if (rentFormattedContent.length > 0) {
        startY = addTableWithTotal("Rent", rentFormattedContent, startY, lastY);
      }

      doc.save(`Report ${new Date().toLocaleDateString()}.pdf`);
    }
  };

  const handleExport = () => {
    onDownloadExport();
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Report Form</h4>
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="mb-3 col-lg-6 col-md-12">
                  <label className="form-label">User</label>
                  <Select
                    options={filteredUsers}
                    value={selectedUser}
                    onChange={handleSelectUser}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Search by User Name"
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    isDisabled={isUserDisabled}
                  ></Select>
                  {/* <div className="d-flex mt-2 px-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleUserCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      All
                    </label>
                  </div> */}
                </div>

                <div className="mb-3 col-lg-6 col-md-12 ">
                  <label className="form-label">Date Range</label>
                  <DateRangePicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onApply={(event, picker) =>
                      handleDateRangeChange(picker.startDate, picker.endDate)
                    }
                    disabled={isDateRangeDisabled}
                  >
                    <input
                      type="text"
                      disabled={isDateRangeDisabled}
                      className={`form-control rounded-1 border-1  input-daterange-timepicker ${
                        isDateRangeDisabled && "bg-dark-subtle"
                      } `}
                    />
                  </DateRangePicker>
                  <div className="d-flex mt-2 px-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      All
                    </label>
                  </div>
                </div>

                <div className="mt-5 gap-5">
                  <div className="d-flex w-100 justify-content-center align-content-center gap-3">
                    <div className="d-flex justify-content-center align-content-center gap-1 ">
                      <input
                        type="radio"
                        id="excel"
                        name="exportFormat"
                        value="excel"
                        checked={exportFormat === "excel"}
                        onChange={() => setExportFormat("excel")}
                      />
                      <label htmlFor="excel">Export to Excel</label>
                    </div>
                    <div className="d-flex justify-content-center align-content-center gap-1 ">
                      <input
                        type="radio"
                        id="pdf"
                        name="exportFormat"
                        value="pdf"
                        checked={exportFormat === "pdf"}
                        onChange={() => setExportFormat("pdf")}
                      />
                      <label htmlFor="pdf">Export to PDF</label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-content-center">
                    {!showDownloadButton ? (
                      <Button
                        className="me-2 mt-3"
                        variant="success"
                        onClick={onSubmitRequestExport}
                      >
                        Export
                        <span className="btn-icon-end">
                          <i className="fa fa-download color-success"></i>
                        </span>
                      </Button>
                    ) : (
                      <div className="d-inline-flex gap-3">
                        <Button
                          className="me-2 mt-3"
                          variant={`success btn-rounded ${
                            isDataAvailable ? "" : "disabled"
                          }`}
                          onClick={handleExport}
                        >
                          <span className="btn-icon-start text-success">
                            <i className="fa fa-download color-success"></i>
                          </span>
                          {isDataAvailable ? "Download" : "Nothing to Download"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserExport;
