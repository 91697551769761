/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Card, Col, Nav, Tab } from "react-bootstrap";
//** Import Image */

import FrontViewSlider from "./FrontViewSlider";
import { getPropertyDetails } from "../../../services/PropertyService";
import CarousalSlider from "./CarousalSlider";
import { UnitDetailsComponent } from "./PropertyDetailsComponents/UnitDetailsComponent";
import { DocumentDetailsComponent } from "./PropertyDetailsComponents/DocumentDetailsComponent";
import { StatusHistoryComponent } from "./PropertyDetailsComponents/StatusHistoryComponent";
import { PropertyRentComponent } from "./PropertyDetailsComponents/PropertyRentComponent";
import PropertyTransaction from "./PropertyTransaction";
import InitialTransactions from "./InitialTransaction";

function PropertyDetails() {
  const nav = useNavigate();

  const path = window.location.pathname.split("/");
  const propertyId = path[path.length - 1];
  const [PropertyData, setPropertyData] = useState();
  const [PropertyRentData, setPropertyRentData] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [showAllUnits, setShowAllUnits] = useState(false);
  const [showAllStatus, setShowAllStatus] = useState(false);
  const [showAllDocuments, setShowAllDocuments] = useState(false);

  const handleSeeAllClick = () => {
    setShowAllUnits(true);
  };
  const handleHideAllClick = () => {
    setShowAllUnits(false);
  };

  const handleSeeAllStatusClick = () => {
    setShowAllStatus(true);
  };
  const handleHideAllStatusClick = () => {
    setShowAllStatus(false);
  };
  const handleSeeAllDocumentClick = () => {
    setShowAllDocuments(true);
  };
  const handleHideAllDocumentClick = () => {
    setShowAllDocuments(false);
  };

  useEffect(() => {
    getPropertyDetails(propertyId)
      .then((res) => {
        console.log(res.data);
        setPropertyData(res?.data?.property);
        setPropertyRentData(res?.data?.rent_collections);
        setIsLoading(false);
      })
      .catch((err) => {
        nav("/page-error-400");
        setIsLoading(false);
      });
  }, [nav, propertyId]);

  const tabData = [
    {
      name: "Rooms",
      icon: "database",
      content: (
        <UnitDetailsComponent
          PropertyData={PropertyData}
          showAllUnits={showAllUnits}
          handleHideAllClick={handleHideAllClick}
          handleSeeAllClick={handleSeeAllClick}
        />
      ),
    },

    {
      name: "Documents",
      icon: "upload",
      content: (
        <DocumentDetailsComponent
          PropertyData={PropertyData}
          showAllDocuments={showAllDocuments}
          handleHideAllDocumentClick={handleHideAllDocumentClick}
          handleSeeAllDocumentClick={handleSeeAllDocumentClick}
        />
      ),
    },
    {
      name: "Status history",
      icon: "history",
      content: (
        <StatusHistoryComponent
          PropertyData={PropertyData}
          showAllStatus={showAllStatus}
          handleHideAllStatusClick={handleHideAllStatusClick}
          handleSeeAllStatusClick={handleSeeAllStatusClick}
        />
      ),
    },
    {
      name: "Rent Collections ",
      icon: "money",
      content: <PropertyRentComponent PropertyRentData={PropertyRentData} />,
    },
  ];

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center ">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Property Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/property-list">Property list</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/property-list/property-details/${propertyId}`}>
                {PropertyData?.property_name}
              </Link>
            </li>
          </ol>
        </div>

        <div className="gap-3 d-flex justify-content-center align-content-center">
          <Link
            onClick={() => window.location.reload()}
            className="btn btn-primary rounded light"
          >
            Refresh
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="front-view-slider mb-sm-5 mb-3 owl-carousel">
                    <FrontViewSlider image={PropertyData?.images} />
                  </div>
                  <div>
                    <div className="d-md-flex d-block mb-sm-5 mb-4">
                      <div className={`card-body pt-2 pb-3 `}>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <span className="mb-0 title">Property name</span> :
                            <span className="text-black ms-2">
                              {PropertyData?.property_name}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Property number</span>{" "}
                            :
                            <span className="text-black ms-2">
                              {PropertyData?.property_number}
                            </span>
                          </li>

                          <li className="list-group-item">
                            <span className="mb-0 title">Ready to use</span> :
                            <span className="text-black ms-2">
                              <Badge
                                bg="badge-rounded"
                                className={`${
                                  PropertyData?.ready_to_use
                                    ? "badge-outline-success"
                                    : "badge-outline-danger"
                                }`}
                              >
                                {PropertyData?.ready_to_use ? "Yes" : "No"}
                              </Badge>
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Status</span> :
                            <span className="text-black ms-2">
                              <Badge
                                bg="badge-rounded"
                                className={`${
                                  PropertyData?.active
                                    ? "badge-outline-success"
                                    : "badge-outline-danger"
                                }`}
                              >
                                {PropertyData?.active ? "Active" : "Inactive"}
                              </Badge>
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Type</span> :
                            <span className="text-black desc-text ms-2">
                              {PropertyData?.type}
                            </span>
                          </li>

                          <li className="list-group-item">
                            <span className="mb-0 title">Assigned to</span> :
                            <span className="text-black desc-text ms-2">
                              {PropertyData?.users
                                ?.map((user) => user.name)
                                .join("  ,  ")}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Location</span> :
                            <span className="text-black desc-text ms-2">
                              <a
                                href={PropertyData?.location_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {PropertyData?.location} , {PropertyData?.city}
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {PropertyData?.ready_to_use ? (
          <>
            <Col xl={12}>
              <Card>
                <Card.Body>
                  <div className="custom-tab-1">
                    <Tab.Container
                      defaultActiveKey={tabData[0].name.toLowerCase()}
                    >
                      <Nav
                        as="ul"
                        className="nav-tabs justify-content-center align-content-center gap-2"
                      >
                        {tabData.map((data, i) => (
                          <Nav.Item as="li" key={i}>
                            <Nav.Link eventKey={data.name.toLowerCase()}>
                              <i className={`la la-${data.icon} me-2 `} />
                              {data.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content className="pt-4">
                        {tabData.map((data, i) => (
                          <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                            <p>{data.content}</p>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <PropertyTransaction />

            {/* <div className=" row col-12 mb-5">
              <div className=" col-12">
                <h4>Sales Staffs</h4>
                <div className="card">
                  <div className="card-body image-gallery">
                    <div className="image-gallery owl-carousel">
                      <CarousalSlider sliderData={PropertyData?.sales_staffs} />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        ) : (
          <>
            <Col xl={12}>
              <Card>
                <Card.Body>
                  <div className="custom-tab-1">
                    <Tab.Container
                      defaultActiveKey={tabData[0].name.toLowerCase()}
                    >
                      <Nav
                        as="ul"
                        className="nav-tabs justify-content-center align-content-center gap-2"
                      >
                        {tabData.slice(0, 2).map((data, i) => (
                          <Nav.Item as="li" key={i}>
                            <Nav.Link eventKey={data.name.toLowerCase()}>
                              <i className={`la la-${data.icon} me-2 `} />
                              {data.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                      <Tab.Content className="pt-4">
                        {tabData.slice(0, 2).map((data, i) => (
                          <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                            <p>{data.content}</p>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
        <div className="gap-3">
          <InitialTransactions />
        </div>
      </div>
    </>
  );
}
export default PropertyDetails;
