import { getPropertyList } from "../../services/PropertyService";
// import { Logout } from "./AuthActions";

export const UPDATE_PROPERTY_DATA = "[Property Confirmed property updated]";
export const REQUEST_START = "REQUEST_START";

export function PropertyAction(pageNo, searchQuery) {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getPropertyList(pageNo, searchQuery)
      .then((response) => {
        // console.log("RES:", response.data);

        dispatch(updatePropertList(response.data));
        // console.log(response);
        // navigate('/dashboard', { replace: true });
      })
      .catch((error) => {});
  };
}

export function updatePropertList(data) {
  return {
    type: UPDATE_PROPERTY_DATA,
    payload: data,
  };
}
