import { useState } from "react";

export default function UserEdit({
  userAccount,
  setUserAccount,
  onSubmit,
  nav,
}) {
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    switch (name) {
      case "name":
        if (!value) fieldErrors.name = "Name is required";
        else delete fieldErrors.name;
        break;
      //   case "staff_id":
      //     if (!value) fieldErrors.staff_id = "Staff ID is required";
      //     else delete fieldErrors.staff_id;
      //     break;
      case "email":
        if (!value) {
          fieldErrors.email = "Email is required";
        } else if (!emailRegex.test(value)) {
          fieldErrors.email = "Invalid email format";
        } else {
          delete fieldErrors.email;
        }
        break;
      case "password":
        if (!value) {
          fieldErrors.password = "Password is required";
        } else if (value.length < 8) {
          fieldErrors.password = "Password must be at least 8 characters";
        } else {
          delete fieldErrors.password;
        }
        if (
          userAccount?.confirm_password &&
          value !== userAccount?.confirm_password
        ) {
          fieldErrors.confirm_password = "Passwords must match";
        } else {
          delete fieldErrors.confirm_password;
        }
        break;
      case "confirm_password":
        if (userAccount?.password !== value)
          fieldErrors.confirm_password = "Passwords must match";
        else delete fieldErrors.confirm_password;
        break;
      case "role":
        if (!value || value === "default")
          fieldErrors.role = "Role is required";
        else delete fieldErrors.role;
        break;
      default:
        break;
    }

    setErrors(fieldErrors);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!userAccount?.name) {
      isValid = false;
      newErrors.name = "Name is required";
    }
    // if (!userAccount?.staff_id) {
    //   isValid = false;
    //   newErrors.staff_id = "Staff ID is required";
    // }
    // if (userAccount?.staff_id?.length < 5) {
    //   isValid = false;
    //   newErrors.staff_id = "Staff ID must be at least 5 characters long";
    // }
    if (!userAccount?.email) {
      isValid = false;
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(userAccount.email)) {
      isValid = false;
      newErrors.email = "Invalid email format";
    }
    if (!userAccount?.password) {
      isValid = false;
      newErrors.password = "Password is required";
    }
    if (userAccount?.password && userAccount?.password.length < 8) {
      isValid = false;
      newErrors.password = "Password must be at least 8 characters";
    }
    if (userAccount?.password !== userAccount?.confirm_password) {
      isValid = false;
      newErrors.confirm_password = "Passwords must match";
    }
    if (!userAccount?.role || userAccount?.role === "default") {
      isValid = false;
      newErrors.role = "Role is required";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleFormSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit user details</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Name</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      value={userAccount?.name || ""}
                      onChange={(e) => {
                        setUserAccount({
                          ...userAccount,
                          name: e.target.value,
                        });
                        validateField("name", e.target.value);
                      }}
                      className="form-control"
                      placeholder="Enter name here"
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Staff ID</label>{" "}
                    {/* <span className="text-danger">*</span> */}
                    <input
                      readOnly
                      value={userAccount?.staff_id || "Staff id not available"}
                      onChange={(e) => {
                        setUserAccount({
                          ...userAccount,
                          staff_id: e.target.value,
                        });
                        validateField("staff_id", e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Enter staff id"
                    />
                    <span className="text-danger">{errors.staff_id}</span>
                  </div>

                  <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Email</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      value={userAccount?.email || ""}
                      autoComplete="off"
                      onChange={(e) => {
                        setUserAccount({
                          ...userAccount,
                          email: e.target.value,
                        });
                        validateField("email", e.target.value);
                      }}
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                    />
                    <span className="text-danger">{errors.email}</span>
                  </div>

                  <div className="mb-3 col-sm-6">
                    <label className="form-label">Password</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      autoComplete="new-password"
                      type="password"
                      className="form-control"
                      value={userAccount?.password || ""}
                      onChange={(e) => {
                        setUserAccount({
                          ...userAccount,
                          password: e.target.value,
                        });
                        validateField("password", e.target.value);
                      }}
                      placeholder="Password must be 8 characters or more."
                    />
                    <span className="text-danger">{errors.password}</span>
                  </div>

                  <div className="mb-3 col-sm-6">
                    <label className="form-label">Confirm Password</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      type="password" // Changed to password type for consistency
                      className="form-control"
                      value={userAccount?.confirm_password || ""}
                      onChange={(e) => {
                        setUserAccount({
                          ...userAccount,
                          confirm_password: e.target.value,
                        });
                        validateField("confirm_password", e.target.value);
                      }}
                      placeholder="Confirm your password"
                    />
                    <span className="text-danger">
                      {errors.confirm_password}
                    </span>
                  </div>

                  <div className="mb-3 col-12">
                    <label className="form-label">Role</label>{" "}
                    <span className="text-danger">*</span>
                    <div className="col-lg-6">
                      <select
                        value={userAccount?.role || "default"}
                        onChange={(e) => {
                          setUserAccount({
                            ...userAccount,
                            role: e.target.value,
                          });
                          validateField("role", e.target.value);
                        }}
                        className="form-control"
                      >
                        <option value="default" disabled>
                          Choose an option
                        </option>
                        <option value="sales">Sales</option>
                        <option value="supervisor">Petty user</option>
                      </select>
                    </div>
                    <span className="text-danger">{errors.role}</span>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-sm-12 pt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary me-2"
                    onClick={handleFormSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger light"
                    onClick={() => nav("/users-list")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
