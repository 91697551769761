import React, { useState, useEffect } from "react";

const AddExpenseForm = ({ handleExpenseData }) => {
  const [expenseData, setExpenseData] = useState({
    date: "",
    amount: "",
    description: "",
    is_refundable: false,
    voucher_number: "",
  });

  useEffect(() => {
    handleExpenseData(expenseData);
  }, [expenseData, handleExpenseData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExpenseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToggleChange = (e) => {
    setExpenseData((prevData) => ({
      ...prevData,
      is_refundable: e.target.checked,
    }));
  };

  return (
    <form>
      <div className="mb-3">
        <label htmlFor="date" className="form-label">
          Date
        </label>

        <input
          type="date"
          name="date"
          value={expenseData.date} // Use expenseData.date
          onChange={handleChange}
          className="form-control react-datepicker-popper"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="amount" className="form-label">
          Amount
        </label>
        <input
          type="number"
          name="amount"
          value={expenseData.amount}
          onChange={handleChange}
          className="form-control"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="voucher_number" className="form-label">
          Voucher number
        </label>
        <input
          type="text"
          name="voucher_number"
          value={expenseData.voucher_number}
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description
        </label>
        <textarea
          name="description"
          value={expenseData.description}
          onChange={handleChange}
          className="form-control"
          rows="3"
        />
      </div>

      <div className="mb-3 d-inline-flex justify-content-center align-content-center gap-2">
        <label htmlFor="refundable" className="form-label h-100">
          Refundable
        </label>

        <label className="switch">
          <input
            type="checkbox"
            name="is_refundable"
            checked={expenseData.is_refundable}
            onChange={handleToggleChange}
          />
          <div className="slider">
            <div className="circle">
              <svg
                className="cross"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 365.696 365.696"
                height="6"
                width="6"
              >
                <path
                  data-original="#000000"
                  fill="currentColor"
                  d="M243.188 182.86 356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"
                />
              </svg>
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                height="10"
                width="10"
              >
                <path
                  data-original="#000000"
                  fill="currentColor"
                  d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                />
              </svg>
            </div>
          </div>
        </label>
      </div>
    </form>
  );
};

export default AddExpenseForm;
