import React, { useState } from "react";
import { AddNewChequeDetails } from "../../../services/ChequeServices";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ChequeForm from "./ChequeForm";

const AddNewCheque = () => {
  const nav = useNavigate();

  const [bankTransferDetails, setBankTransferDetails] = useState({
    cheque_no: "",
    amount: "",
    date: "",
    type: "",
  });

  const onSubmit = () => {
    // console.log("Form submitted:", bankTransferDetails);
    const postData = {
      cheque_no: bankTransferDetails?.cheque_no,
      amount: bankTransferDetails?.amount,
      date: bankTransferDetails?.date,
      type: bankTransferDetails?.type,
    };

    // console.log(postData);

    AddNewChequeDetails(postData)
      .then((res) => {
        toast.success("Bank transfer added successfully");
        nav("/bank-transfer");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  return (
    <ChequeForm
      bankTransferDetails={bankTransferDetails}
      setBankTransferDetails={setBankTransferDetails}
      onSubmit={onSubmit}
    />
  );
};

export default AddNewCheque;
