import axiosInstance from "./AxiosInstance";

export function refundDeposit(postData) {
  return axiosInstance.post(`/api/transactions/refund-deposit`, postData);
}

export function getSecurityDepositList(searchQuery) {
  console.log(
    "api here",
    `/api/transactions/security-deposits ${
      searchQuery ? `?search=${searchQuery}` : ""
    }`
  );
  return axiosInstance.get(
    `/api/transactions/security-deposits${
      searchQuery ? `?search=${searchQuery}` : ""
    }`
  );
}

export function getSecurityDepositDetails(id) {
  return axiosInstance.get(`/api/transactions/deposit/${id}`);
}

export function updateSecurityDepositDetails(postData) {
  return axiosInstance.post(`/api/transactions/edit-deposit`, postData);
}
