import { Button, Modal } from "react-bootstrap";

export default function UnitModal(
  modalCentered,
  setModalCentered,
  unitName,
  setUnitName,
  setUnitType,
  unitDescription,
  setUnitDescription,
  handleAddUnit,
  errors,
  setErrors,
  unitType
) {
  return (
    <Modal
      className="fade"
      show={modalCentered}
      onHide={() => setModalCentered(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title>Add Room</Modal.Title>
        <Button
          onClick={() => setModalCentered(false)}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          {/* name Label */}
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-name">
              Name <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <input
                type="text"
                className="form-control"
                placeholder="Name of the room"
                value={unitName}
                onChange={(e) => setUnitName(e.target.value)}
              />
              {errors.unitName && (
                <div className="text-danger">{errors?.unitName}</div>
              )}
            </div>
          </div>
          {/* type label */}
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-type">
              Type <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              {/* <select
                defaultValue={`${unitType ? unitType : "option"}`}
                onChange={(e) => setUnitType(e.target.value)}
                className="form-control"
              >
                <option value="option" disabled>
                  Choose...
                </option>
                <option value="bedroom">Bedroom</option>
                <option value="hall-property">hall property</option>
              </select> */}

              <input
                type="text"
                className="form-control"
                placeholder="Type"
                value={unitType}
                onChange={(e) => setUnitType(e.target.value)}
              />

              {errors.unitType && (
                <div className="text-danger">{errors?.unitType}</div>
              )}
            </div>
          </div>

          {/* Description */}
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-name">
              Description
            </label>
            <div className="col-lg-6">
              <textarea
                type="text"
                className="form-control"
                placeholder="Add a description of the room"
                value={unitDescription}
                onChange={(e) => setUnitDescription(e.target.value)}
              />
              {errors.unitDescription && (
                <div className="text-danger">{errors?.unitDescription}</div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setUnitName(null);
            setUnitType(null);
            setUnitDescription(null);
            setErrors({});

            setModalCentered(false);
          }}
          variant="danger light"
        >
          Close
        </Button>
        <Button onClick={() => handleAddUnit()} variant="success">
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
