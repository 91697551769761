import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { getUnitsDetails } from "../../../../services/PropertyService";
import FrontViewSlider from "../FrontViewSlider";
import CarousalSlider from "../CarousalSlider";
//** Import Image */

// import { UnitDetailsComponent } from "./PropertyDetailsComponents/UnitDetailsComponent";
// import { DocumentDetailsComponent } from "./PropertyDetailsComponents/DocumentDetailsComponent";
// import { StatusHistoryComponent } from "./PropertyDetailsComponents/StatusHistoryComponent";
// import { PropertyRentComponent } from "./PropertyDetailsComponents/PropertyRentComponent";

function UnitDetails() {
  const nav = useNavigate();

  const path = window.location.pathname.split("/");
  const unitId = path[path.length - 1];
  const propertyId = path[path.length - 3];
  const [UnitData, setUnitData] = useState();
  //   const [PropertyRentData, setPropertyRentData] = useState();

  const [isLoading, setIsLoading] = useState(true);
  //   const [showAllUnits, setShowAllUnits] = useState(false);
  //   const [showAllStatus, setShowAllStatus] = useState(false);
  //   const [showAllDocuments, setShowAllDocuments] = useState(false);

  //   const handleSeeAllClick = () => {
  //     setShowAllUnits(true);
  //   };
  //   const handleHideAllClick = () => {
  //     setShowAllUnits(false);
  //   };

  //   const handleSeeAllStatusClick = () => {
  //     setShowAllStatus(true);
  //   };
  //   const handleHideAllStatusClick = () => {
  //     setShowAllStatus(false);
  //   };
  //   const handleSeeAllDocumentClick = () => {
  //     setShowAllDocuments(true);
  //   };
  //   const handleHideAllDocumentClick = () => {
  //     setShowAllDocuments(false);
  //   };

  useEffect(() => {
    // Get property details from API
    getUnitsDetails(unitId)
      .then((res) => {
        setUnitData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        nav("/page-error-400");
        setIsLoading(false);
      });
  }, [nav, unitId]);

  console.log(UnitData);

  //   const tabData = [
  //     {
  //       name: "Units",
  //       icon: "database",
  //       content: (
  //         <UnitDetailsComponent
  //           PropertyData={PropertyData}
  //           showAllUnits={showAllUnits}
  //           handleHideAllClick={handleHideAllClick}
  //           handleSeeAllClick={handleSeeAllClick}
  //         />
  //       ),
  //     },

  //     {
  //       name: "Documents",
  //       icon: "upload",
  //       content: (
  //         <DocumentDetailsComponent
  //           PropertyData={PropertyData}
  //           showAllDocuments={showAllDocuments}
  //           handleHideAllDocumentClick={handleHideAllDocumentClick}
  //           handleSeeAllDocumentClick={handleSeeAllDocumentClick}
  //         />
  //       ),
  //     },
  //     {
  //       name: "Status history",
  //       icon: "history",
  //       content: (
  //         <StatusHistoryComponent
  //           PropertyData={PropertyData}
  //           showAllStatus={showAllStatus}
  //           handleHideAllStatusClick={handleHideAllStatusClick}
  //           handleSeeAllStatusClick={handleSeeAllStatusClick}
  //         />
  //       ),
  //     },
  //     {
  //       name: "Rent Collections ",
  //       icon: "money",
  //       content: <PropertyRentComponent PropertyRentData={PropertyRentData} />,
  //     },
  //   ];

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center ">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Room Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to={`/property-list/property-details/${propertyId}`}>
                Property Details
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/property-list/property-details/${propertyId}/unit-details/${unitId}`}
              >
                {UnitData?.name}
              </Link>
            </li>
          </ol>
        </div>
        {/* <Link to="/property-details" className="btn btn-danger rounded me-3">
          Update Info
        </Link> */}
        <div className="gap-3 d-flex justify-content-center align-content-center">
          <Link
            onClick={() => window.location.reload()}
            className="btn btn-primary rounded light"
          >
            Refresh
          </Link>

          {/* <ExportButton propertyId={unitId} /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="front-view-slider mb-sm-5 mb-3 owl-carousel">
                    <FrontViewSlider image={UnitData?.images} />
                  </div>
                  <div>
                    <div className="d-md-flex d-block mb-sm-5 mb-4">
                      <div className={`card-body pt-2 pb-3 `}>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <span className="mb-0 title">Room name</span> :
                            <span className="text-black ms-2">
                              {UnitData?.name}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Property number</span>{" "}
                            :
                            <span className="text-black ms-2">
                              {UnitData?.property_number}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Status</span> :
                            <span className="text-black ms-2">
                              <Badge
                                bg="badge-rounded"
                                className={`${
                                  UnitData?.user?.active
                                    ? "badge-outline-success"
                                    : "badge-outline-danger"
                                }`}
                              >
                                {UnitData?.active ? "Active" : "Inactive"}
                              </Badge>
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Type</span> :
                            <span className="text-black desc-text ms-2">
                              {UnitData?.type}
                            </span>
                          </li>

                          <li className="list-group-item">
                            <span className="mb-0 title">Assigned to</span> :
                            <span className="text-black desc-text ms-2">
                              {UnitData?.user?.name}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Location</span> :
                            <span className="text-black desc-text ms-2">
                              <a
                                href={UnitData?.location_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {UnitData?.location} , {UnitData?.city}
                              </a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Col xl={12}>
          <Card>
            <Card.Body>
              <div className="custom-tab-1">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav
                    as="ul"
                    className="nav-tabs justify-content-center align-content-center gap-2"
                  >
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} me-2 `} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="pt-4">
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        <p>{data.content}</p>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col> */}

        <div className=" row col-12 mb-5">
          <div className=" col-12">
            <h4>Sales Staffs</h4>
            <div className="card">
              <div className="card-body image-gallery">
                <div className="image-gallery owl-carousel">
                  <CarousalSlider sliderData={UnitData?.sales_staffs} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UnitDetails;
