import { getDealsList } from "../../services/DealsService";

export const GET_DEALS_DATA = "[Deals data action]";
export const REQUEST_START = "REQUEST_START";

export function DealsAction() {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getDealsList()
      .then((response) => {
        // console.log("RES:", response.data);

        dispatch(DealsList(response.data));
      })
      .catch((error) => {});
  };
}

export function DealsList(data) {
  return {
    type: GET_DEALS_DATA,
    payload: data,
  };
}
