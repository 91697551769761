import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

const AddNewLeadForm = ({ Data }) => {
  const [LeadsData, setLeadsData] = useState({});
  const [isOtherStatus, setIsOtherStatus] = useState(false);

  useEffect(() => {
    Data(LeadsData);
  }, [LeadsData, Data]);

  const handleTypeChange = (type) => {
    setLeadsData({
      ...LeadsData,
      type: type,
    });
  };
  const handleStatusChange = (status) => {
    const isOtherSelected = status === "others";
    setIsOtherStatus(isOtherSelected);
    setLeadsData({
      ...LeadsData,
      status: isOtherSelected ? "" : status,
    });
  };

  return (
    <>
      {/* <div className="col-12">
        <div className="card">
          <div className="card-body"> */}
      <form>
        <div className="row">
          <div className="mb-3 col-lg-4 col-md-6">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={LeadsData?.name}
              onChange={(e) =>
                setLeadsData({
                  ...LeadsData,
                  name: e.target.value,
                })
              }
              className="form-control"
              placeholder="Name"
            />
          </div>

          <div className="mb-3 col-lg-4 col-md-6">
            <label className="form-label">Email</label>
            <input
              type="email"
              value={LeadsData?.email}
              onChange={(e) =>
                setLeadsData({
                  ...LeadsData,
                  email: e.target.value,
                })
              }
              className="form-control"
              placeholder="Email"
              required=""
            />
          </div>

          <div className="mb-3 col-lg-4 col-md-6">
            <label className="form-label">Phone</label>
            <input
              value={LeadsData?.phone}
              onChange={(e) =>
                setLeadsData({
                  ...LeadsData,
                  phone: e.target.value,
                })
              }
              type="number"
              className="form-control"
              placeholder="Enter phone number"
              required=""
            />
          </div>

          <div className="mb-3 col-12">
            <label className="form-label">Type</label>
            <div className="d-flex flex-wrap gap-4">
              {["normal", "cold", "warm", "hot"].map((type) => (
                <Badge
                  key={type}
                  onClick={() => handleTypeChange(type)}
                  bg={"badge-rounded"}
                  className={`badge-${
                    LeadsData.type === type ? "success light" : "warning light"
                  } me-2 cursor-pointer py-2 px-4 gap-2`}
                >
                  {LeadsData.type === type && <span>&#10003; </span>}
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </Badge>
              ))}
            </div>
          </div>
          <div className="mb-3 col-12">
            <label className="form-label">Status</label>
            <div className="d-flex flex-wrap gap-4">
              {[
                "created",
                "contacted",
                "qualified",
                "needs analysis",
                "won",
                "lost",
                "proposal sent",
                "in negotiation",
                "on hold",
                "unqualified",
                "others",
              ].map((status) => (
                <Badge
                  key={status}
                  onClick={() => handleStatusChange(status)}
                  bg={"badge-rounded"}
                  className={`badge-${
                    LeadsData.status === status ||
                    (isOtherStatus && status === "others")
                      ? "success"
                      : "warning"
                  } light me-2 cursor-pointer py-2 px-4`}
                >
                  {LeadsData.status === status ||
                    (isOtherStatus && status === "others" && (
                      <span>&#10003; </span>
                    ))}
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </Badge>
              ))}
              {isOtherStatus && (
                <input
                  value={LeadsData?.status}
                  onChange={(e) =>
                    setLeadsData({
                      ...LeadsData,
                      status: e.target.value,
                    })
                  }
                  type="text"
                  className="form-control mt-2"
                  placeholder="Other status if not listed above"
                />
              )}
            </div>
          </div>

          <div className="mb-3 col-12">
            <label className="form-label">Comment</label>
            <textarea
              value={LeadsData?.comment}
              onChange={(e) =>
                setLeadsData({
                  ...LeadsData,
                  comment: e.target.value,
                })
              }
              type="text"
              className="form-control"
              placeholder="Write a Comment"
            />
          </div>
        </div>
      </form>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default AddNewLeadForm;
