import { REQUEST_START, RENT_REQUEST_LIST } from "../actions/RentRequestAction";

const initialState = {
  loading: false,
  rentRequestList: {},
};

const rentRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    case RENT_REQUEST_LIST:
      return {
        ...state,
        rentRequestList: action.payload,

        loading: false,
      };
    default:
      return state;
  }
};

export default rentRequestReducer;
