import { Button, Modal } from "react-bootstrap";

export default function DocumentModal(
  documentModalCentered,
  setDocumentModalCentered,
  documentName,
  setDocumentName,
  documentDescription,
  setDocumentDescription,
  documentFile,
  getDocRootProps,
  getDocInputProps,
  isDocDragActive,
  documentPreview,
  setDocumentFile,
  handleAddDocument,
  documentErrors,
  setDocumentErrors
) {
  return (
    <Modal
      className="fade"
      show={documentModalCentered}
      onHide={() => setDocumentModalCentered(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title>Add Document</Modal.Title>
        <Button
          onClick={() => setDocumentModalCentered(false)}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-name">
              Label <span className="text-danger">*</span>
            </label>
            <div className="col-lg-6">
              <input
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Label for the document"
              />{" "}
              {documentErrors.documentName && (
                <div className="text-danger">{documentErrors.documentName}</div>
              )}
            </div>
          </div>
          {/* Description */}
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-name">
              Description
            </label>
            <div className="col-lg-6">
              <textarea
                value={documentDescription}
                onChange={(e) => setDocumentDescription(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Add a description for the file"
              />{" "}
              {documentErrors.documentDescription && (
                <div className="text-danger">
                  {documentErrors.documentDescription}
                </div>
              )}
            </div>
          </div>
          <div className="form-group mb-3 row">
            <label className="col-lg-4 col-form-label" htmlFor="val-name">
              File <span className="text-danger">*</span>
            </label>
            <div {...getDocRootProps()} className="drop-zone ">
              <input {...getDocInputProps()} />
              {isDocDragActive ? (
                <p>Drop the document here ...</p>
              ) : (
                <p>
                  Drag 'n' drop a document here, or click to select a document
                </p>
              )}
              {/* Document Preview */}
              {documentPreview && (
                <div>
                  <p>Preview:</p>

                  <p
                    style={{
                      color: "blue",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      lineClamp: 1,
                    }}
                  >
                    {documentFile?.name}
                  </p>
                </div>
              )}
            </div>
            {documentErrors.documentFile && (
              <div className="text-danger">{documentErrors.documentFile}</div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setDocumentName(null);
            setDocumentDescription(null);
            setDocumentFile(null);
            setDocumentErrors({});
            setDocumentModalCentered(false);
          }}
          variant="danger light"
        >
          Close
        </Button>
        <Button onClick={() => handleAddDocument()} variant="success">
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
