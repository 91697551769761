import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Card, Tab, Nav } from "react-bootstrap";
import profile from "../../../images/user.png";
import noImage from "../../../images/no-image.jpg";
import {
  getSupervisorDetails,
  getSupervisorTransactions,
} from "../../../services/SupervisorService";

const tabData = [
  {
    name: "Income",
  },
  {
    name: "Expense",
  },
];

const SupervisorTransaction = () => {
  const path = window.location.pathname.split("/");
  const propertyId = path[path.length - 1];

  const [transactionsList, setTransactionsList] = useState({
    data: [],
    total_documents: 0,
  });
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIsTabLoading] = useState(true);
  const [isExpense, setIsExpense] = useState(false);
  const [user, setUser] = useState({});

  const totalPages = Math.ceil(transactionsList?.total_documents / 12);

  useEffect(() => {
    const fetchSupervisorDetails = async () => {
      try {
        const res = await getSupervisorDetails(propertyId);
        setUser(res.data);
      } catch (error) {
        console.error("Error fetching supervisor details:", error);
      }
    };

    const fetchTransactions = async () => {
      try {
        const response = await getSupervisorTransactions(
          propertyId,
          isExpense,
          pageNo
        );
        setTransactionsList(response.data);
      } catch (error) {
        console.error("Error fetching supervisor transactions:", error);
      } finally {
        setIsTabLoading(false);
        setIsLoading(false);
      }
    };

    fetchSupervisorDetails();
    fetchTransactions();
  }, [isExpense, pageNo, propertyId]);

  const handlePageChange = (isPrev) => {
    setIsTabLoading(true);
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1); // Ensure page number is not less than 1
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onTabChange = (eventKey) => {
    setIsTabLoading(true);
    setIsExpense(eventKey !== "Income");
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  const bioList = [
    { title: "Email", value: user?.user?.email },
    { title: "Status", value: user?.user?.active ? "Active" : "Inactive" },
    {
      title: "Number of Transactions",
      value: transactionsList?.total_documents,
    },
  ];

  return (
    <>
      <div className="page-titles">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/supervisor-list"}>Supervisor List</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to="#">Supervisor Transaction</Link>
          </li>
        </ol>
      </div>

      <div className="row gx-lg-3"></div>

      <div className="row justify-content-center">
        <div className="col-12">
          <div className="card overflow-hidden">
            <div className="card-body">
              <div className="text-center">
                <div className="profile-photo">
                  <img
                    src={profile}
                    width="100"
                    className="img-fluid rounded-circle"
                    alt=""
                  />
                </div>
                <h3 className="mt-4 mb-1">{user?.user?.name}</h3>
                <p className="text-muted">{user?.user?.role}</p>
              </div>
            </div>

            <div className="card-footer pt-0 pb-0 text-center">
              <div className="row">
                {bioList.map((item, index) => (
                  <div key={index} className="col-4 pt-3 pb-3 border-end">
                    <h3
                      className={`mb-1 ${
                        item.value === "Active" || item.value === "Inactive"
                          ? item.value === "Active"
                            ? "text-success"
                            : "text-danger"
                          : "text-black"
                      }`}
                    >
                      {item?.value}
                    </h3>
                    <span>{item?.title}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <Col xl={12}>
          <Card>
            <Card.Header>
              <Card.Title>Supervisor Transaction listing</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="custom-tab-1 h-100 ">
                <Tab.Container defaultActiveKey={tabData[0].name}>
                  <Nav as="ul" className="nav-tabs justify-content-center">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link
                          eventKey={data.name}
                          onClick={() => onTabChange(data.name)}
                        >
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <div className="row pt-4">
                    {isTabLoading ? (
                      <div className="h-auto w-100 justify-content-center align-items-center">
                        <div className="sk-three-bounce">
                          <div className="sk-child sk-bounce1"></div>
                          <div className="sk-child sk-bounce2"></div>
                          <div className="sk-child sk-bounce3"></div>
                        </div>
                      </div>
                    ) : (
                      transactionsList?.data.map((transaction, index) => (
                        <div
                          className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6"
                          key={index}
                        >
                          <div className="card project-boxed box-shadow-lg">
                            <div className="img-bx h-100">
                              <img
                                src={
                                  transaction.images &&
                                  transaction.images.length > 0
                                    ? transaction.images[0]
                                    : noImage
                                }
                                alt=""
                                className="me-3 object-fit-fill w-100 h-100" // Ensure the image fills the entire space of the div
                              />
                            </div>

                            <div className="card-header align-items-start">
                              <div>
                                <p className="fs-14 mb-2 text-primary text-uppercase">
                                  {transaction?.type}
                                </p>
                                <h6 className="fs-18 font-w500 mb-3">
                                  <Link
                                    to={"#"}
                                    className={`${
                                      transaction?.property?.property_name
                                        ?.length > 0
                                        ? "text-black"
                                        : "text-muted"
                                    } user-name`}
                                  >
                                    {transaction?.property?.property_name
                                      ?.length > 0
                                      ? transaction?.property?.property_name
                                      : "Property details not available"}
                                  </Link>
                                </h6>
                                <div className="text-dark fs-14 text-nowrap">
                                  <i
                                    className="fas fa-calendar me-3"
                                    aria-hidden="true"
                                  ></i>
                                  Created on{" "}
                                  {transaction?.created_at
                                    ? new Date(
                                        transaction.created_at
                                      ).toUTCString()
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="card-body p-0 pb-3">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                  <span className="mb-0 title">Currency</span> :
                                  <span className="text-black ms-2">
                                    {transaction?.country?.currency_symbol}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="mb-0 title">Amount</span> :
                                  <span className="text-black ms-2">
                                    {transaction?.amount}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="mb-0 title">
                                    Description
                                  </span>{" "}
                                  :
                                  <span className="text-black desc-text ms-2">
                                    {transaction?.description}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>

                  {/* </Tab.Pane> */}
                  {/* ))}
                  </Tab.Content> */}
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <div className="row">
          <div className="col-xl-12 ">
            <div className="table-responsive table-hover fs-14 order-list-table">
              <div id="orderList" className="dataTables_wrapper no-footer">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {transactionsList?.data?.length} entries
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers">
                    <Link
                      className={"paginate_button previous "}
                      to="#"
                      onClick={() => {
                        if (pageNo > 1) {
                          handlePageChange(true);
                        }
                      }}
                    >
                      <i className="fa fa-angle-double-left" />
                    </Link>
                    <span>
                      {Array.from({ length: totalPages }, (_, i) => (
                        <Link
                          key={i}
                          to="#"
                          className={`paginate_button ${
                            pageNo === i + 1 ? "current" : ""
                          }`}
                          // onClick={() => {
                          //   setIsTabLoading(true);
                          //   setPageNo(i + 1);
                          //   window.scrollTo({ top: 0, behavior: "smooth" });
                          // }}
                        >
                          {i + 1}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="#"
                      onClick={() => {
                        if (totalPages > pageNo) {
                          handlePageChange(false);
                        }
                      }}
                    >
                      <i className="fa fa-angle-double-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SupervisorTransaction;
