import axiosInstance from "./AxiosInstance";

export function getDashboardData() {

    return axiosInstance.get(
        `/api/dashboard`,

    );
}

