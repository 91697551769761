import toast from "react-hot-toast";
import { deleteRent, getRentCollection } from "../../services/RentServices";

export const RENT_COLLECTION_LIST = "RENT_COLLECTION_LIST";
export const RENT_START = "RENT_START";

export function RentAction(searchQuery, pageNo) {
  return (dispatch) => {
    dispatch({ type: RENT_START });

    getRentCollection(searchQuery, pageNo)
      .then((response) => {
        console.log(response.data);

        const data = response.data;

        dispatch(RentData(data));
      })
      .catch((error) => {
        // dispatch(Logout(navigate));
        // console.log(error);
      });
  };
}

export function RentDeleteAction(id, setModalCentered) {
  console.log(id);
  return (dispatch) => {
    deleteRent(id)
      .then((res) => {
        toast.success("Rent deleted successfully");
        dispatch(RentAction());
      })
      .catch((error) => {
        toast.error(`${error?.response?.data?.error}`);
        console.log(error);
      })
      .finally(() => {
        setModalCentered(false);
      });
  };
}

// FundActions.js

export function RentData(data) {
  return {
    type: RENT_COLLECTION_LIST,
    payload: data,
  };
}
