import React, { Fragment, useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SelectPropertyTable from "../../SelectProperty/SelectPropertyTable";
import { PropertyAction } from "../../../../store/actions/PropertryActions";
import PageTitle from "../../../layouts/PageTitle";
import AddExpenseForm from "./AddExpenseForm";
import { addExpense } from "../../../../services/PropertyService";

const AddExpenseWizard = () => {
  const [goSteps, setGoSteps] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const { properties, loading } = useSelector((state) => state.properties);
  const [ExpenseData, setExpenseData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    if (properties?.length <= 0) {
      dispatch(PropertyAction("all"));
    }
  }, [dispatch, properties]);

  const handleSelectedPropertyChange = (propertyData) => {
    setSelectedProperty(propertyData);
  };

  const handleExpenseData = (data) => {
    setExpenseData(data);
  };

  // console.log("expense", ExpenseData);

  const onSubmit = () => {
    // console.log(ExpenseData);

    if (
      !ExpenseData?.date ||
      !ExpenseData?.amount ||
      !ExpenseData?.description
    ) {
      toast.error(
        "All fields are required. Please ensure no field is left empty."
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const FormData = {
      property: selectedProperty,
      date: ExpenseData?.date,
      amount: ExpenseData?.amount,
      description: ExpenseData?.description,
      is_refundable: ExpenseData?.is_refundable,
      voucher_number: ExpenseData?.voucher_number,
    };

    console.log(FormData);

    addExpense(FormData)
      .then((res) => {
        toast.success("Expense added");
        nav("/property-list");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Something went wrong"
        );
        setIsLoading(false);
      });
  };

  if (loading || isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <PageTitle
        activeMenu={`Add Expense`}
        motherMenu={"Property List"}
        motherPath={"property-list"}
      />

      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {goSteps === 0 ? `Select Property` : "Add Expense"}
              </h4>
            </div>
            <div className="card-body">
              <div className="form-wizard ">
                <Stepper className="nav-wizard" activeStep={goSteps}>
                  <Step className="nav-link" onClick={() => setGoSteps(0)} />
                  <Step className="nav-link" onClick={() => setGoSteps(1)} />
                </Stepper>
                {goSteps === 0 && (
                  <>
                    <SelectPropertyTable
                      onPropertySelect={handleSelectedPropertyChange}
                      properties={properties}
                      selectedProperty={selectedProperty}
                      setGoSteps={setGoSteps}
                    />
                  </>
                )}
                {goSteps === 1 && (
                  <>
                    <AddExpenseForm handleExpenseData={handleExpenseData} />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-warning sw-btn-prev me-1"
                        onClick={() => setGoSteps(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-success ms-1"
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
                {/* {goSteps === 2 && (
                  <>
                    <StepThree />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(1)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(3)}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )} */}
                {/* {goSteps === 3 && (
                  <>
                    <StepFour />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(4)}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddExpenseWizard;
