import { Badge, Button, Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";

import noImage from "../../../images/no-image.jpg";

export default function SecurityDepositDetailsModal(props) {
  console.log(props.viewDetails);
  return (
    <Modal
      className="fade bd-example-modal-lg"
      size="lg"
      show={props.modalCentered}
      onHide={props.setModalCentered}
      centered
    >
      <Modal.Header>
        <Modal.Title>Details</Modal.Title>
        <Button
          onClick={() => {
            props.setModalCentered(false);
          }}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <div className="row">
          <div
            className="col-12" // key={ind}
          >
            <div className="property-card style-1">
              <div className={`dz-media `}>
                <Zoom>
                  <img alt="" src={props.viewDetails?.images?.[0] || noImage} />
                </Zoom>
              </div>

              <div className={`card-body pt-2 pb-3`}>
                <ul className="list-group list-group-flush">
                  {props.viewDetails?.type && (
                    <li className="list-group-item">
                      <span className="mb-0 title">Type</span> :
                      <span className="text-black ms-2">
                        {props.viewDetails?.type
                          ? props.viewDetails?.type
                          : "Type not Available"}
                      </span>
                    </li>
                  )}

                  <li className="list-group-item">
                    <span className="mb-0 title">User</span> :
                    <span className="text-black ms-2">
                      {props.viewDetails?.user
                        ? props.viewDetails?.user?.name
                        : "Name not Available"}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <span className="mb-0 title">Description</span> :
                    <span className="text-black ms-2">
                      {props.viewDetails?.description}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="flex-column d-flex justify-content-center">
       <Button
         variant="success"
         onClick={() => {
           setModalCentered(false);
         }}
       >
         Close
       </Button>
      </Modal.Footer> */}
    </Modal>
  );
}
