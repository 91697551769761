import { useEffect, useState } from "react";
import {
  Badge,
  Modal,
  Button,
  Col,
  Card,
  Table,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LeadsAction } from "../../../store/actions/LeadsAction";
import { Link, useNavigate } from "react-router-dom";
import SelectPropertyTable from "../SelectProperty/SelectPropertyTable";
import LeadAssignUserList from "./LeadAssignUserList";

const LeadsTable = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  // const [modalCentered, setModalCentered] = useState(false);
  const [addLeadModal, setAddLeadModal] = useState(false);
  const [largeModal, setLargeModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState();
  const [status, setStatus] = useState("all");
  const [pageNo, setPageNo] = useState(1);

  // const [statusHistory, setStatusHistory] = useState();
  const { Leads, loading } = useSelector((state) => state.leads);

  const totalPages = Math.ceil(Leads?.total_documents / 10);

  const handlePageChange = (isPrev) => {
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1);
    // Ensure page number is not less than 1
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    dispatch(LeadsAction(status, pageNo));
  }, [dispatch, pageNo, status]);
  console.log(Leads);

  if (loading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  const renderBadge = (status) => {
    switch (status && status?.toLowerCase()) {
      case "cold":
        return <Badge bg="primary light">Cold</Badge>;
      case "warm":
        return <Badge bg="warning light">Warm</Badge>;
      case "hot":
        return <Badge bg="danger light">Hot</Badge>;
      case "normal":
      default:
        return <Badge bg="white light">Normal</Badge>;
    }
  };

  return (
    <div className="h-auto">
      <Col>
        <LeadAssignUserList
          largeModal={largeModal}
          setLargeModal={setLargeModal}
          selectedLead={selectedLead}
          setSelectedLead={setSelectedLead}
          LeadsAction={() => dispatch(LeadsAction(status, pageNo))}
        />
        <Card>
          {/* Modal Add Lead  */}
          <Modal
            className="fade"
            size="lg"
            show={addLeadModal}
            onHide={() => setAddLeadModal(false)}
            centered
          >
            <Modal.Body>
              <div className="col-12">
                <SelectPropertyTable />
              </div>
            </Modal.Body>
          </Modal>

          <Card.Header>
            <Card.Title>Leads</Card.Title>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                className="me-2"
                variant="success btn-rounded"
                onClick={() => {
                  nav("add");
                }}
              >
                <span className="btn-icon-start text-success">
                  <i className="fa fa-plus color-success" />
                </span>
                Add new lead
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            {/* <div className="d-flex justify-content-end w-100">
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="sm"
                  className="mt-1 mb-2 text-uppercase"
                >
                  Sort by : {status}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[
                    "all",
                    "created",
                    "contacted",
                    "qualified",
                    "needs analysis",
                    "won",
                    "lost",
                    "proposal sent",
                    "in negotiation",
                    "on hold",
                    "unqualified",
                    "others",
                  ]?.map((status, index) => (
                    <Dropdown.Item
                      className="text-uppercase"
                      key={index}
                      onClick={() => setStatus(status)}
                    >
                      Sort by : {status && status}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Customer name</th>
                  <th>Property</th>
                  <th>Comments</th>
                  <th>Created by</th>
                  <th>Assigned to</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Leads?.data?.length > 0 ? (
                  Leads?.data?.map((lead, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{lead?.customer?.name}</td>
                      <td>
                        <td>
                          {lead?.property ? lead?.property?.property_name : "-"}
                        </td>
                      </td>
                      <td>{lead?.comment}</td>
                      <td>{lead?.created_by?.name}</td>
                      <td>{lead?.assigned_to?.name}</td>
                      <td>{renderBadge(lead?.type)}</td>
                      <td>{lead?.status}</td>

                      <td>
                        <Dropdown className="ms-auto">
                          <Dropdown.Toggle
                            variant=""
                            className="btn-link i-false"
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-top">
                            <Dropdown.Item
                              className="text-black"
                              onClick={() => {
                                setLargeModal(true);
                                setSelectedLead(lead?._id);
                              }}
                            >
                              Assign lead
                            </Dropdown.Item>
                            {lead?.status_history?.length > 0 && (
                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  nav(`${lead?._id}`);
                                }}
                              >
                                Lead details
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>

                        {/* {lead?.status_history?.length > 0 ? (
                        <Button
                          className="me-2 "
                          size="sm"
                          variant="outline-success"
                          onClick={() => {
                            setModalCentered(true);
                            setStatusHistory(lead?.status_history);
                          }}
                        >
                          Status details
                        </Button>
                      ) : (
                        <Button
                          className="me-2 "
                          size="sm"
                          variant="outline-danger disabled"
                        >
                          No status details
                        </Button>
                      )} */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="9">
                      No Leads Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <div className="row">
          <div className="col-xl-12 ">
            <div className="table-responsive table-hover fs-14 order-list-table">
              <div id="orderList" className="dataTables_wrapper no-footer">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {Leads?.data?.length} of {Leads?.total_documents}{" "}
                    entries
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers">
                    <div
                      className="paginate_button previous"
                      onClick={() => handlePageChange(true)}
                    >
                      <i className="fa fa-angle-double-left" />
                    </div>
                    <span>
                      {Array.from({ length: totalPages }, (_, i) => (
                        <div
                          key={i}
                          className={`paginate_button ${
                            pageNo === i + 1 ? "current" : ""
                          }`}
                          onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            setPageNo(i + 1);
                          }}
                        >
                          {i + 1}
                        </div>
                      ))}
                    </span>
                    <div
                      className="paginate_button next"
                      onClick={() => {
                        if (totalPages > pageNo) {
                          handlePageChange(false);
                        }
                      }}
                    >
                      <i className="fa fa-angle-double-right" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default LeadsTable;
