import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, HashNavigation } from "swiper/modules";

const CarousalSlider = ({ sliderData }) => {
  return (
    <div className="image-gallery-container d-flex justify-content-center align-items-center">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        // hashNavigation={{
        //   watchState: true,
        // }}
        loop={true} // Uncommented and set to true; adjust as needed
        speed={1000}
        navigation={{
          prevEl: ".left-arrow",
          nextEl: ".right-arrow",
        }}
        breakpoints={{
          360: { slidesPerView: 1 },
          591: { slidesPerView: 1 },
          1750: { slidesPerView: 1 },
        }}
        modules={[Navigation, HashNavigation]}
        className="mySwiper"
      >
        {sliderData?.length > 0 ? (
          sliderData.map((item, ind) => (
            <SwiperSlide
              data-hash={`slide${ind}`}
              className={`col-12 ${sliderData?.length > 1 && "px-5"}  `}
              key={ind}
            >
              <img src={item.image} alt="" />
            </SwiperSlide>
          ))
        ) : (
          <div className="col-12 text-center py-5 card-body align-items-center ">
            <p>No sales staffs found</p>
          </div>
        )}
      </Swiper>
      {sliderData?.length > 1 && (
        <div className="owl-nav detail-nav">
          <div className="owl-prev left-arrow">
            <i className="fas fa-caret-left" />
          </div>
          <div className="owl-next right-arrow">
            <i className="fas fa-caret-right" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CarousalSlider;
