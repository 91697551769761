import axiosInstance from "./AxiosInstance";

export function getRentCollection(searchQuery, pageNo) {
  const params = new URLSearchParams();

  if (pageNo) {
    params.append("page", pageNo);
  }

  if (searchQuery) {
    params.append("search", searchQuery);
  }

  const queryString = params.toString();
  const url = `/api/transactions/rent${queryString ? `?${queryString}` : ""}`;

  return axiosInstance.get(url);
}

export function getRent(id) {
  return axiosInstance.get(`/api/transactions/rent/${id}`);
}

export function deleteRent(id) {
  return axiosInstance.get(`/api/transactions/delete-rent/${id}`);
}

export function updateRent(id, postData) {
  return axiosInstance.post(`/api/transactions/editRent/${id}`, postData);
}

export function addRent(postData) {
  return axiosInstance.post(`/api/transactions/add-rent`, postData);
}

export function refundRent(postData) {
  return axiosInstance.post(`/api/transactions/refund-rent`, postData);
}
