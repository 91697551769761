import { getLeadsList } from "../../services/LeadsServices";

export const GET_LEADS_DATA = "[Leads data action]";
export const REQUEST_START = "REQUEST_START";

export function LeadsAction(status, pageNo) {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getLeadsList(status, pageNo)
      .then((response) => {
        // console.log("RES:", response.data);

        dispatch(LeadsList(response.data));
      })
      .catch((error) => {});
  };
}

export function LeadsList(data) {
  return {
    type: GET_LEADS_DATA,
    payload: data,
  };
}
