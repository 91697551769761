import React, { useState } from "react";
import "./filtering.css";

const SelectPropertyTable = ({
  onPropertySelect,
  properties,
  setGoSteps,
  selectedProperty,
}) => {
  // const { properties, loading } = useSelector((state) => state.properties);

  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSelectionChange = (propertyId) => {
    setSelectedPropertyId(
      selectedPropertyId === propertyId ? null : propertyId
    );
    onPropertySelect(selectedPropertyId === propertyId ? null : propertyId);
  };

  const isRowDisabled = (propertyId) =>
    selectedPropertyId !== null && selectedPropertyId !== propertyId;

  const filteredProperties = properties?.data?.filter((property) => {
    return (
      property.property_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      property.property_number
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      property._id.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    // <div className="col-12">
    //   <div className="card">
    <>
      <div className="card-header ">
        {/* <h4 className="card-title">Select Property</h4> */}
        <div
          className=" d-flex justify-content-between align-items-center"
          style={{
            width: "100%",
          }}
        >
          {/* Search Input */}
          <div
            className="d-flex  gap-4 align-items-center "
            style={{ width: "350px" }}
          >
            <h4 className="card-title">Search</h4>

            <input
              type="text"
              className="form-control input-rounded"
              placeholder="Search by Name, Number, or ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="text-end toolbar toolbar-bottom  p-2">
            <button
              className={`${
                selectedProperty ? "" : "disabled"
              } btn btn-success px-4`}
              onClick={() => setGoSteps(1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="w-100 table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th></th> {/* Checkbox column */}
                <th>Property Name</th>
                <th>Property Number</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {filteredProperties?.map((property) => (
                <tr
                  key={property._id}
                  onClick={() => handleSelectionChange(property._id)}
                  className={isRowDisabled(property._id) ? "disabled-row" : ""}
                >
                  <td>
                    <input
                      type="radio"
                      checked={selectedPropertyId === property._id}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectionChange(property._id);
                      }}
                    />
                  </td>
                  <td>{property.property_name}</td>
                  <td>{property.property_number}</td>
                  <td>
                    {property?.users
                      .map((user) =>
                        Array.isArray(user?.name)
                          ? user.name.join(",")
                          : user.name
                      )
                      .join(", ")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>

    //    </div>
    // </div>
  );
};

export default SelectPropertyTable;
