import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({ motherMenu, activeMenu, motherPath }) => {
  //   let path = window.location.pathname.split("/");

  return (
    <div className="page-titles">
      {/* <h4>{activeMenu}{pageHeading}</h4>	 */}
      <ol className="breadcrumb">
        {motherMenu && (
          <li className="breadcrumb-item">
            <Link to={`/${motherPath}`}>{motherMenu}</Link>
          </li>
        )}

        {activeMenu && <li className="breadcrumb-item active ">{activeMenu}</li>}
      </ol>
    </div>
  );
};

export default PageTitle;
