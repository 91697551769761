import axiosInstance from "./AxiosInstance";

export function AddNewChequeDetails(postData) {
  return axiosInstance.post(`/api/cheque/create`, postData);
}

export function getChequeList() {
  return axiosInstance.get(`/api/cheque`);
}

export function closeCheque(id) {
  return axiosInstance.get(`/api/cheque/close/${id}`);
}
