import axiosInstance from "./AxiosInstance";

export function getLeadsList(status, page = 1) {
  console.log(
    `/api/leads?${
      status !== "all" ? `status=${status}&page=${page}` : `page=${page}`
    }`
  );

  return axiosInstance.get(
    `/api/leads?${
      status !== "all" ? `status=${status}&page=${page}` : `page=${page}`
    } `
  );
}

export function createLead(data) {
  // console.log("Lead data", data);
  return axiosInstance.post("/api/leads/add", data);
}

export function assignLeads(data) {
  console.log("Assign lead data", data);
  return axiosInstance.post("api/leads/assign-lead", data);
}

export function getLeadDetails(id) {
  return axiosInstance.get(`/api/leads/${id}`);
}
