import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const SecurityDepositModal = ({
  modalCentered,
  setModalCentered,
  rejectModalCentered,
  setRejectModalCentered,
  partialModalCentered,
  setPartialModalCentered,
  postData,
  onRefund,
  onReject,
  onPartiallyRefund,
}) => {
  const [partialAmount, setPartialAmount] = useState("");
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!partialModalCentered) {
      setPartialAmount("");
      setNotes("");
      setErrors({});
    }
  }, [partialModalCentered]);

  const validateForm = () => {
    const newErrors = {};

    // Validate amount
    if (!partialAmount) {
      newErrors.amount = "Amount is required";
    } else if (isNaN(partialAmount) || parseFloat(partialAmount) <= 0) {
      newErrors.amount = "Amount must be a positive number";
    } else if (parseFloat(partialAmount) > postData?.amount) {
      newErrors.amount = "Amount cannot exceed the original security deposit";
    }

    // Validate notes
    if (!notes.trim()) {
      newErrors.notes = "Please provide a reason for partial refunding";
    } else if (notes.length > 500) {
      newErrors.notes = "Notes cannot exceed 500 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePartialRefund = () => {
    if (validateForm()) {
      onPartiallyRefund({
        amount: parseFloat(partialAmount),
        notes: notes.trim(),
      });
      setPartialModalCentered(false);
    }
  };

  const renderRefundModal = () => (
    <Modal show={modalCentered} onHide={() => setModalCentered(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Security Deposit Refund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="subtitle">
          Are you sure you want to refund this security deposit?
        </h4>
        <div className="mt-3">
          <p className="mb-2">Amount: {postData?.amount?.toLocaleString()}</p>
          {postData?.description && (
            <p className="mb-0">Description: {postData?.description}</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => setModalCentered(false)}>
          Cancel
        </Button>
        <Button variant="success" onClick={onRefund}>
          Refund
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderRejectModal = () => (
    <Modal
      show={rejectModalCentered}
      onHide={() => setRejectModalCentered(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Security Deposit Rejection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="subtitle">
          Are you sure you want to reject this security deposit? This action
          cannot be undone.
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => setRejectModalCentered(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onReject}>
          Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderPartialRefundModal = () => (
    <Modal
      show={partialModalCentered}
      onHide={() => setPartialModalCentered(false)}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Partial Security Deposit refunding</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <h5>Original Deposit Details</h5>
          <p className="mb-2">Amount: {postData?.amount?.toLocaleString()}</p>
          {postData?.description && (
            <p className="mb-0">Description: {postData?.description}</p>
          )}
        </div>

        <Form>
          <Form.Group className="mb-3" controlId="partialAmount">
            <Form.Label>Partial Amount to refund</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              value={partialAmount}
              onChange={(e) => setPartialAmount(e.target.value)}
              isInvalid={!!errors.amount}
            />
            <Form.Control.Feedback type="invalid">
              {errors.amount}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              Enter an amount less than or equal to{" "}
              {postData?.amount?.toLocaleString()}
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="notes">
            <Form.Label>Reason for Partial refunding</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Please provide a reason for partial refunding..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              isInvalid={!!errors.notes}
            />
            <Form.Control.Feedback type="invalid">
              {errors.notes}
            </Form.Control.Feedback>
            <Form.Text className="text-muted">
              {500 - notes.length} characters remaining
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => setPartialModalCentered(false)}>
          Cancel
        </Button>
        <Button variant="success" onClick={handlePartialRefund}>
          Refund Partially
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {renderRefundModal()}
      {renderRejectModal()}
      {renderPartialRefundModal()}
    </>
  );
};

export default SecurityDepositModal;
