import toast from "react-hot-toast";
import { AddProperty } from "../../../../services/PropertyService";
import { mediaUpload } from "../../../../services/MediaUploadService";

export function onSubmitClicked(
  setIsLoading,
  PropertyData,
  files,
  validImageTypes,
  documents,
  units,
  nav
) {
  return () => {
    setIsLoading(true);

    if (PropertyData.return_percentage) {
      if (
        PropertyData.return_percentage < 1 ||
        PropertyData.return_percentage > 100
      ) {
        toast.error("Return percentage must be between 1-100");
        setIsLoading(false);
        return;
      }
    }

    if (
      !PropertyData.property_name ||
      !PropertyData.property_number ||
      !PropertyData.type ||
      !PropertyData.location ||
      !PropertyData.location_link ||
      !PropertyData.city ||
      !PropertyData.total_value
      // !PropertyData.return_percentage
    ) {
      const missingFields = [];
      if (!PropertyData.property_name) missingFields.push("Property Name");
      if (!PropertyData.property_number) missingFields.push("Property Number");
      if (!PropertyData.type) missingFields.push("Type");
      if (!PropertyData.location) missingFields.push("Location");
      if (!PropertyData.location_link) missingFields.push("Location link");

      if (!PropertyData.city) missingFields.push("City");
      if (!PropertyData.total_value) missingFields.push("Total Value");
      // if (!PropertyData.return_percentage)
      //   missingFields.push("Return Percentage");

      const errorMessage = `The fields ${missingFields} is required.`;
      // +
      // " Please ensure no field is left empty except Images,Documents and Units."
      toast.error(errorMessage);
      setIsLoading(false);
      return;
    }

    if (files.some((file) => !validImageTypes.includes(file.type))) {
      toast.error(
        "One or more selected files have an invalid image file type. Please select valid images."
      );
      setIsLoading(false);
      return;
    }

    // Handle document uploads
    const documentUploads = documents.map((doc) => {
      if (doc.file instanceof File) {
        const formData = new FormData();
        formData.append("file", doc.file);
        return mediaUpload(formData)
          .then((response) => ({
            ...doc,
            document_link: response.data.files[0],
            isUploaded: true,
          }))
          .catch((error) => {
            console.error("Error uploading document file:", error);
            return { ...doc, isUploaded: false };
          });
      } else {
        return Promise.resolve(doc);
      }
    });

    // Handle image uploads
    const imageUploads = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      return mediaUpload(formData)
        .then((response) => ({
          url: response.data.files[0], // Assuming response contains the URL
        }))
        .catch((error) => {
          console.error("Error uploading image:", error);
          return null;
        });
    });

    Promise.all([...documentUploads, ...imageUploads]).then((results) => {
      const processedDocuments = results.slice(0, documents.length);
      const processedImages = results
        .slice(documents.length)
        .filter((image) => image !== null);

      if (processedDocuments.some((doc) => !doc.isUploaded)) {
        toast.error("Failed to upload one or more documents.");
        setIsLoading(false);
        return;
      }

      if (processedImages.length !== files.length) {
        toast.error("Failed to upload one or more images.");
        setIsLoading(false);
        return;
      }

      const propertyPayload = {
        property_name: PropertyData.property_name,
        city: PropertyData.city,
        type: PropertyData.type,
        location: PropertyData.location,
        location_link: PropertyData.location_link,
        property_number: PropertyData.property_number,
        total_value: PropertyData.total_value,
        return_percentage: PropertyData.return_percentage,
        units: units.map((unit) => ({
          name: unit.name,
          type: unit.type,
          description: unit.description,
        })),
        documents: processedDocuments.map((doc) => ({
          label: doc.label,
          description: doc.description,
          document_link: doc.document_link,
        })),
        images: processedImages,
      };

      console.log(propertyPayload);

      AddProperty(propertyPayload)
        .then((res) => {
          toast.success("Property added successfully!");
          nav("/property-list");
        })
        .catch((err) => {
          console.log(err);
          console.error("Error submitting the form:", err);
          const errorDetails = err?.response?.data?.details;
          if (Array.isArray(errorDetails)) {
            errorDetails.forEach((err) => {
              const sanitizedMessage = err?.message
                ?.replace(/["/,]/g, "")
                .replace(/_/g, " ");
              toast.error(sanitizedMessage);
            });
          } else {
            toast.error("Failed to update property. Please try again.");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };
}

//

export function DocumentFormValidate(
  documentName,
  documentDescription,
  documentFile,
  validDocumentTypes,
  setDocumentErrors
  // documentIndex
) {
  return () => {
    let formIsValid = true;
    let documentErrors = {};

    // Validate the document name
    if (!documentName) {
      formIsValid = false;
      documentErrors["documentName"] = "Label is required.";
    }

    // Validate the document description
    // if (!documentDescription) {
    //   formIsValid = false;
    //   documentErrors["documentDescription"] = "Description is required.";
    // }

    function isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    }
    // if (documentIndex === null) {
    if (!documentFile) {
      formIsValid = false;
      documentErrors["documentFile"] = "File is required.";
    } else if (isValidUrl(documentFile)) {
      return formIsValid;
    } else if (!validDocumentTypes.includes(documentFile?.type)) {
      formIsValid = false;
      documentErrors["documentFile"] = "File format not supported.";
    }
    // }

    setDocumentErrors(documentErrors);
    return formIsValid;
  };
}

//
export function UnitFormValidate(
  unitName,
  unitType,
  unitDescription,
  setErrors
) {
  return () => {
    let formIsValid = true;
    let errors = {};

    if (!unitName) {
      formIsValid = false;
      errors["unitName"] = "Name is required.";
    }

    if (!unitType || unitType === "option") {
      formIsValid = false;
      errors["unitType"] = "Type is required.";
    }

    // if (!unitDescription) {
    //   formIsValid = false;
    //   errors["unitDescription"] = "Description is required.";
    // }

    setErrors(errors);
    return formIsValid;
  };
}
