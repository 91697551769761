import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PageTitle from "../../layouts/PageTitle";
import { isEqual } from "lodash";
import RentRequestEditForm from "./RentRequestEditForm";
import {
  getCashHandOverDetails,
  updateCashHandOverDetails,
} from "../../../services/RentRequestServices";

const RentRequestEdit = () => {
  const nav = useNavigate();
  const path = window.location.pathname.split("/");
  const UserId = path[path.length - 1];
  const [cashHandOver, setCashHandOver] = useState({});
  const [oldCashHandOver, setOldCashHandOver] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    getCashHandOverDetails(UserId)
      .then((res) => {
        setOldCashHandOver({
          billing_date: formatDate(res?.data?.data?.date),
          amount: res?.data?.data?.amount,
          description: res?.data?.data?.description,
        });

        setCashHandOver({
          billing_date: formatDate(res?.data?.data?.date),
          amount: res?.data?.data?.amount,
          description: res?.data?.data?.description,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [UserId, nav]);

  const onSubmit = () => {
    setIsLoading(true);

    console.log(cashHandOver);

    const hasFormChanges = !isEqual(oldCashHandOver, cashHandOver);
    if (!hasFormChanges) {
      toast.error(
        "No changes made. Please edit the details before submitting."
      );
      setIsLoading(false);
      return;
    }

    const postData = {
      date: cashHandOver?.billing_date,
      amount: cashHandOver?.amount,
    };

    updateCashHandOverDetails(UserId, postData)
      .then((res) => {
        console.log(res);
        nav("/rent-requests");
        toast.success("Updated successfully");
      })
      .catch((err) => {
        toast.error(err.response.data.error);

        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2); // Add leading zero
    const day = `0${d.getDate()}`.slice(-2); // Add leading zero
    return `${year}-${month}-${day}`;
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }
  return (
    <>
      <PageTitle
        activeMenu={"Edit transaction"}
        activePath={"edit"}
        motherMenu={"Transactions List"}
        motherPath={"transaction-list"}
      />
      <RentRequestEditForm
        nav={nav}
        onSubmit={onSubmit}
        cashHandOver={cashHandOver}
        setCashHandOver={setCashHandOver}
      />
    </>
  );
};

export default RentRequestEdit;
