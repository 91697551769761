import { Button, Modal, Form } from "react-bootstrap";
import { useState } from "react";
import toast from "react-hot-toast";
import { refundRent } from "../../../services/RentServices";
import { RentAction } from "../../../store/actions/RentActions";

export default function RefundRentModal(props) {
  const [reason, setReason] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const { voucher_number, _id, amount } = props.viewDetails;

    if (!reason.trim()) {
      toast.error("Please enter a reason for the refund.");
      return;
    }
    const postData = {
      rent_transaction_id: _id,
      refund_amount: String(amount),
      reason: reason,
      voucher_number: voucher_number,
    };

    refundRent(postData)
      .then((res) => {
        console.log(res);
        toast.success("Refund processed successfully.");
        setReason("");
        props.setModalCentered(false);
        props.dispatch(RentAction());
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message || "Failed to process the refund.");
      })
      .finally(() => {
        props.setModalCentered(false);
      });
  };

  return (
    <Modal
      className="fade bd-example-modal-lg"
      size="lg"
      show={props.modalCentered}
      onHide={() => props.setModalCentered(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Rent Refund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="subtitle">Are you sure you want to refund this rent?</h4>
        <div className="mt-3">
          <p className="mb-2">
            Amount :{" "}
            <span className="fw-semibold">{props.viewDetails?.amount}</span>
          </p>
        </div>

        {/* Form for refund */}
        <Form onSubmit={handleSubmit} className="border-0">
          <Form.Label className="text-black ">Reason for Refunding</Form.Label>
          <Form.Control
            className="mb-3"
            as="textarea"
            rows={3}
            placeholder="Please provide a reason for refunding..."
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <Modal.Footer className="border-0">
            <Button
              variant="success"
              onClick={() => props.setModalCentered(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" type="submit">
              Refund
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
