import React, { useState } from "react";
import { Link } from "react-router-dom";

export function PropertyRentComponent({ PropertyRentData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 6;

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = PropertyRentData?.slice(
    indexOfFirstData,
    indexOfLastData
  );

  const totalPages = Math.ceil((PropertyRentData?.length || 0) / dataPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPaginationLinks = () => {
    let pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);

      if (currentPage <= 3) {
        for (let i = 2; i <= 5; i++) {
          pages.push(i);
        }
      } else if (currentPage > totalPages - 3) {
        pages.push("...");
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
        }
        if (currentPage + 2 < totalPages) {
          pages.push("...");
        }
        if (currentPage + 1 < totalPages) {
          pages.push(totalPages);
        }
      }
    }

    return pages.map((page, index) => (
      <Link
        key={index}
        to="#"
        className={`paginate_button ${currentPage === page ? "current" : ""}`}
        onClick={() => {
          if (typeof page === "number") {
            handlePageChange(page);
            // window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }}
      >
        {page}
      </Link>
    ));
  };

  return (
    <div className="col-12">
      <div className="card-header mb-0 border-0 pb-0 d-flex justify-content-between align-items-center">
        <h3 className="fs-20 text-black mb-0">Rent Collection</h3>
      </div>
      <div className="row card-body">
        {PropertyRentData?.length > 0 ? (
          <div className="table-responsive table-hover fs-14 order-list-table">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <table
                className="table display table-hover display mb-4 dataTablesCard card-table dataTable no-footer"
                id="example5"
              >
                <thead>
                  <tr role="row">
                    <th>Name</th>
                    <th>Billing Date</th>
                    <th>Posting Date</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Room</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.user?.name}</td>
                      <td>
                        {item?.billing_date
                          ? new Date(item.billing_date).toUTCString()
                          : ""}
                      </td>
                      <td>
                        {item?.posting_date
                          ? new Date(item.posting_date).toUTCString()
                          : ""}
                      </td>
                      <td>{item?.amount}</td>
                      <td>{item?.description}</td>
                      <td>{item.type}</td>
                      <td>{item.units?.length > 0 ? item.units?.length : 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {indexOfFirstData + 1} to {indexOfLastData} of{" "}
                  {PropertyRentData.length} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <Link
                    className="paginate_button previous"
                    to="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="fa fa-angle-double-left" />
                  </Link>
                  <span>{renderPaginationLinks()}</span>
                  <Link
                    className="paginate_button next"
                    to="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="fa fa-angle-double-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-lg-12">
            <div className="h-100">
              <div className="card-body d-flex justify-content-center align-items-center h-100">
                <p>No Data Found.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
