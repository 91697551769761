import axiosInstance from "./AxiosInstance";

export function getFundData(status,pageNo) {
  return axiosInstance.get(
    `/api/fund-requests?status${status !== "all" && `=${status}`}&page=${pageNo}`
  );
}

export function updateFundRequest(data) {
  if (data.action === "accepted") {
    const postData = {
      request_id: data.request_id,
      action: "accepted",
      amount: data.amount.toString(),
      comment: data.comment,
    };

    return axiosInstance.post(`/api/manage-fund-request`, postData);
  } else {
    const postData = {
      request_id: data.request_id,
      action: "rejected",
      reason: data.reason,
    };
    return axiosInstance.post(`/api/manage-fund-request`, postData);
  }
}


export function getFundRequestDetails(id) {
  return axiosInstance.get(`/api/fund-requests/${id}`);
}