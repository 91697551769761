import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { addMoneyToSupervisorWallet } from "../../../services/SupervisorService";
import toast from "react-hot-toast";

const AddMoneyForm = ({
  modalCentered,
  setModalCentered,
  selectedId,
  setSelectedId,
}) => {
  const [userData, setuserData] = useState();

  const toggleSendAmount = () => {
    if (!userData) {
      toast.error("Please enter a valid amount");
      return;
    }

    setModalCentered(false);
    const postData = {
      user: selectedId,
      amount: userData,
    };

    addMoneyToSupervisorWallet(postData)
      .then((res) => {
        // console.log(res.data);
        setSelectedId(null);
        setuserData(null);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Something went wrong"
        );
      });
  };

  return (
    <Modal
      className="fade"
      show={modalCentered}
      onHide={setModalCentered}
      centered
    >
      <Modal.Header>
        <Modal.Title>Add Money to Wallet</Modal.Title>
        <Button
          onClick={() => setModalCentered(false)}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="col-12">

        <div className="card">
          <div className="card-body"> */}
        <form>
          <div className="row">
            <div className="mb-3 col-12">
              <div className="input-group input-group-lg">
                <span
                  className="input-group-text label-primary label-xl"
                  id="basic-addon1"
                >
                  AED
                </span>
                <input
                  type="number"
                  value={userData}
                  onChange={(e) => {
                    setuserData(e.target.value);
                    // console.log(postData);
                  }}
                  placeholder="Amount"
                  aria-label="Amount"
                  aria-describedby="basic-addon1"
                  className="form-control text-black text-xxl-left"
                />
              </div>
            </div>
          </div>
        </form>
        {/* </div>
        </div>
      </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setModalCentered(false);
            setuserData({});
          }}
          variant="danger"
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            toggleSendAmount();
          }}
        >
          Send amount
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMoneyForm;
