import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
// import Footer from "./layouts/Footer";

//using
// import Home from "./components/Dashboard/Home unused";
import Home from "./components/Dashboard/Home";
import RentCollectionList from "./components/Rent/RentCollectionList";
import LeadsTable from "./components/Leads/Leads";
import FundRequestList from "./components/FundRequest/FundRequestList";
import CheckRequestList from "./components/ChequeRequest/ChequeRequestList";
import PropertyList from "./components/Property/PropertyList";
import FundRequestDetails from "./components/FundRequest/FundRequestDetails";
import EditPropertyDetails from "./components/Property/EditProperty/EditPropertyDetails";
import AddNewProperty from "./components/Property/AddNewProperty/AddNewProperty";
import SupervisorLists from "./components/Supervisors/SupervisorLists";
import SupervisorDetails from "./components/Supervisors/SupervisorDetails";
import SupervisorTransaction from "./components/Supervisors/SupervisorTransaction";
import PropertyDetails from "./components/Property/PropertyDetails";
import DealsTable from "./components/Deals/Deals";
import WizardLeads from "./components/Leads/WizardLeads";
import LeadsDetails from "./components/Leads/LeadDetails";
import UsersList from "./components/users/UsersList";
import EditUserForm from "./components/users/EditUserForm";
import AddNewUser from "./components/users/AddNewUser";
import InvestorsList from "./components/Inverstors/InvestorsList";
import AddNewInvestor from "./components/Inverstors/AddNewInvestor";
import EditInvestor from "./components/Inverstors/EditInvestor";
import SelectUserTable from "./components/Property/SelectUsers/SelectUserTable";
import UnitDetails from "./components/Property/UnitDetails/UnitDetails";
import AddExpenseWizard from "./components/Property/AddExpense/AddExpenseWizard";
import SelectPropertyForUsers from "./components/users/SelectPropertyForUsers/SelectPropertyForUsers";
import Export from "./components/Export/Export";
import Transaction from "./components/Supervisors/Transaction";
import PropertyDealsTable from "./components/Property/PropertyDeals/PropertyDealList";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import AddInvestments from "./components/Inverstors/AddInvestments/AddInvestments";
import EditRentCollection from "./components/Rent/EditRentCollection";
import SecurityDepositList from "./components/SecurityDeposit/SecurityDepositList";
import BankTransfer from "./components/BankTransfer/BankTransferList";
import UserExport from "./components/UserExport/UserExport";
import AddBankTransferForm from "./components/BankTransfer/AddNewBankTransfer";
import AddNewCheque from "./components/ChequeRequest/AddNewCheque";
import RentRequestList from "./components/RentRequests/RentRequestList";
import AddNewRent from "./components/Rent/AddNewRent";
import RentRequestEdit from "./components/RentRequests/RentRequestEdit";
import SecurityDepositEdit from "./components/SecurityDeposit/SecurityDepositEdit";
import SecurityDepositsDetails from "./components/SecurityDeposit/SecurityDepositDetails";

const Markup = () => {
  const allroutes = [
    //Using
    { url: "dashboard", component: <Home /> },
    { url: "", component: <Home /> },
    { url: "rent-list", component: <RentCollectionList /> },
    { url: "rent-list/add", component: <AddNewRent /> },

    { url: "rent-requests", component: <RentRequestList /> },
    { url: "rent-requests/edit/:id", component: <RentRequestEdit /> },

    { url: "rent-list/edit/:id", component: <EditRentCollection /> },
    { url: "security-deposit-list", component: <SecurityDepositList /> },
    {
      url: "security-deposit-list/edit/:id",
      component: <SecurityDepositEdit />,
    },
    {
      url: "security-deposit-list/:id",
      component: <SecurityDepositsDetails />,
    },

    { url: "bank-transfer", component: <BankTransfer /> },

    {
      url: "bank-transfer/add",
      component: <AddBankTransferForm />,
    },

    { url: "report", component: <Export /> },

    { url: "users-list", component: <UsersList /> },
    { url: "/users-list/edit/:id", component: <EditUserForm /> },
    {
      url: "/users-list/assign-property/:id",
      component: <SelectPropertyForUsers />,
    },

    {
      url: "/users-list/export",
      component: <UserExport />,
    },
    { url: "/users-list/add-user", component: <AddNewUser /> },

    { url: `supervisor-list/:id`, component: <SupervisorDetails /> },
    {
      url: `/supervisor-list/:id/property-details/:id`,
      component: <PropertyDetails />,
    },

    {
      url: `supervisor-list/transactions/:id`,
      component: <SupervisorTransaction />,
    },
    { url: `supervisor-list`, component: <SupervisorLists /> },
    { url: `transaction-list`, component: <Transaction /> },
    { url: "fund-requests", component: <FundRequestList /> },
    { url: `fund-requests/:id`, component: <FundRequestDetails /> },
    { url: `cheque-requests`, component: <CheckRequestList /> },
    { url: `cheque-requests/add`, component: <AddNewCheque /> },

    { url: "property-list", component: <PropertyList /> },
    {
      url: `property-list/edit-property/:id`,
      component: <EditPropertyDetails />,
    },

    {
      url: `property-list/property-deals/:id`,
      component: <PropertyDealsTable />,
    },
    {
      url: `property-list/assign-property/:id`,
      component: <SelectUserTable />,
    },
    { url: `property-list/add-property`, component: <AddNewProperty /> },
    { url: `property-list/add-expense`, component: <AddExpenseWizard /> },
    {
      url: `property-list/property-details/:id`,
      component: <PropertyDetails />,
    },
    {
      url: `property-list/property-details/:id/unit-details/:id`,
      component: <UnitDetails />,
    },
    // {
    //   url: `property-list/property-details/:id/units`,
    //   component: <PropertyUnitsDetails />,
    // },

    { url: `investors-list`, component: <InvestorsList /> },
    { url: `investors-list/add-investor`, component: <AddNewInvestor /> },
    { url: `investors-list/edit/:id`, component: <EditInvestor /> },
    {
      url: `investors-list/add-investments/:id`,
      component: <AddInvestments />,
    },

    { url: `leads`, component: <LeadsTable /> },
    { url: `leads/add`, component: <WizardLeads /> },
    { url: `leads/:id`, component: <LeadsDetails /> },

    { url: `deals`, component: <DealsTable /> },

    /// Dashboard
    // { url: "dashboard", component: <Home /> },
    // { url: "", component: <Home /> },
    // { url: "analytics", component: <Analytics /> },
    // { url: "review", component: <Reviews /> },
    // { url: "order-list", component: <OrderList /> },
    // { url: "customer-list", component: <CustomerList /> },
    // { url: "task", component: <Task /> },

    //Agent
    // { url: "add-agent", component: <AddAgent /> },
    // { url: "add-agent-wizard", component: <AddAgentWizard /> },
    // { url: "all-agents", component: <AllAgents /> },
    // { url: "agent-profile", component: <AgentsProfile /> },

    //property
    // { url: "add-property", component: <AddProperty /> },

    //
    // { url: "flat-icons", component: <FlaticonIcons /> },
    // { url: "svg-icons", component: <SvgIcons /> },

    /// Apps
    // { url: "app-profile", component: <AppProfile /> },
    // { url: "post-details", component: <PostDetails /> },
    // { url: "email-compose", component: <Compose /> },
    // { url: "email-inbox", component: <Inbox /> },
    // { url: "email-read", component: <Read /> },
    // { url: "app-calender", component: <Calendar /> },

    ///Cms
    // { url: "content", component: <Content /> },
    // { url: "menu", component: <Menu /> },
    // { url: "email-template", component: <EmailTemplate /> },
    // { url: "blog", component: <CmsBlog /> },
    // { url: "add-content", component: <ContentAdd /> },
    // { url: "add-email", component: <AddMail /> },
    // { url: "add-blog", component: <AddBlog /> },
    // { url: "blog-category", component: <BlogCategory /> },

    /// Shop
    // { url: "ecom-product-grid", component: <ProductGrid /> },
    // { url: "ecom-product-list", component: <ProductList /> },
    // { url: "ecom-product-detail", component: <ProductDetail /> },
    // { url: "ecom-product-order", component: <ProductOrder /> },
    // { url: "ecom-checkout", component: <Checkout /> },
    // { url: "ecom-invoice", component: <Invoice /> },
    // { url: "ecom-customers", component: <EcomCustomers /> },

    /// Chart
    // { url: "chart-sparkline", component: <SparklineChart /> },
    // { url: "chart-chartjs", component: <ChartJs /> },
    // { url: "chart-apexchart", component: <ApexChart /> },
    // { url: "chart-rechart", component: <RechartJs /> },

    /// Bootstrap
    // { url: "ui-accordion", component: <UiAccordion /> },
    // { url: "ui-alert", component: <UiAlert /> },
    // { url: "ui-badge", component: <UiBadge /> },
    // { url: "ui-button", component: <UiButton /> },
    // { url: "ui-modal", component: <UiModal /> },
    // { url: "ui-button-group", component: <UiButtonGroup /> },
    // { url: "ui-list-group", component: <UiListGroup /> },
    // { url: "ui-media-object", component: <MediaObject /> },
    // { url: "ui-card", component: <UiCards /> },
    // { url: "ui-carousel", component: <UiCarousel /> },
    // { url: "ui-dropdown", component: <UiDropDown /> },
    // { url: "ui-popover", component: <UiPopOver /> },
    // { url: "ui-progressbar", component: <UiProgressBar /> },
    // { url: "ui-tab", component: <UiTab /> },
    // { url: "ui-pagination", component: <UiPagination /> },
    // { url: "ui-typography", component: <UiTypography /> },
    // { url: "ui-grid", component: <UiGrid /> },

    /// Plugin

    // { url: "uc-select2", component: <Select2 /> },
    // { url: "uc-sweetalert", component: <MainSweetAlert /> },
    // { url: "uc-toastr", component: <Toastr /> },
    // { url: "map-jqvmap", component: <JqvMap /> },
    // { url: "uc-lightgallery", component: <Lightgallery /> },

    /// Widget
    // { url: "widget-basic", component: <Widget /> },

    // Form
    // { url: "form-element", component: <Element /> },
    // { url: "form-wizard", component: <Wizard /> },
    // { url: "form-ckeditor", component: <CkEditor /> },
    // { url: "form-pickers", component: <Pickers /> },
    // { url: "form-validation", component: <FormValidation /> },

    /// table
    // { url: "table-datatable-basic", component: <DataTable /> },
    // { url: "table-filtering", component: <FilteringTable /> },
    // { url: "table-sorting", component: <SortingTable /> },
    // { url: "table-bootstrap-basic", component: <BootstrapTable /> },

    /// pages
    // { url: "empty", component: <EmptyPage /> },
    // { url: "todo", component: <Todo /> },
  ];

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        {/*  */}
        {/*  */}
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const sideMenu = useSelector((state) => state.sideMenu);
  return (
    <div
      id="main-wrapper "
      className={`show  ${sideMenu ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div className="content-body">
        <div
          className=" px-5
          container-fluid"
          style={{ minHeight: window.screen.height - 45 }}
        >
          <Outlet />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Markup;
