import { useState } from "react";

export default function SecurityDepositEditForm({
  securityDeposit,
  setSecurityDeposit,
  onSubmit,
  nav,
}) {
  const [errors, setErrors] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(
    securityDeposit?.payment_method || ""
  );

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    // Basic validation for required fields
    if (!value || (typeof value === "string" && value.trim() === "")) {
      fieldErrors[name] = `${name.replace(/_/g, " ")} is required`;
    } else {
      delete fieldErrors[name];
    }

    setErrors(fieldErrors);
  };

  const validateForm = () => {
    let newErrors = {};

    // Validate common required fields
    const requiredFields = [
      "date",
      "amount",
      "description",
      "voucher_number",
      "payment_method",
    ];

    requiredFields.forEach((field) => {
      const value = securityDeposit[field];
      if (!value || (typeof value === "string" && value.trim() === "")) {
        newErrors[field] = `${field.replace(/_/g, " ")} is required`;
      }
    });

    // Validate payment method specific fields
    switch (securityDeposit.payment_method) {
      case "card":
        const cardDetails = securityDeposit.card_details || {};
        const cardFieldsToValidate = [
          { field: "amount", label: "Card Amount" },
          { field: "card_type", label: "Card Type" },
          { field: "reference_number", label: "Reference Number" },
          { field: "beneficiary_name", label: "Beneficiary Name" },
        ];

        cardFieldsToValidate.forEach(({ field, label }) => {
          const value = cardDetails[field];
          if (!value || (typeof value === "string" && value.trim() === "")) {
            newErrors[`card_details.${field}`] = `${label} is required`;
          }
        });
        break;

      case "cheque":
        const chequeDetails = securityDeposit.cheque_details || {};
        const chequeFieldsToValidate = [
          { field: "cheque_date", label: "Cheque Date" },
          { field: "cheque_amount", label: "Cheque Amount" },
          { field: "signed_by", label: "Signed By" },
          { field: "received_date", label: "Received Date" },
        ];

        chequeFieldsToValidate.forEach(({ field, label }) => {
          const value = chequeDetails[field];
          if (!value || (typeof value === "string" && value.trim() === "")) {
            newErrors[`cheque_details.${field}`] = `${label} is required`;
          }
        });
        break;

      case "online":
        const onlineDetails = securityDeposit.online_transfer_details || {};
        const onlineFieldsToValidate = [
          { field: "beneficiary_name", label: "Beneficiary Name" },
          { field: "reference_number", label: "Reference Number" },
          { field: "date", label: "Online Transfer Date" },
          { field: "amount", label: "Online Transfer Amount" },
        ];

        onlineFieldsToValidate.forEach(({ field, label }) => {
          const value = onlineDetails[field];
          if (!value || (typeof value === "string" && value.trim() === "")) {
            newErrors[
              `online_transfer_details.${field}`
            ] = `${label} is required`;
          }
        });
        break;

      default:
        newErrors.payment_method = "Invalid payment method selected";
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePaymentMethodChange = (e) => {
    const value = e.target.value;
    setPaymentMethod(value);

    // Reset other payment method details when switching
    const resetDetails = {
      cheque_details: null,
      card_details: null,
      online_transfer_details: null,
    };

    // Set specific details based on payment method
    switch (value) {
      case "cheque":
        resetDetails.cheque_details = {};
        break;
      case "card":
        resetDetails.card_details = {};
        break;
      case "online":
        resetDetails.online_transfer_details = {};
        break;
      default:
        break;
    }

    setSecurityDeposit((prevState) => ({
      ...prevState,
      payment_method: value,
      ...resetDetails,
    }));
  };

  const handleFormSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit Security Deposit Details</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  {/* Deposit ID */}

                  {/* Date */}
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Date
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={securityDeposit?.date || ""}
                      onChange={(e) => {
                        setSecurityDeposit({
                          ...securityDeposit,
                          date: e.target.value,
                        });
                        validateField("date", e.target.value);
                      }}
                    />
                    {errors.date && (
                      <div className="text-danger">{errors.date}</div>
                    )}
                  </div>

                  {/* Amount */}
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Amount
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={securityDeposit?.amount || ""}
                      onChange={(e) => {
                        setSecurityDeposit({
                          ...securityDeposit,
                          amount: e.target.value,
                        });
                        validateField("amount", e.target.value);
                      }}
                    />
                    {errors.amount && (
                      <div className="text-danger">{errors.amount}</div>
                    )}
                  </div>

                  {/* Description */}
                  <div className="mb-3 col-12">
                    <label className="form-label">
                      Description
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <textarea
                      className="form-control"
                      value={securityDeposit?.description || ""}
                      onChange={(e) => {
                        setSecurityDeposit({
                          ...securityDeposit,
                          description: e.target.value,
                        });
                        validateField("description", e.target.value);
                      }}
                    />
                    {errors.description && (
                      <div className="text-danger">{errors.description}</div>
                    )}
                  </div>

                  {/* Voucher Number */}
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Voucher Number
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={securityDeposit?.voucher_number || ""}
                      onChange={(e) => {
                        setSecurityDeposit({
                          ...securityDeposit,
                          voucher_number: e.target.value,
                        });
                        validateField("voucher_number", e.target.value);
                      }}
                    />
                    {errors.voucher_number && (
                      <div className="text-danger">{errors.voucher_number}</div>
                    )}
                  </div>

                  {/* Payment Method */}
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Payment Method <span className="text-danger">*</span>{" "}
                    </label>
                    <select
                      className="form-control"
                      value={paymentMethod}
                      onChange={handlePaymentMethodChange}
                    >
                      <option value="cash">Cash</option>
                      <option value="card">Card</option>
                      <option value="online">Online Transfer</option>
                      <option value="cheque">Cheque</option>
                    </select>
                  </div>

                  {/* Conditional Fields */}
                  {securityDeposit.payment_method === "cheque" && (
                    <div className="row">
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Cheque Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={
                            securityDeposit.cheque_details.cheque_date || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                cheque_date: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["cheque_details.cheque_date"] && (
                          <div className="text-danger">
                            {errors["cheque_details.cheque_date"]}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Cheque Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            securityDeposit.cheque_details.cheque_amount || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                cheque_amount: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Signed By</label>
                        <input
                          type="text"
                          className="form-control"
                          value={securityDeposit.cheque_details.signed_by || ""}
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                signed_by: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["cheque_details.signed_by"] && (
                          <div className="text-danger">
                            {errors["cheque_details.signed_by"]}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Received Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={
                            securityDeposit.cheque_details.received_date || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                received_date: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["cheque_details.received_date"] && (
                          <div className="text-danger">
                            {errors["cheque_details.received_date"]}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-12">
                        <label className="form-label">Cheque Notes</label>
                        <textarea
                          className="form-control"
                          value={securityDeposit.cheque_details.notes || ""}
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                notes: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["cheque_details.notes"] && (
                          <div className="text-danger">
                            {errors["cheque_details.notes"]}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {securityDeposit.payment_method === "card" && (
                    <div className="row">
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Beneficiary Name</label>
                        <input
                          className="form-control"
                          value={
                            securityDeposit.card_details?.beneficiary_name || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              card_details: {
                                ...securityDeposit.card_details,
                                beneficiary_name: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["card_details.beneficiary_name"] && (
                          <div className="text-danger">
                            {errors["card_details.beneficiary_name"]}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Card Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          value={securityDeposit.card_details?.amount || ""}
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              card_details: {
                                ...securityDeposit.card_details,
                                amount: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["card_details.amount"] && (
                          <div className="text-danger">
                            {errors["card_details.amount"]}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Card Type</label>
                        <input
                          className="form-control"
                          value={securityDeposit.card_details?.card_type || ""}
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              card_details: {
                                ...securityDeposit.card_details,
                                card_type: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["card_details.card_type"] && (
                          <div className="text-danger">
                            {errors["card_details.card_type"]}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Reference Number</label>
                        <input
                          className="form-control"
                          value={
                            securityDeposit.card_details?.reference_number || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              card_details: {
                                ...securityDeposit.card_details,
                                reference_number: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["card_details.reference_number"] && (
                          <div className="text-danger">
                            {errors["card_details.reference_number"]}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-12">
                        <label className="form-label">Card Notes</label>
                        <textarea
                          className="form-control"
                          value={securityDeposit.card_details.notes || ""}
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              card_details: {
                                ...securityDeposit.card_details,
                                notes: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["card_details.notes"] && (
                          <div className="text-danger">
                            {errors["card_details.notes"]}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {securityDeposit.payment_method === "online" && (
                    <div className="row">
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Beneficiary name</label>
                        <input
                          className="form-control"
                          value={
                            securityDeposit.online_transfer_details
                              .beneficiary_name || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              online_transfer_details: {
                                ...securityDeposit.online_transfer_details,
                                beneficiary_name: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["online_transfer_details.beneficiary_name"] && (
                          <div className="text-danger">
                            {
                              errors[
                                "online_transfer_details.beneficiary_names"
                              ]
                            }
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">Reference number</label>
                        <input
                          className="form-control"
                          value={
                            securityDeposit.online_transfer_details
                              .reference_number || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              online_transfer_details: {
                                ...securityDeposit.online_transfer_details,
                                reference_number: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["online_transfer_details.reference_number"] && (
                          <div className="text-danger">
                            {errors["online_transfer_details.reference_number"]}
                          </div>
                        )}
                      </div>

                      <div className="mb-3 col-lg-6">
                        <label className="form-label">
                          Online Transfer Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={
                            securityDeposit.online_transfer_details.date || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              online_transfer_details: {
                                ...securityDeposit.online_transfer_details,
                                date: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["online_transfer_details.date"] && (
                          <div className="text-danger">
                            {errors["online_transfer_details.date"]}
                          </div>
                        )}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label className="form-label">
                          Online Transfer Amount
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            securityDeposit.online_transfer_details.amount || ""
                          }
                          onChange={(e) =>
                            setSecurityDeposit({
                              ...securityDeposit,
                              online_transfer_details: {
                                ...securityDeposit.online_transfer_details,
                                amount: e.target.value,
                              },
                            })
                          }
                        />
                        {errors["online_transfer_details.amount"] && (
                          <div className="text-danger">
                            {errors["online_transfer_details.amount"]}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </form>

              {/* Submit and Cancel Buttons */}
              <div className="row">
                <div className="col-sm-12 pt-3">
                  <button
                    type="button"
                    className="btn btn-success me-3"
                    onClick={handleFormSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => nav(-1)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
