import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";

import { getLeadDetails } from "../../../services/LeadsServices";
import { Link } from "react-router-dom";

const LeadsDetails = () => {
  const path = window.location.pathname.split("/");
  const leadsId = path[path.length - 1];
  const [leadDetails, setLeadDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getLeadDetails(leadsId).then((res) => {
      setLeadDetails(res.data.data);
      setIsLoading(false);
    });
  }, [leadsId]);
  console.log(leadDetails);

  const leadInfo = [
    { title: "Status", detail: leadDetails?.status },
    { title: "Type", detail: leadDetails?.type },
    {
      title: "Property Name",
      detail: leadDetails?.property
        ? leadDetails?.property
        : "Property Details Not Available",
    },

    {
      title: "Converted to deal",
      detail: leadDetails?.converted_to_deal ? "Yes" : "No",
    },
    {
      title: "Created Date",
      detail: new Date(leadDetails?.created_at).toUTCString(),
    },
    {
      title: "Updated Date",
      detail: new Date(leadDetails?.updated_at).toUTCString(),
    },
  ];

  const basicInfo = [
    {
      heading: {
        name: "Customer",
      },
      details: [
        { title: "Name", subtitle: leadDetails?.customer?.name },
        { title: "Email", subtitle: leadDetails?.customer?.email },
        { title: "Phone", subtitle: leadDetails?.customer?.phone },
      ],
    },
    {
      heading: {
        name: "Created by",
      },
      details: [
        { title: "Name", subtitle: leadDetails?.created_by?.name },
        { title: "Email", subtitle: leadDetails?.created_by?.email },
        { title: "Role", subtitle: leadDetails?.created_by?.role },
        {
          title: "Status",
          subtitle: leadDetails?.created_by?.active ? "Active" : "Inactive",
        },
        {
          title: "Created Date",
          subtitle: new Date(leadDetails?.created_by?.created_at).toUTCString(),
        },
      ],
    },
    {
      heading: {
        name: "Assigned to",
      },
      details: [
        { title: "Name", subtitle: leadDetails?.assigned_to?.name },
        { title: "Email", subtitle: leadDetails?.assigned_to?.email },
        { title: "Role", subtitle: leadDetails?.assigned_to?.role },
        {
          title: "Status",
          subtitle: leadDetails?.assigned_to?.active ? "Active" : "Inactive",
        },
        {
          title: "Created Date",
          subtitle: new Date(
            leadDetails?.assigned_to?.created_at
          ).toUTCString(),
        },
      ],
    },
  ];

  const renderBadge = (status) => {
    switch (status?.toLowerCase()) {
      case "cold":
        return (
          <Badge
            style={{
              cursor: "default",
            }}
            bg="primary light"
          >
            Cold
          </Badge>
        );
      case "warm":
        return (
          <Badge
            style={{
              cursor: "default",
            }}
            bg="warning light"
          >
            Warm
          </Badge>
        );
      case "hot":
        return (
          <Badge
            style={{
              cursor: "default",
            }}
            bg="danger light"
          >
            Hot
          </Badge>
        );
      case "normal":
      default:
        return (
          <Badge
            style={{
              cursor: "default",
            }}
            bg="white light"
          >
            Normal
          </Badge>
        );
    }
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        motherMenu={"Leads"}
        motherPath={"leads"}
        activeMenu={"Details"}
      />
      <div className="row gx-lg-3">
        <div className="col-xl-9 col-xxl-12">
          <div className="row gx-lg-3">
            {basicInfo.map((customerSet, index) => (
              <div className="col-xl-4 col-lg-4 col-sm-12" key={index}>
                <div className="card overflow-hidden">
                  <div className="text-center p-3 bg-success">
                    <h3 className="mt-3 mb-1 text-white">
                      {customerSet.heading.name}
                    </h3>
                    {/* <p className="text-white mb-0">
                      {customerSet.heading.phone}
                    </p> */}
                  </div>
                  <ul className="list-group list-group-flush">
                    {customerSet.details.map((item, detailIndex) => (
                      <li
                        className="list-group-item d-flex justify-content-between"
                        key={detailIndex}
                      >
                        <span className="mb-0">{item.title}</span>
                        <strong className="text-black">
                          {item.title === "Status" ? (
                            <Badge
                              style={{
                                cursor: "default",
                              }}
                              bg="badge-rounded"
                              className={`badge-${
                                item.subtitle === "Active"
                                  ? "success"
                                  : "danger"
                              }`}
                            >
                              {item.subtitle}
                            </Badge>
                          ) : (
                            item.subtitle
                          )}
                        </strong>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}

            <div className="col-12">
              <div className="card overflow-hidden">
                <div className="card-header">
                  <h4 className="card-title">Lead Detail</h4>
                </div>
                <div className="card-body">
                  <p>{leadDetails?.comment}</p>
                  <table className="check-tbl mb-2">
                    <tbody>
                      {leadInfo.map((item, ind) => (
                        <tr key={ind}>
                          <td>
                            <i className="las la-check-circle" /> {item.title} :
                          </td>
                          <td className="tb-para">
                            {item.title === "Property Name" ? (
                              item.detail ===
                              "Property Details Not Available" ? (
                                item.detail
                              ) : (
                                <Link
                                  to={`/property-list/property-details/${item.detail._id}`}
                                >
                                  {item.detail.property_name}
                                </Link>
                              )
                            ) : item.title === "Type" ? (
                              renderBadge(item.detail)
                            ) : (
                              item.detail
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="row border-top">
                    <h4 className="title my-3">Status Details</h4>

                    {leadDetails.status_history &&
                      leadDetails.status_history.map((item, ind) => (
                        <div className="col-lg-6">
                          <div className="card property-card border">
                            <div className={`card-body pt-2 pb-3 `}>
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                  <span className="mb-0 title">Status</span> :
                                  <span className="text-black ms-2">
                                    {item?.status}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="mb-0 title">Comment</span> :
                                  <span className="text-black ms-2">
                                    {item?.comment}
                                  </span>
                                </li>
                                <li className="list-group-item">
                                  <span className="mb-0 title">User</span> :
                                  <span className="text-black desc-text ms-2">
                                    {new Date(item?.date).toUTCString()}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadsDetails;
