// dashboardReducer.js

import {
  REQUEST_START,
  UPDATE_DASHBOARD_DATA,
} from "../actions/Types/DashboardActionTypes";

const initialState = {
  loading: false,
  dashboard: [],
};

const dashboardReducer = (state = initialState, action) => {
  if (action.type === REQUEST_START) {
    return {
      ...state,
      loading: true,
    };
  } else if (action.type === UPDATE_DASHBOARD_DATA) {
    return {
      ...state,
      dashboard: action.payload,
      loading: false,
    };
  } else {
    return {
      ...state,
    };
  }
};

export default dashboardReducer;
