import React, { Fragment, useState } from "react";
import NavHader from "./NavHader";
import Header from "./Header";
import SideBarNew from "./SideBarNew";

const JobieNav = ({ onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <Fragment>
      <NavHader />
      <SideBarNew onClick={() => onClick2()} onClick3={() => onClick3()} />
      {/* <NavbarPro onClick={() => onClick2()} onClick3={() => onClick3()} /> */}
      <Header onProfile={() => onClick("profile")} toggle={toggle} />
    </Fragment>
  );
};

export default JobieNav;
