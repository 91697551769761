import { GET_LEADS_DATA, REQUEST_START } from "../actions/LeadsAction";

const initialState = {
  loading: false,
  Leads: [],
};

const LeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    case GET_LEADS_DATA:
      return {
        ...state,
        Leads: action.payload, // Assuming action.payload is an array
        loading: false,
      };
    default:
      return state;
  }
};

export default LeadsReducer;
