/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import ConfirmUsersActionModal from "../users/ConfirmUsersActionModal";
import { investorsListAction } from "../../../store/actions/InvestorsAction";
import {
  deleteInvestor,
  onFreezeInvestor,
} from "../../../services/InvestorsServices";

function InvestorsList() {
  const dispatch = useDispatch();
  const { investorsList, loading } = useSelector((state) => state.investors);
  const [modalCentered, setModalCentered] = useState(false);
  const [modalType, setModalType] = useState([]);
  const [selectedInvestors, setselectedInvestors] = useState();

  const [sortBy, setSortBy] = useState("all");
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    dispatch(investorsListAction());
  }, [dispatch, sortBy]);

  console.log(investorsList);

  const totalPages = Math.ceil(investorsList?.users?.length / 10);

  const nav = useNavigate();

  // Pagination logic
  const handlePageChange = (isPrev) => {
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1); // Ensure page number is not less than 1
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //actions

  const onFreeze = (id) => {
    onFreezeInvestor(id)
      .then((res) => {
        console.log(res);
        toast.success(res.data.message);
        dispatch(investorsListAction());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = (id) => {
    deleteInvestor(id)
      .then((res) => {
        toast.success("Investor deleted successfully");
        dispatch(investorsListAction());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center ">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Investors List</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link>Investors</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/investors-list">Investors List</Link>
            </li>
          </ol>
        </div>
        <Link
          onClick={() => {
            window.location.reload();
          }}
          to="/fund-requests"
          className="btn btn-primary rounded light me-3"
        >
          Refresh
        </Link>
        {/* <Link to="/fund-requests" className="btn btn-primary rounded">
          <i className="flaticon-381-settings-2 me-0" />
        </Link> */}
      </div>

      <div className="row">
        <div className="col-12">
          <div className="widget-stat card">
            <div className="card-body p-4 card-d gap-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-success text-success">
                  <span className="">
                    <i className="flaticon-381-user-7"></i>
                  </span>
                </span>
                <div className="media-body">
                  <p className="mb-1">Total Investors</p>
                  <h4 className="mb-0">{investorsList?.length}</h4>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  className="me-2"
                  variant="success btn-rounded"
                  onClick={() => {
                    nav("add-Investor");
                  }}
                >
                  <span className="btn-icon-start text-success">
                    <i className="fa fa-plus color-success" />
                  </span>
                  Add new Investor
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <Row className="mb-2 w-100 col-12 justify-content-end">
            <ConfirmUsersActionModal
              modalCentered={modalCentered}
              setModalCentered={setModalCentered}
              modalType={modalType}
              onFreeze={onFreeze}
              selectedUser={selectedInvestors}
              onDelete={onDelete}
            />

            {/* <div className="d-flex justify-content-end w-100">
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="sm"
                  className="mt-1 mb-2 text-uppercase"
                >
                  Sort by : {sortBy}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSortBy("all")}>
                    Sort by : ALL{" "}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortBy("sales")}>
                    Sort by : Sales{" "}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSortBy("supervisor")}>
                    Sort by : Supervisor{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </Row>
          <div className="table-responsive table-hover fs-14 order-list-table h-auto  pb-5 ">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <table
                className="table display table-hover display mb-4 dataTablesCard card-table dataTable no-footer"
                id="example5"
              >
                <thead>
                  <tr role="row">
                    {/* <th className='orderList_sorting_asc'>
                                            <div className='form-check custom-checkbox ms-2'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='checkAll'
                                                    required
                                                // onClick={() => chackboxFun('all')}
                                                />
                                                <label
                                                    className='form-check-label'
                                                    htmlFor='checkAll'
                                                />
                                            </div>
                                        </th> */}
                    <th className="">First name</th>
                    <th className="">Last name</th>

                    <th className="">Email</th>

                    <th className="">Phone</th>

                    <th className="">Invested amount</th>
                    <th className="">Returns</th>

                    {/* <th className=''>Customer</th> */}
                    {/* <th className="">Role</th> */}
                    {/* <th className=''>Location</th> */}
                    <th className="">Status</th>
                    {/* <th className=''>Type</th> */}
                    {/* <th className=''>Agent</th> */}
                    {/* <th className="sorting">Status</th> */}
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {investorsList?.length > 0 ? (
                    investorsList?.map((item, index) => (
                      <tr
                        key={index}
                        // className={`${item?.active ? "" : "disabled-row"}`}
                      >
                        <td>{item?.first_name}</td>
                        <td>{item?.last_name}</td>

                        <td>{item?.email}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.total_investment_amount}</td>
                        <td>{item.total_return_amount}</td>

                        {/* <td>{item.customer}</td> */}
                        {/* <td>{item?.active ? "Active " : "not active"}</td> */}

                        {/* <td>{item.location}</td> */}
                        {/* <td>{item.type}</td> */}
                        {/* <td>{item.agent}</td> */}
                        <td>
                          <span
                            className={`text-${
                              item?.active ? "success" : "danger"
                            }`}
                          >
                            {item?.active ? "Active " : "Deactivate"}
                          </span>
                        </td>
                        <td>
                          <Dropdown className="ms-auto">
                            <Dropdown.Toggle
                              variant=""
                              className="btn-link i-false"
                            >
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                                  stroke="#3E4954"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                                  stroke="#3E4954"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                                  stroke="#3E4954"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-top">
                              {item?.active && (
                                <>
                                  <Dropdown.Item
                                    className="text-black"
                                    onClick={() => {
                                      nav(
                                        `/investors-list/add-investments/${item._id}`
                                      );
                                    }}
                                  >
                                    Add Investments
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    className="text-black"
                                    onClick={() => {
                                      nav(`/investors-list/edit/${item._id}`);
                                    }}
                                  >
                                    Edit User
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="text-black"
                                    onClick={() => {
                                      setModalCentered(true);

                                      setModalType("delete_user");

                                      setselectedInvestors(item._id);
                                    }}
                                  >
                                    Delete User
                                  </Dropdown.Item>
                                </>
                              )}
                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  setModalCentered(true);
                                  if (item?.active) {
                                    setModalType("deactivate_user");
                                  } else {
                                    setModalType("activate_user");
                                  }
                                  setselectedInvestors(item._id);
                                }}
                              >
                                {item?.active
                                  ? "Deactivate Account"
                                  : "Activate Account"}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No Investors</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {investorsList?.users?.length} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <div
                    className="paginate_button previous"
                    onClick={() => {
                      if (pageNo > 1) {
                        handlePageChange(true);
                      }
                    }}
                  >
                    <i className="fa fa-angle-double-left" />
                  </div>
                  <span>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <div
                        key={i}
                        className={`paginate_button ${
                          pageNo === i + 1 ? "current" : ""
                        }`}
                        onClick={() => {
                          setPageNo(i + 1);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {i + 1}
                      </div>
                    ))}
                  </span>
                  <div
                    className="paginate_button next"
                    onClick={() => {
                      if (totalPages > pageNo) {
                        handlePageChange(false);
                      }
                    }}
                  >
                    <i className="fa fa-angle-double-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestorsList;
