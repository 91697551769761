import { Button, Modal } from "react-bootstrap";

export default function FundModals(
  rejectModalCentered,
  setRejectModalCentered,
  postData,
  setPostData,
  toggleRejected,
  modalCentered,
  setModalCentered,
  toggleSendAmount
) {
  return (
    <>
      <Modal
        className="fade"
        show={rejectModalCentered}
        onHide={setRejectModalCentered}
        centered
      >
        <Modal.Header>
          <Modal.Title>Reject Request</Modal.Title>
          <Button
            onClick={() => {
              setRejectModalCentered(false);
            }}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon">
                  <span className="me-3 bgl-primary text-primary">
                    <svg
                      id="icon-customers"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Supervisor</p>
                    <h4 className="mb-0">{postData.supervisorName}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="card shadow-none">
              <h4 className="card-header text-black px-0 card-title--large">
                Reason for Rejection
              </h4>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                  <textarea
                    onChange={(e) => {
                      setPostData({
                        ...postData,
                        reason: e.target.value,
                      });
                    }}
                    className="form-control border-black text-black"
                    rows="4"
                    placeholder="Type your reason here..."
                    style={{
                      fontSize: "18px",
                      caretColor: "black",
                    }}
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setRejectModalCentered(false);
              setPostData({});
            }}
            variant="danger"
          >
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              toggleRejected();
            }}
          >
            Reject
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Acceptance */}

      <Modal
        className="fade"
        show={modalCentered}
        onHide={setModalCentered}
        centered
      >
        <Modal.Header>
          <Modal.Title>Send amount</Modal.Title>
          <Button
            onClick={() => {
              setModalCentered(false);
            }}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon">
                  <span className="me-3 bgl-primary text-primary">
                    <svg
                      id="icon-customers"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Supervisor</p>
                    <h4 className="mb-0">{postData.supervisorName}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative">
            <div className="input-group input-group-lg">
              <span
                className="input-group-text label-primary label-xl"
                id="basic-addon1"
              >
                AED
              </span>
              <input
                type="number"
                value={postData.amount}
                onChange={(e) => {
                  setPostData({ ...postData, amount: e.target.value });
                  // console.log(postData);
                }}
                placeholder="Amount"
                aria-label="Amount"
                aria-describedby="basic-addon1"
                className="form-control text-black text-xxl-left"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalCentered(false);
              setPostData({});
            }}
            variant="danger"
          >
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              toggleSendAmount();
            }}
          >
            Send amount
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
