import { useState } from "react";

export default function InvestorForm({
  investorAccount,
  setInvestorAccount,
  onSubmit,
  nav,
  isEdit,
}) {
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case "first_name":
        if (!value) fieldErrors.name = "First name is required";
        else delete fieldErrors.first_name;
        break;
      case "last_name":
        if (!value) fieldErrors.last_name = "Last name is required";
        else delete fieldErrors.last_name;
        break;
      case "email":
        if (!value) {
          fieldErrors.email = "Email is required";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          fieldErrors.email = "Invalid email format";
        } else {
          delete fieldErrors.email;
        }
        break;

      case "password":
        if (isEdit) break;
        if (!value) {
          fieldErrors.password = "Password is required";
        } else if (value.length < 8) {
          fieldErrors.password = "Password must be at least 8 characters";
        } else delete fieldErrors.password;
        if (
          investorAccount?.confirm_password &&
          value !== investorAccount?.confirm_password
        ) {
          fieldErrors.confirm_password = "Passwords must match";
        } else {
          delete fieldErrors.confirm_password;
        }
        break;
      case "confirm_password":
        if (isEdit) break;
        if (investorAccount?.password !== value)
          fieldErrors.confirm_password = "Passwords must match";
        else delete fieldErrors.confirm_password;
        break;

      case "street":
        if (!value) fieldErrors.street = "Street is required";
        else delete fieldErrors.street;
        break;
      case "city":
        if (!value) fieldErrors.city = "City is required";
        else delete fieldErrors.city;
        break;
      case "phone":
        if (!value) {
          fieldErrors.phone = "Phone is required";
        } else if (value.length < 10) {
          fieldErrors.phone = "Phone must be at least 10 numbers";
        } else {
          delete fieldErrors.phone;
        }
        break;
      case "state":
        if (!value) fieldErrors.state = "State is required";
        else delete fieldErrors.state;
        break;

      case "country":
        if (!value) fieldErrors.country = "Country is required";
        else delete fieldErrors.country;
        break;

      default:
        break;
    }

    setErrors(fieldErrors);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    const requiredFields = [
      "first_name",
      "last_name",
      "email",
      "phone",
      "street",
      "city",
      "state",
      "country",
    ];
    if (!isEdit) {
      requiredFields.push("password", "confirm_password");
    }

    requiredFields.forEach((field) => {
      if (!investorAccount[field]) {
        newErrors[field] = `${field.replace(/_/g, " ")} is required`;
        isValid = false;
      }
    });

    if (!isEdit) {
      if (investorAccount.password && investorAccount.password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
        isValid = false;
      }

      if (investorAccount.password !== investorAccount.confirm_password) {
        newErrors.confirm_password = "Passwords must match";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleFormSubmit = () => {
    console.log(validateForm());
    if (validateForm()) {
      onSubmit();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Add Investor details</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="mb-3 col-lg-6">
                    <label className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={investorAccount?.first_name || ""}
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          first_name: e.target.value,
                        });
                        validateField("first_name", e.target.value);
                      }}
                      placeholder="Enter first name"
                    />
                    {errors.first_name && (
                      <div className="text-danger">{errors.first_name}</div>
                    )}
                  </div>
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">Last Name</label>{" "}
                    {/* <span className="text-danger">*</span> */}
                    <input
                      type="text"
                      value={investorAccount?.last_name || ""}
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          last_name: e.target.value,
                        });
                        validateField("last_name", e.target.value);
                      }}
                      className="form-control"
                      placeholder="Enter name here"
                    />
                    <span className="text-danger">{errors.last_name}</span>
                  </div>

                  {/* <div className="mb-3 col-lg-4 col-md-6">
                    <label className="form-label">Staff ID</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      value={investorAccount?.staff_id || ""}
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          staff_id: e.target.value,
                        });
                        validateField("staff_id", e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Enter staff id"
                    />
                    <span className="text-danger">{errors.staff_id}</span>
                  </div> */}

                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">Email</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      value={investorAccount?.email || ""}
                      autoComplete="off"
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          email: e.target.value,
                        });
                        validateField("email", e.target.value);
                      }}
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                    />
                    <span className="text-danger">{errors.email}</span>
                  </div>

                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">Phone</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      value={investorAccount?.phone || ""}
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          phone: e.target.value,
                        });
                        validateField("phone", e.target.value);
                      }}
                      type="number"
                      className="form-control"
                      placeholder="Enter Phone number"
                    />
                    <span className="text-danger">{errors.phone}</span>
                  </div>

                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">Street</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      value={investorAccount?.street || ""}
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          street: e.target.value,
                        });
                        validateField("street", e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Enter street"
                    />
                    <span className="text-danger">{errors.street}</span>
                  </div>

                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">City</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      value={investorAccount?.city || ""}
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          city: e.target.value,
                        });
                        validateField("city", e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Enter city"
                    />
                    <span className="text-danger">{errors.city}</span>
                  </div>

                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">State</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      value={investorAccount?.state || ""}
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          state: e.target.value,
                        });
                        validateField("state", e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Enter state"
                    />
                    <span className="text-danger">{errors.street}</span>
                  </div>
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">Country</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      value={investorAccount?.country || ""}
                      onChange={(e) => {
                        setInvestorAccount({
                          ...investorAccount,
                          country: e.target.value,
                        });
                        validateField("country", e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Enter Country"
                    />
                    <span className="text-danger">{errors.country}</span>
                  </div>

                  {!isEdit && (
                    <>
                      <div className="mb-3 col-sm-6">
                        <label className="form-label">Password</label>{" "}
                        <span className="text-danger">*</span>
                        <input
                          autoComplete="new-password"
                          type="password"
                          className="form-control"
                          value={investorAccount?.password || ""}
                          onChange={(e) => {
                            setInvestorAccount({
                              ...investorAccount,
                              password: e.target.value,
                            });
                            validateField("password", e.target.value);
                          }}
                          placeholder="Password must be 8 characters or more."
                        />
                        <span className="text-danger">{errors.password}</span>
                      </div>

                      <div className="mb-3 col-sm-6">
                        <label className="form-label">Confirm Password</label>{" "}
                        <span className="text-danger">*</span>
                        <input
                          type="password" // Changed to password type for consistency
                          className="form-control"
                          value={investorAccount?.confirm_password || ""}
                          onChange={(e) => {
                            setInvestorAccount({
                              ...investorAccount,
                              confirm_password: e.target.value,
                            });
                            validateField("confirm_password", e.target.value);
                          }}
                          placeholder="Confirm your password"
                        />
                        <span className="text-danger">
                          {errors.confirm_password}
                        </span>
                      </div>
                    </>
                  )}

                  {/* <div className="mb-3 col-12">
                    <label className="form-label">Role</label>{" "}
                    <span className="text-danger">*</span>
                    <div className="col-lg-6">
                      <select
                        value={investorAccount?.role || "default"}
                        onChange={(e) => {
                          setInvestorAccount({
                            ...investorAccount,
                            role: e.target.value,
                          });
                          validateField("role", e.target.value);
                        }}
                        className="form-control"
                      >
                        <option value="default" disabled>
                          Choose an option
                        </option>
                        <option value="sales">Sales</option>
                        <option value="supervisor">Supervisor</option>
                      </select>
                    </div>
                    <span className="text-danger">{errors.role}</span>
                  </div> */}
                </div>
              </form>
              <div className="row">
                <div className="col-sm-12 pt-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary me-2"
                    onClick={handleFormSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger light"
                    onClick={() => nav("/investors-list")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
