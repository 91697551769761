import React from "react";
import { Button, Modal } from "react-bootstrap";
import { RentDeleteAction } from "../../../store/actions/RentActions";

export default function RentDeleteModal({
  viewDetails,
  modalCentered,
  setModalCentered,
  dispatch,
}) {
  const onDelete = () => {
    console.log(viewDetails?._id);
    dispatch(RentDeleteAction(viewDetails?._id, setModalCentered));
  };

  return (
    <Modal
      className="fade bd-example-modal-lg"
      size="lg"
      show={modalCentered}
      onHide={() => setModalCentered(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete rent</Modal.Title>
        <Button
          onClick={() => setModalCentered(false)}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="row gap-3">
          <h4 className="subtitle">
            Are you sure you want to delete this rent?
          </h4>
          <div className="col-12">
            <div className="property-card style-1">
              <div className="card-body pt-2 pb-3">
                <ul className="list-group list-group-flush">
                  {viewDetails?.type && (
                    <li className="list-group-item">
                      <span className="mb-0 title">Type</span> :
                      <span className="text-black ms-2">
                        {viewDetails.type}
                      </span>
                    </li>
                  )}
                  <li className="list-group-item">
                    <span className="mb-0 title">User</span> :
                    <span className="text-black ms-2">
                      {viewDetails?.user?.name || "Name not Available"}
                    </span>
                  </li>
                  <li className="list-group-item">
                    <span className="mb-0 title">Description</span> :
                    <span className="text-black ms-2">
                      {viewDetails?.description}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center">
        <Button variant="success" onClick={() => setModalCentered(false)}>
          Close
        </Button>
        <Button variant="danger" onClick={onDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
