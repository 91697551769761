import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import InvestorForm from "./InvestorForm";
import toast from "react-hot-toast";
import { AddInvestor } from "../../../services/InvestorsServices";

const AddNewInvestor = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [investorAccount, setInvestorAccount] = useState({});

  const onSubmit = () => {
    const postData = {
      first_name: investorAccount?.first_name,
      last_name: investorAccount?.last_name,
      email: investorAccount?.email,
      password: investorAccount?.password,
      phone: investorAccount?.phone,
      street: investorAccount?.street,
      city: investorAccount?.city,
      state: investorAccount?.state,
      country: investorAccount?.country,
    };
    console.log("reached 1");
    setIsLoading(true);
    AddInvestor(postData)
      .then((res) => {
        console.log("reached 2");
        toast.success("Investor added successfully");
        nav("/investors-list");

        console.log("reached 3");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        activeMenu={"Add Investor"}
        activePath={"add-investor"}
        motherMenu={"Investors List"}
        motherPath={"investors-list"}
      />
      <InvestorForm
        investorAccount={investorAccount}
        setInvestorAccount={setInvestorAccount}
        onSubmit={onSubmit}
        nav={nav}
        isEdit={false}
      />
    </>
  );
};

export default AddNewInvestor;
