import React, { Fragment, useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import PageTitle from "../../layouts/PageTitle";
import SelectPropertyTable from "../SelectProperty/SelectPropertyTable";
import AddNewRentForm from "./AddNewRentForm";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PropertyAction } from "../../../store/actions/PropertryActions";
import { mediaUpload } from "../../../services/MediaUploadService";
import { addRent } from "../../../services/RentServices";
import { getPropertyDetails } from "../../../services/PropertyService";

const AddNewRent = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedPropertyUnits, setSelectedPropertyUnits] = useState(null);
  const [errors, setErrors] = useState({});

  const { properties, loading } = useSelector((state) => state.properties);
  const [rentData, setRentData] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(PropertyAction("all"));
  }, [dispatch]);

  const handleSelectedPropertyChange = (propertyData) => {
    setSelectedProperty(propertyData);
    console.log(propertyData);
    if (propertyData) {
      getPropertyDetails(propertyData)
        .then((res) => {
          console.log(res.data.property.units);
          setSelectedPropertyUnits(res.data.property.units);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!rentData.billing_date)
      newErrors.billing_date = "Billing date is required";
    if (!rentData.amount) newErrors.amount = "Amount is required";
    if (!rentData.payment_method)
      newErrors.payment_method = "Payment method is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmitClicked = () => {
    if (validateForm()) {
      handleFormSubmit();
    }
  };

  const handleRentDataChange = (data) => {
    setRentData(data);
  };

  const handleFormSubmit = async () => {
    try {
      if (
        !selectedProperty ||
        !rentData?.unit_id ||
        !rentData?.voucher_number ||
        !rentData?.billing_date ||
        !rentData?.amount ||
        !rentData?.payment_method ||
        !rentData?.images
      ) {
        toast.error("Please fill in all required fields except description.");
        return;
      }

      setIsSubmitting(true);

      // Handle image uploads (unchanged)
      const imageUploads = await Promise.all(
        rentData.images.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          try {
            const response = await mediaUpload(formData);
            if (response.data?.files?.[0]) {
              return {
                url: response.data.files[0],
              };
            }
            return null;
          } catch (error) {
            console.error("Error uploading image:", error);
            return null;
          }
        })
      );

      const validImages = imageUploads.filter((image) => image !== null);

      if (validImages.length === 0) {
        toast.error("No images were uploaded successfully.");
        setIsSubmitting(false);
        return;
      }

      const formData = {
        property_id: selectedProperty,
        unit_id: rentData.unit_id,
        voucher_number: String(rentData.voucher_number),
        billing_date: rentData.billing_date,
        amount: String(rentData.amount),
        description: rentData.description || "",
        payment_method: String(rentData.payment_method),
        images: validImages,
      };

      // Add payment method specific details
      if (rentData.payment_method === "cheque") {
        formData.cheque_details = {
          cheque_date: rentData.cheque_date,
          cheque_amount: String(rentData.cheque_amount),
          signed_by: rentData.signed_by,
          received_date: rentData.received_date,
          notes: rentData.cheque_notes || "",
        };
      } else if (rentData.payment_method === "card") {
        formData.card_details = {
          notes: rentData.card_notes || "",
          amount: String(rentData.card_amount),
          card_type: rentData.card_type || "",
          beneficiary_name: rentData.beneficiary_name || "",
          reference_number: rentData.reference_number || "",
        };
      } else if (rentData.payment_method === "online") {
        formData.online_transfer_details = {
          date: rentData.online_transfer_date,
          amount: String(rentData.online_transfer_amount),
          beneficiary_name: rentData.beneficiary_name || "",
          reference_number: rentData.reference_number || "",
        };
      }

      console.log(formData);

      const response = await addRent(formData);

      if (response.data) {
        toast.success("Rent added successfully");
        navigate("/rent-list");
      }
    } catch (error) {
      console.log(error);
      const errorMessage =
        error?.response?.data?.error || "Something went wrong";
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading || isSubmitting) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <PageTitle activeMenu="Add" motherMenu="Rents" motherPath="rent-list" />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {currentStep === 0 ? "Select Property" : "Add New Rent"}
              </h4>
            </div>
            <div className="card-body">
              <div className="form-wizard">
                <Stepper className="nav-wizard" activeStep={currentStep}>
                  <Step
                    className="nav-link"
                    onClick={() => setCurrentStep(0)}
                  />
                  <Step
                    className="nav-link"
                    onClick={() => setCurrentStep(1)}
                  />
                </Stepper>
                {currentStep === 0 && (
                  <SelectPropertyTable
                    onPropertySelect={handleSelectedPropertyChange}
                    properties={properties}
                    selectedProperty={selectedProperty}
                    setGoSteps={setCurrentStep}
                  />
                )}
                {currentStep === 1 && (
                  <Fragment>
                    <AddNewRentForm
                      rent={rentData}
                      setRent={handleRentDataChange}
                      errors={errors}
                      setErrors={setErrors}
                      selectedPropertyUnits={selectedPropertyUnits}
                    />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-warning sw-btn-prev me-1"
                        onClick={() => setCurrentStep(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-success ms-1"
                        onClick={handleFormSubmitClicked}
                      >
                        Submit
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddNewRent;
