import {
  REQUEST_START_TRANSACTION,
  UPDATE_NOT_APPROVED_LIST_DATA,
} from "../actions/SupervisorsActions";

const initialState = {
  loading: false,
  notApprovedTransactionList: [],
};

const notApprovedTransactionReducer = (state = initialState, action) => {
  if (action.type === REQUEST_START_TRANSACTION) {
    return {
      ...state,
      loading: true,
    };
  } else if (action.type === UPDATE_NOT_APPROVED_LIST_DATA) {
    return {
      ...state,
      notApprovedTransactionList: action.payload,
      loading: false,
    };
  } else {
    return {
      ...state,
    };
  }
};

export default notApprovedTransactionReducer;
