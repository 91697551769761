import React, { useState } from "react";
import BankTransferDetailsForm from "./BankTransferDetailsForm";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { addNewBankTransfer } from "../../../services/BankTransferServices";

const AddBankTransferForm = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [bankTransferDetails, setBankTransferDetails] = useState({
    reference_number: "",
    amount: "",
    transaction_type: "",
    notes: "",
    date: "",
  });

  const onSubmit = () => {
    setIsLoading(true);
    // console.log("Form submitted:", bankTransferDetails);
    const postData = {
      reference_number: bankTransferDetails.reference_number,
      amount: bankTransferDetails.amount,
      transaction_type: bankTransferDetails.type,
      note: bankTransferDetails.notes,
      date: bankTransferDetails.date,
    };

    // console.log(postData);

    addNewBankTransfer(postData)
      .then((res) => {
        console.log(res);

        toast.success("Bank transfer added successfully");
        nav("/bank-transfer");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <BankTransferDetailsForm
      bankTransferDetails={bankTransferDetails}
      setBankTransferDetails={setBankTransferDetails}
      onSubmit={onSubmit}
    />
  );
};

export default AddBankTransferForm;
