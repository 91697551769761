import React, { Fragment, useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import PageTitle from "../../layouts/PageTitle";
import SelectPropertyTable from "../SelectProperty/SelectPropertyTable";
import AddNewLeadForm from "./AddNewLeadForm";
import { PropertyAction } from "../../../store/actions/PropertryActions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createLead } from "../../../services/LeadsServices";

const WizardLeads = () => {
  const [goSteps, setGoSteps] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const { properties, loading } = useSelector((state) => state.properties);
  const [LeadsData, setLeadsData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    dispatch(PropertyAction("all"));
  }, [dispatch]);

  const handleSelectedPropertyChange = (propertyData) => {
    setSelectedProperty(propertyData);
  };

  const handleLeadsData = (data) => {
    setLeadsData(data);
  };

  // console.log(selectedProperty);

  const onSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(LeadsData);

    if (
      !LeadsData?.name ||
      !LeadsData?.comment ||
      (!LeadsData?.email && LeadsData?.email !== "") ||
      !LeadsData?.phone ||
      !LeadsData?.status ||
      !LeadsData?.type
    ) {
      toast.error(
        "All fields are required. Please ensure no field is left empty."
      );
      setIsLoading(false);
      return;
    }

    // Check for optional email format
    if (LeadsData?.email && !emailRegex.test(LeadsData.email)) {
      toast.error("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const FormData = {
      name: LeadsData?.name,
      email: LeadsData?.email, //optional
      phone: LeadsData?.phone,
      property_id: selectedProperty,
      comment: LeadsData?.comment,
      status: LeadsData?.status,
      type: LeadsData?.type,
    };

    createLead(FormData)
      .then((res) => {
        setLeadsData(null);
        setSelectedProperty(null);
        toast.success(res.data.message);
        nav("/leads");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLeadsData(null);
        setIsLoading(false);
        toast.error("Something went wrong. Please try again later.");
      });
  };

  if (loading || isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <PageTitle activeMenu={`Add`} motherMenu="Leads" motherPath={"leads"} />

      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {goSteps === 0 ? `Select Property` : "Add New Lead"}
              </h4>
            </div>
            <div className="card-body">
              <div className="form-wizard ">
                <Stepper className="nav-wizard" activeStep={goSteps}>
                  <Step className="nav-link" onClick={() => setGoSteps(0)} />
                  <Step className="nav-link" onClick={() => setGoSteps(1)} />
                </Stepper>
                {goSteps === 0 && (
                  <>
                    <SelectPropertyTable
                      onPropertySelect={handleSelectedPropertyChange}
                      properties={properties}
                      selectedProperty={selectedProperty}
                      setGoSteps={setGoSteps}
                    />
                  </>
                )}
                {goSteps === 1 && (
                  <>
                    <AddNewLeadForm Data={handleLeadsData} />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-warning sw-btn-prev me-1"
                        onClick={() => setGoSteps(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-success ms-1"
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
                {/* {goSteps === 2 && (
                  <>
                    <StepThree />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(1)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(3)}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )} */}
                {/* {goSteps === 3 && (
                  <>
                    <StepFour />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(4)}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WizardLeads;
