import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PageTitle from "../../layouts/PageTitle";
import { isEqual } from "lodash";
import SecurityDepositEditForm from "./SecurityDepositEditForm";
import {
  getSecurityDepositDetails,
  updateSecurityDepositDetails,
} from "../../../services/SecurityDepositServices";

const SecurityDepositEdit = () => {
  const nav = useNavigate();
  const path = window.location.pathname.split("/");
  const UserId = path[path.length - 1];
  const [securityDeposit, setSecurityDeposit] = useState({});
  const [oldSecurityDeposit, setOldSecurityDeposit] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Format date in YYYY-MM-DD format
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(d.getDate()).padStart(2, "0")}`;
  };

  // Fetch security deposit details on mount
  useEffect(() => {
    const fetchSecurityDepositDetails = async () => {
      try {
        const res = await getSecurityDepositDetails(UserId);
        const data = res?.data?.data;

        const formattedData = {
          ...data,
          date: formatDate(data?.date),

          // Restructure nested details based on payment method
          payment_method: data?.payment_method,

          // Cheque details
          cheque_details: data?.cheque_details
            ? {
                cheque_date: formatDate(data.cheque_details.cheque_date),
                cheque_amount: String(data.cheque_details.cheque_amount || ""),
                signed_by: data.cheque_details.signed_by,
                received_date: formatDate(data.cheque_details.received_date),
                notes: data.cheque_details.notes,
              }
            : null,

          // Online transfer details
          online_transfer_details: data?.online_transfer_details
            ? {
                beneficiary_name: data.online_transfer_details.beneficiary_name,
                date: formatDate(data.online_transfer_details.date),
                amount: String(data.online_transfer_details.amount || ""),
                reference_number: data.online_transfer_details.reference_number,
              }
            : null,

          // Card details
          card_details: data?.card_details
            ? {
                beneficiary_name: data.card_details.beneficiary_name,
                amount: String(data.card_details.amount || ""),
                card_type: data.card_details.card_type,
                reference_number: data.card_details.reference_number,
                notes: data.card_details.notes,
              }
            : null,

          // Ensure images are processed correctly
          images: data?.images?.map((img) => img.url) || [],
        };

        setSecurityDeposit(formattedData);
        setOldSecurityDeposit(formattedData);
      } catch (err) {
        console.error("Error fetching security deposit details:", err);
        toast.error("Failed to fetch security deposit details.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSecurityDepositDetails();
  }, [UserId]);

  // Prepare post data for API submission
  const preparePostData = () => {
    return {
      depositId: securityDeposit._id,
      date: securityDeposit?.date,
      amount: String(securityDeposit?.amount),
      description: securityDeposit?.description,
      property_id: securityDeposit?.property?._id,
      unit_id: securityDeposit?.unit?._id,
      voucher_number: securityDeposit?.voucher_number,
      payment_method: securityDeposit?.payment_method,

      images: securityDeposit?.images?.map((url) => ({ url })) || [],

      // Conditionally include payment method specific details
      ...(securityDeposit?.payment_method === "card" && {
        card_details: {
          amount: String(securityDeposit.card_details?.amount || "0"),
          card_type: securityDeposit.card_details?.card_type || "",
          reference_number:
            securityDeposit.card_details?.reference_number || "",
          beneficiary_name:
            securityDeposit.card_details?.beneficiary_name || "",
          notes: securityDeposit.card_details?.notes || "",
        },
      }),

      // Similar modifications for other payment methods
      ...(securityDeposit?.payment_method === "cheque" && {
        cheque_details: {
          cheque_date: securityDeposit.cheque_details?.cheque_date || "",
          cheque_amount: String(
            securityDeposit.cheque_details?.cheque_amount || "0"
          ),
          signed_by: securityDeposit.cheque_details?.signed_by || "",
          received_date: securityDeposit.cheque_details?.received_date || "",
          notes: securityDeposit.cheque_details?.notes || "",
        },
      }),

      ...(securityDeposit?.payment_method === "online" && {
        online_transfer_details: {
          beneficiary_name:
            securityDeposit.online_transfer_details?.beneficiary_name || "",
          date: securityDeposit.online_transfer_details?.date || "",
          amount: String(
            securityDeposit.online_transfer_details?.amount || "0"
          ),
          reference_number:
            securityDeposit.online_transfer_details?.reference_number || "",
        },
      }),
    };
  };

  // Handle form submission
  const onSubmit = async () => {
    if (isEqual(oldSecurityDeposit, securityDeposit)) {
      toast.error(
        "No changes made. Please edit the details before submitting."
      );
      return;
    }

    setIsLoading(true);
    const postData = preparePostData();
    // console.log(postData);

    try {
      await updateSecurityDepositDetails(postData);
      toast.success("Security deposit details updated successfully!");
      nav("/security-deposit-list"); // Navigate back to the list
    } catch (err) {
      console.log("Error updating security deposit details:", err);
      toast.error("Failed to update security deposit details.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        activeMenu="Edit security deposit"
        activePath="edit"
        motherMenu="Security Deposit List"
        motherPath="security-deposit-list"
      />
      <SecurityDepositEditForm
        nav={nav}
        onSubmit={onSubmit}
        securityDeposit={securityDeposit}
        setSecurityDeposit={setSecurityDeposit}
      />
    </>
  );
};

export default SecurityDepositEdit;
