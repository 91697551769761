import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Button, Modal } from "react-bootstrap";
import noImage from "../../../images/no-image.jpg";
import { Link } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import toast from "react-hot-toast";

import { approveTransaction } from "../../../services/SupervisorService";
import { useDispatch, useSelector } from "react-redux";
import { notApprovedTransactions } from "../../../store/actions/SupervisorsActions";

function Transaction() {
  const dispatch = useDispatch();

  const [viewDetails, setViewDetails] = useState([]);
  const [modalCentered, setModalCentered] = useState(false);
  const [confirmModalCentered, setConfirmModalCentered] = useState(false);
  const [approveAction, setApproveAction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { notApprovedTransactionList, loading } = useSelector(
    (state) => state.notApprovedTransactions
  );

  useEffect(() => {
    dispatch(notApprovedTransactions());
  }, [dispatch]);

  console.log(notApprovedTransactionList);

  const toggleViewDetails = (item) => {
    setModalCentered(true);
    setViewDetails(item);
  };

  const submitApproveTransaction = () => {
    setConfirmModalCentered(false);

    const postData = {
      id: selectedId,
      approved: approveAction,
    };
    console.log(postData);
    setIsLoading(true);

    approveTransaction(postData)
      .then((res) => {
        console.log(res);
        toast.success(res.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong !!!");
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(notApprovedTransactions());
      });
  };

  if (loading || isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center ">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Transactions List</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/transaction-list">Supervisor</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/transaction-list">Transactions List</Link>
            </li>
          </ol>
        </div>
        <Link
          onClick={() => {
            window.location.reload();
          }}
          to="/fund-requests"
          className="btn btn-primary rounded light me-3"
        >
          Refresh
        </Link>
      </div>
      <div className="row h-100">
        <div className="col-xl-6 col-xxl-12">
          <div className="card house-bx">
            <div className="card-body">
              <div className="media align-items-center">
                <svg
                  width={55}
                  height={55}
                  viewBox="0 0 88 85"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M77.25 	30.8725V76.25H10.75V30.8725L44 8.70001L77.25 30.8725Z"
                    fill="url(#paint0_linear)"
                  />
                  <path
                    d="M2 76.25H86V85H2V76.25Z"
                    fill="url(#paint1_linear)"
                  />{" "}
                  <path
                    d="M21.25 39.5H42.25V76.25H21.25V39.5Z"
                    fill="url(#paint2_linear)"
                  />
                  <path
                    d="M52.75 39.5H66.75V64H52.75V39.5Z"
                    fill="url(#paint3_linear)"
                  />
                  <path
                    d="M87.9424 29.595L84.0574 35.405L43.9999 8.70005L3.94237 35.405L0.057373 29.595L43.9999 0.300049L87.9424 29.595Z"
                    fill="url(#paint4_linear)"
                  />
                  <path
                    d="M49.25 62.25H70.25V65.75H49.25V62.25Z"
                    fill="url(#paint5_linear)"
                  />
                  <path
                    d="M52.75 50H66.75V53.5H52.75V50Z"
                    fill="url(#paint6_linear)"
                  />
                  <path
                    d="M28.25 57C28.25 57.4642 28.0656 57.9093 27.7374 58.2375C27.4092 58.5657 26.9641 58.75 26.5 58.75C26.0359 58.75 25.5908 58.5657 25.2626 58.2375C24.9344 57.9093 24.75 57.4642 24.75 57C24.75 56.5359 24.9344 56.0908 25.2626 55.7626C25.5908 55.4344 26.0359 55.25 26.5 55.25C26.9641 55.25 27.4092 55.4344 27.7374 55.7626C28.0656 56.0908 28.25 56.5359 28.25 57Z"
                    fill="url(#paint7_linear)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="19.255"
                      y1="28.8075"
                      x2="64.1075"
                      y2="73.6775"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F9F9DF" />
                      <stop offset={1} stopColor="#B6BDC6" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1={2}
                      y1="80.625"
                      x2={86}
                      y2="80.625"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1="22.9825"
                      y1="40.6025"
                      x2="37.8575"
                      y2="69.915"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F0CB49" />
                      <stop offset={1} stopColor="#E17E43" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear"
                      x1="52.75"
                      y1="51.75"
                      x2="66.75"
                      y2="51.75"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#7BC7E9" />
                      <stop offset={1} stopColor="#3C6DB0" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear"
                      x1="0.057373"
                      y1="17.8525"
                      x2="87.9424"
                      y2="17.8525"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#E17E43" />
                      <stop offset={1} stopColor="#85152E" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear"
                      x1="784.25"
                      y1="216.25"
                      x2="1036.25"
                      y2="216.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint6_linear"
                      x1="570.75"
                      y1="179.5"
                      x2="682.75"
                      y2="179.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint7_linear"
                      x1="98.25"
                      y1="195.25"
                      x2="105.25"
                      y2="195.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#E17E43" />
                      <stop offset={1} stopColor="#85152E" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="media-body">
                  <h4 className="fs-22 text-white">INFORMATION</h4>
                  <p className="mb-0">Supervisor needs a fast response</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Confirmation modal */}

        <Modal
          className="fade"
          show={confirmModalCentered}
          onHide={confirmModalCentered}
          centered
        >
          <Modal.Header>
            <Modal.Title>
              {" "}
              {approveAction ? "Approve transaction" : "Reject transaction"}
            </Modal.Title>
            {/* <Button
              onClick={() => {
                setModalCentered(false);
              }}
              variant=""
              className="btn-close"
            ></Button> */}
          </Modal.Header>
          <Modal.Body>
            <div className="position-relative">
              {" "}
              <h4 className="subtitle">
                {approveAction
                  ? "Are you sure you want to approve this transaction?"
                  : "Are you sure you want to reject this transaction?"}
              </h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setConfirmModalCentered(false);
              }}
              variant="danger"
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => {
                submitApproveTransaction();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for details */}

        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          show={modalCentered}
          onHide={setModalCentered}
          centered
        >
          <Modal.Header>
            <Modal.Title>Details</Modal.Title>
            <Button
              onClick={() => {
                setModalCentered(false);
              }}
              variant=""
              className="btn-close"
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="row">
              <div
                className="col-12"
                // key={ind}
              >
                <div className="property-card style-1">
                  <div className={`dz-media `}>
                    <Zoom>
                      <img alt="" src={viewDetails?.images?.[0] || noImage} />
                    </Zoom>
                  </div>

                  <div className={`card-body pt-2 pb-3`}>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <span className="mb-0 title">Voucher number</span> :
                        <span className="text-black ms-2">
                          {viewDetails?.voucher_number
                            ? viewDetails?.voucher_number
                            : "Voucher number not available"}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Description</span> :
                        <span className="text-black ms-2">
                          {viewDetails?.description}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <Modal.Title>User details</Modal.Title>
                      </li>

                      <li className="list-group-item">
                        <span className="mb-0 title">Email</span> :
                        <span className="text-black desc-text ms-2">
                          {viewDetails?.user?.email}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Role</span> :
                        <span className="text-black desc-text ms-2">
                          {viewDetails?.user?.role}
                        </span>
                      </li>
                      <li className="list-group-item">
                        <span className="mb-0 title">Status</span> :
                        <span className="text-black desc-text ms-2">
                          <Badge
                            bg=""
                            className={`badge-${
                              viewDetails?.user?.active ? "success" : "danger"
                            } badge-xs`}
                          >
                            {viewDetails?.user?.active ? "Active" : "Inactive"}
                          </Badge>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer className="flex-column d-flex justify-content-center">
            <Button
              variant="success"
              onClick={() => {
                setModalCentered(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>

        <div className="col-xl-12 h-100 ">
          <div className="table-responsive  fs-14 order-list-table">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <table
                className="table display  display mb-4 dataTablesCard card-table dataTable no-footer"
                id="example5"
              >
                <thead>
                  <tr role="row">
                    <th className=""> name</th>
                    <th className="">Email</th>

                    <th className="">Date</th>

                    <th className="">Property name</th>
                    <th className="">Amount </th>
                    <th className="sorting">type</th>
                    <th className="sorting">Status</th>

                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notApprovedTransactionList?.length > 0 ? (
                    notApprovedTransactionList?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.user?.name}</td>
                        <td>{item?.user?.email}</td>

                        <td>
                          {item?.created_at
                            ? new Date(item?.created_at).toUTCString()
                            : ""}
                        </td>

                        <td>{item?.property?.property_name}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.type}</td>

                        <td>
                          <Badge
                            bg=""
                            className={`badge-${
                              item?.is_approved ? "green" : "warning "
                            } badge-xs`}
                          >
                            {item?.is_approved ? "Approved" : "Not Approved"}
                          </Badge>
                        </td>

                        <Dropdown className="ms-auto">
                          <Dropdown.Toggle
                            variant=""
                            className="btn-link i-false"
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                                stroke="#3E4954"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item
                              className="text-black"
                              onClick={() => {
                                setApproveAction(true);
                                setConfirmModalCentered(true);
                                setSelectedId(item._id);
                              }}
                            >
                              Approve
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="text-black"
                              onClick={() => {
                                setApproveAction(false);
                                setConfirmModalCentered(true);
                                setSelectedId(item._id);
                              }}
                            >
                              Reject
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="text-black"
                              onClick={() => toggleViewDetails(item)}
                            >
                              View details
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No Transactions</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-5 ">
                <div className="dataTables_info">
                  Showing {notApprovedTransactionList?.length} of{" "}
                  {notApprovedTransactionList?.length} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  {/* <Link
                    className="paginate_button previous"
                    to="#"
                    onClick={() => handlePageChange(true)}
                  >
                    <i className="fa fa-angle-double-left" />
                  </Link> */}
                  {/* <span>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <Link
                        key={i}
                        to="#"
                        className={`paginate_button ${
                          pageNo === i + 1 ? "current" : ""
                        }`}
                        onClick={() => {
                          setPageNo(i + 1);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {i + 1}
                      </Link>
                    ))}
                  </span> */}
                  {/* <Link
                    className="paginate_button next"
                    to="#"
                    onClick={() => {
                      if (totalPages > pageNo) {
                        handlePageChange(false);
                      }
                    }}
                  >
                    <i className="fa fa-angle-double-right" />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Transaction;
