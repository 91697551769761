import React, { useEffect, useState } from "react";
import UserForm from "./UserForm";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PageTitle from "../../layouts/PageTitle";
import { editUser, getUserDetails } from "../../../services/UsersServices";
import UserEdit from "./UserEdit";

const EditUserForm = () => {
  const nav = useNavigate();
  const path = window.location.pathname.split("/");
  const UserId = path[path.length - 1];
  const [userAccount, setUserAccount] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    // Get User details from API
    getUserDetails(UserId)
      .then((res) => {
        setUserAccount(res.data.user);
        console.log(res.data.user);
        setIsLoading(false);
      })
      .catch((err) => {
        nav("/page-error-400");
        setIsLoading(false);
      });
  }, [UserId, nav]);

  const onSubmit = () => {
    const postData = {
      name: userAccount?.name,
      staff_id: userAccount?.staff_id,
      email: userAccount?.email,
      password: userAccount?.password,
      role: userAccount?.role,
    };

    editUser(UserId, postData)
      .then((res) => {
        toast.success("User updated successfully");
        nav("/users-list");
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Something went wrong"
        );
      });
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }
  return (
    <>
      <PageTitle
        activeMenu={"Edit User"}
        activePath={"edit-user"}
        motherMenu={"User List"}
        motherPath={"users-list"}
      />
      <UserEdit
        userAccount={userAccount}
        setUserAccount={setUserAccount}
        onSubmit={onSubmit}
        nav={nav}
      />
    </>
  );
};

export default EditUserForm;
