export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-dashboard-1" />,
    content: [
      {
        title: "Dashboard",
        to: "dashboard",
      },
      {
        title: "Rent",
        to: "rent-list",
      },
      {
        title: "Rent requests",
        to: "rent-requests",
      },
      {
        title: "Security Deposit",
        to: "security-deposit-list",
      },
      {
        title: "Bank transfer",
        to: "bank-transfer",
      },
      {
        title: "Report",
        to: "report",
      },
    ],
  },

  {
    title: "Users",

    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user-9" />,
    content: [
      {
        title: "Users list",
        to: "users-list",
      },
    ],
  },
  {
    title: "Investors",

    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user-2" />,
    content: [
      {
        title: "Investors list",
        to: "investors-list",
      },
    ],
  },
  {
    title: "Supervisors",

    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user-7" />,
    content: [
      {
        title: "Supervisor list",
        to: "supervisor-list",
      },
      {
        title: "Pending Transactions",
        to: "transaction-list",
      },
      {
        title: "Fund Requests",
        to: "fund-requests",
      },
      {
        title: "Cheque Requests",
        to: "cheque-requests",
      },
    ],
  },
  {
    title: "Property",

    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-layer-1" />,
    content: [
      {
        title: "Property List",
        to: "property-list",
      },
    ],
  },
  {
    title: "CRM",

    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-chart" />,
    content: [
      {
        title: "Leads",
        to: "leads",
      },
      {
        title: "Deals",
        to: "deals",
      },
    ],
  },
];
