import {
  getNotApprovedTransactionList,
  getSupervisorListData,
} from "../../services/SupervisorService";

export const UPDATE_SUPERVISOR_LIST_DATA = "[UPDATE_SUPERVISOR_LIST_DATA]";
export const UPDATE_NOT_APPROVED_LIST_DATA = "[UPDATE_NOT_APPROVED_LIST_DATA]";
export const REQUEST_START = "REQUEST_START";

export const REQUEST_START_TRANSACTION = "REQUEST_START_TRANSACTION";

export function SupervisorListAction(navigate) {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getSupervisorListData()
      .then((response) => {
        dispatch(updateSupervisorListData(response.data));
        //  console.log(response);
      })
      .catch((error) => {});
  };
}

export function notApprovedTransactions() {
  return (dispatch) => {
    dispatch({ type: REQUEST_START_TRANSACTION });

    getNotApprovedTransactionList()
      .then((response) => {
        dispatch(updateNotApprovedListData(response.data));
        console.log(response);
      })
      .catch((error) => {});
  };
}

export function updateSupervisorListData(data) {
  return {
    type: UPDATE_SUPERVISOR_LIST_DATA,
    payload: data,
  };
}

export function updateNotApprovedListData(data) {
  return {
    type: UPDATE_NOT_APPROVED_LIST_DATA,
    payload: data,
  };
}
