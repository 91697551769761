export function DocumentDetailsComponent({
  PropertyData,
  showAllDocuments,
  handleHideAllDocumentClick,
  handleSeeAllDocumentClick,
}) {
  return (
    <div className="col-12">
      <div className="card-header mb-0 border-0 pb-0 d-flex justify-content-between align-items-center">
        <h3 className="fs-20 text-black mb-0">Document Details</h3>
        {PropertyData.documents?.length > 2 && (
          <p
            className="text-blue light text-decoration-underline c-pointer"
            onClick={
              showAllDocuments
                ? handleHideAllDocumentClick
                : handleSeeAllDocumentClick
            }
          >
            {showAllDocuments ? "Hide All" : "See All"}
          </p>
        )}
      </div>
      <div className="row card-body">
        {PropertyData.documents && PropertyData.documents.length > 0 ? (
          PropertyData.documents.map((doc, index) => (
            <div
              className={`col-xl-3 col-xxl-4 col-md-6 col-sm-6 col-lg-4 m-b30`}
              key={index}
            >
              <div
                className={`border rounded py-2 ${
                  showAllDocuments || index < 2 ? "visible" : "hidden"
                }`}
              >
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <span className="mb-0 title">Label</span> :
                    <span className="text-black ms-2">{doc.label}</span>
                  </li>
                  <li className="list-group-item">
                    <span className="mb-0 title">Description</span> :
                    <span className="text-black desc-text ms-2">
                      {doc.description}
                    </span>
                  </li>
                  <li className="list-group-item">
                    {/* Direct link to document */}
                    <span className="text-black desc-text">
                      <a
                        href={doc.document_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`btn btn-outline${
                          doc.document_link ? "-success" : "-danger disabled"
                        }`}
                      >
                        {doc.document_link
                          ? "View Document"
                          : "No Document Available"}
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ))
        ) : (
          <div
            className={`card-body d-flex justify-content-center align-items-center h-100`}
          >
            <p className="d-flex justify-content-center align-items-center h-100">
              No documents available.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
