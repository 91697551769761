import { getDashboardData } from "../../services/DashboardService";
import {
  REQUEST_START,
  UPDATE_DASHBOARD_DATA,
} from "./Types/DashboardActionTypes";

export function DashboardAction(navigate) {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getDashboardData()
      .then((response) => {
        dispatch(updateDashboardData(response.data));
        console.log(response);
      })
      .catch((error) => {});
  };
}

export function updateDashboardData(data) {
  return {
    type: UPDATE_DASHBOARD_DATA,
    payload: data,
  };
}
