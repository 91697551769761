import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PageTitle from "../../layouts/PageTitle";
import InvestorForm from "./InvestorForm";
import { isEqual } from "lodash";
import {
  getInvestorDetails,
  updateInvestor,
} from "../../../services/InvestorsServices";

const EditInvestor = () => {
  const nav = useNavigate();
  const path = window.location.pathname.split("/");
  const UserId = path[path.length - 1];
  const [investorAccount, setInvestorAccount] = useState({});
  const [oldInvestorAccount, setOldInvestorAccount] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state to true

  useEffect(() => {
    // Get User details from API
    getInvestorDetails(UserId)
      .then((res) => {
        // console.log(res.data.investor);
        setOldInvestorAccount({
          first_name: res?.data?.investor?.first_name,
          last_name: res?.data?.investor?.last_name,
          email: res?.data?.investor?.email,
          phone: res?.data?.investor?.phone,
          street: res?.data?.investor?.address?.street,
          city: res?.data?.investor?.address?.city,
          state: res?.data?.investor?.address?.state,
          country: res?.data?.investor?.address?.country,
          password: res?.data?.investor?.password,
        });

        setInvestorAccount({
          first_name: res?.data?.investor?.first_name,
          last_name: res?.data?.investor?.last_name,
          email: res?.data?.investor?.email,
          phone: res?.data?.investor?.phone,
          street: res?.data?.investor?.address?.street,
          city: res?.data?.investor?.address?.city,
          state: res?.data?.investor?.address?.state,
          country: res?.data?.investor?.address?.country,
          password: res?.data?.investor?.password,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // nav("/page-error-400");
        setIsLoading(false);
      });
  }, [UserId, nav]);

  const onSubmit = () => {
    setIsLoading(true);

    const hasFormChanges = !isEqual(oldInvestorAccount, investorAccount);
    if (!hasFormChanges) {
      toast.error(
        "No changes made. Please edit the details before submitting."
      );
      setIsLoading(false);
      return;
    }

    const postData = {
      first_name: investorAccount?.first_name,
      last_name: investorAccount?.last_name,
      email: investorAccount?.email,
      phone: investorAccount?.phone,
      street: investorAccount?.street,
      city: investorAccount?.city,
      state: investorAccount?.state,
      country: investorAccount?.country,
      password: investorAccount?.password,
    };

    updateInvestor(UserId, postData)
      .then((res) => {
        setIsLoading(false);

        toast.success("Investor updated successfully");
        nav("/investors-list");
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Something went wrong"
        );
      });
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }
  return (
    <>
      <PageTitle
        activeMenu={"Edit User"}
        activePath={"edit-user"}
        motherMenu={"User List"}
        motherPath={"users-list"}
      />
      <InvestorForm
        investorAccount={investorAccount}
        setInvestorAccount={setInvestorAccount}
        onSubmit={onSubmit}
        nav={nav}
        isEdit={true}
      />
    </>
  );
};

export default EditInvestor;
