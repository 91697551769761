import { CHEQUE_LIST, REQUEST_START } from "../actions/ChequeAction";

const initialState = {
  loading: false,
  chequeList: {},
};

const chequeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
      };

    case CHEQUE_LIST:
      return {
        ...state,
        chequeList: action.payload,

        loading: false,
      };
    default:
      return state;
  }
};

export default chequeReducer;
