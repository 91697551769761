import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SupervisorListAction } from "../../../store/actions/SupervisorsActions";
import AddMoneyForm from "./AddMoneyForm";

function SupervisorLists() {
  const dispatch = useDispatch();
  const { supervisorList, loading } = useSelector((state) => state.supervisors);
  const [modalCentered, setModalCentered] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // const [sortBy, setSortBy] = useState("all");
  const [pageNo, setPageNo] = useState(1);

  // const [postData, setPostData] = useState({});
  useEffect(() => {
    dispatch(SupervisorListAction());
  }, [dispatch]);

  const totalPages = Math.ceil(supervisorList.length / 10);

  const nav = useNavigate();
  // Pagination logic

  const handlePageChange = (isPrev) => {
    let newPageNo = pageNo + (isPrev ? -1 : 1);
    newPageNo = Math.max(newPageNo, 1); // Ensure page number is not less than 1
    setPageNo(newPageNo);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (loading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <AddMoneyForm
        modalCentered={modalCentered}
        setModalCentered={setModalCentered}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />
      <div className="form-head page-titles d-flex  align-items-center ">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Supervisor List</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link>Supervisor</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/supervisor-list">Supervisor List</Link>
            </li>
          </ol>
        </div>
        <Link
          onClick={() => {
            window.location.reload();
          }}
          to="/fund-requests"
          className="btn btn-primary rounded light me-3"
        >
          Refresh
        </Link>
        {/* <Link to="/fund-requests" className="btn btn-primary rounded">
          <i className="flaticon-381-settings-2 me-0" />
        </Link> */}
      </div>
      <div className="row">
        <div className="col-xl-6 col-xxl-12">
          <div className="card house-bx">
            <div className="card-body">
              <div className="media align-items-center">
                <svg
                  width={55}
                  height={55}
                  viewBox="0 0 88 85"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M77.25 	30.8725V76.25H10.75V30.8725L44 8.70001L77.25 30.8725Z"
                    fill="url(#paint0_linear)"
                  />
                  <path
                    d="M2 76.25H86V85H2V76.25Z"
                    fill="url(#paint1_linear)"
                  />{" "}
                  <path
                    d="M21.25 39.5H42.25V76.25H21.25V39.5Z"
                    fill="url(#paint2_linear)"
                  />
                  <path
                    d="M52.75 39.5H66.75V64H52.75V39.5Z"
                    fill="url(#paint3_linear)"
                  />
                  <path
                    d="M87.9424 29.595L84.0574 35.405L43.9999 8.70005L3.94237 35.405L0.057373 29.595L43.9999 0.300049L87.9424 29.595Z"
                    fill="url(#paint4_linear)"
                  />
                  <path
                    d="M49.25 62.25H70.25V65.75H49.25V62.25Z"
                    fill="url(#paint5_linear)"
                  />
                  <path
                    d="M52.75 50H66.75V53.5H52.75V50Z"
                    fill="url(#paint6_linear)"
                  />
                  <path
                    d="M28.25 57C28.25 57.4642 28.0656 57.9093 27.7374 58.2375C27.4092 58.5657 26.9641 58.75 26.5 58.75C26.0359 58.75 25.5908 58.5657 25.2626 58.2375C24.9344 57.9093 24.75 57.4642 24.75 57C24.75 56.5359 24.9344 56.0908 25.2626 55.7626C25.5908 55.4344 26.0359 55.25 26.5 55.25C26.9641 55.25 27.4092 55.4344 27.7374 55.7626C28.0656 56.0908 28.25 56.5359 28.25 57Z"
                    fill="url(#paint7_linear)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="19.255"
                      y1="28.8075"
                      x2="64.1075"
                      y2="73.6775"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F9F9DF" />
                      <stop offset={1} stopColor="#B6BDC6" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1={2}
                      y1="80.625"
                      x2={86}
                      y2="80.625"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1="22.9825"
                      y1="40.6025"
                      x2="37.8575"
                      y2="69.915"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F0CB49" />
                      <stop offset={1} stopColor="#E17E43" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear"
                      x1="52.75"
                      y1="51.75"
                      x2="66.75"
                      y2="51.75"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#7BC7E9" />
                      <stop offset={1} stopColor="#3C6DB0" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear"
                      x1="0.057373"
                      y1="17.8525"
                      x2="87.9424"
                      y2="17.8525"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#E17E43" />
                      <stop offset={1} stopColor="#85152E" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear"
                      x1="784.25"
                      y1="216.25"
                      x2="1036.25"
                      y2="216.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint6_linear"
                      x1="570.75"
                      y1="179.5"
                      x2="682.75"
                      y2="179.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3C6DB0" />
                      <stop offset={1} stopColor="#291F51" />
                    </linearGradient>
                    <linearGradient
                      id="paint7_linear"
                      x1="98.25"
                      y1="195.25"
                      x2="105.25"
                      y2="195.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#E17E43" />
                      <stop offset={1} stopColor="#85152E" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="media-body">
                  <h4 className="fs-22 text-white">INFORMATION</h4>
                  <p className="mb-0">List of all Supervisor </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <Row className="mb-2 w-100 col-12 justify-content-end">
            {/* <div className="d-flex justify-content-end w-100">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    size="sm"
                    className="mt-1 mb-2 text-uppercase"
                  >
                    Sort by : {sortBy}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setSortBy("all")}>
                      Sort by : ALL{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortBy("requested")}>
                      Sort by : REQUESTED{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortBy("accepted")}>
                      Sort by : ACCEPTED{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortBy("rejected")}>
                      Sort by : REJECTED{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
          </Row>
          <div className="table-responsive table-hover fs-14 order-list-table h-auto  pb-5 ">
            <div id="orderList" className="dataTables_wrapper no-footer">
              <table
                className="table display table-hover display mb-4 dataTablesCard card-table dataTable no-footer"
                id="example5"
              >
                <thead>
                  <tr role="row">
                    {/* <th className='orderList_sorting_asc'>
                                            <div className='form-check custom-checkbox ms-2'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='checkAll'
                                                    required
                                                // onClick={() => chackboxFun('all')}
                                                />
                                                <label
                                                    className='form-check-label'
                                                    htmlFor='checkAll'
                                                />
                                            </div>
                                        </th> */}
                    <th className="">Supervisor name</th>
                    <th className="">Email</th>
                    {/* <th className=''>Customer</th> */}
                    <th className="">Role</th>
                    {/* <th className=''>Location</th> */}
                    <th className="">Status</th>
                    {/* <th className=''>Type</th> */}
                    {/* <th className=''>Agent</th> */}
                    {/* <th className="sorting">Status</th> */}
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {supervisorList?.length > 0 ? (
                    supervisorList?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        {/* <td>{item.customer}</td> */}
                        {/* <td>{item?.active ? "Active " : "not active"}</td> */}

                        {/* <td>{item.location}</td> */}
                        <td>{item?.role}</td>
                        {/* <td>{item.type}</td> */}
                        {/* <td>{item.agent}</td> */}
                        <td>
                          <span
                            className={`text-${
                              item?.active === "false" ? "danger" : "success"
                            }`}
                          >
                            {item?.active ? "Active " : "not active"}
                          </span>
                        </td>
                        <td>
                          <Dropdown className="ms-auto">
                            <Dropdown.Toggle
                              variant=""
                              className="btn-link i-false"
                            >
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.0005 12C11.0005 12.5523 11.4482 13 12.0005 13C12.5528 13 13.0005 12.5523 13.0005 12C13.0005 11.4477 12.5528 11 12.0005 11C11.4482 11 11.0005 11.4477 11.0005 12Z"
                                  stroke="#3E4954"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.0005 12C18.0005 12.5523 18.4482 13 19.0005 13C19.5528 13 20.0005 12.5523 20.0005 12C20.0005 11.4477 19.5528 11 19.0005 11C18.4482 11 18.0005 11.4477 18.0005 12Z"
                                  stroke="#3E4954"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M4.00049 12C4.00049 12.5523 4.4482 13 5.00049 13C5.55277 13 6.00049 12.5523 6.00049 12C6.00049 11.4477 5.55277 11 5.00049 11C4.4482 11 4.00049 11.4477 4.00049 12Z"
                                  stroke="#3E4954"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-top">
                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  // nav(`/supervisor-list/add-money/${item._id}`);
                                  setModalCentered(true);
                                  setSelectedId(item._id);
                                }}
                              >
                                Add Money to wallet
                              </Dropdown.Item>

                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  nav(`/supervisor-list/${item._id}`);
                                }}
                              >
                                View details
                              </Dropdown.Item>

                              <Dropdown.Item
                                className="text-black"
                                onClick={() => {
                                  nav(
                                    `/supervisor-list/transactions/${item._id}`,
                                    { state: { id: index } }
                                  );
                                }}
                              >
                                View transactions
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No Supervisors</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {supervisorList.length} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <Link
                    className="paginate_button previous"
                    to="#"
                    onClick={() => handlePageChange(true)}
                  >
                    <i className="fa fa-angle-double-left" />
                  </Link>
                  <span>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <Link
                        key={i}
                        to="#"
                        className={`paginate_button ${
                          pageNo === i + 1 ? "current" : ""
                        }`}
                        onClick={() => {
                          setPageNo(i + 1);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {i + 1}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="#"
                    onClick={() => {
                      if (totalPages > pageNo) {
                        handlePageChange(false);
                      }
                    }}
                  >
                    <i className="fa fa-angle-double-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupervisorLists;
