import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import noImage from "../../../images/no-image.jpg";
import Zoom from "react-medium-image-zoom";

const FrontViewSlider = (images) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="font-view-slider-container">
      <Slider {...settings}>
        {images?.image?.length > 0 ? (
          images?.image?.map((item, index) => {
            return (
              <div key={index} className="items">
                <div className="front-view">
                  <Zoom>
                    <img src={item ? item : noImage} alt="" />
                  </Zoom>
                </div>
              </div>
            );
          })
        ) : (
          <div className="items">
            <div className="front-view">
              <Zoom>
                <img src={noImage} alt="" />
              </Zoom>
            </div>
          </div>
        )}
      </Slider>
    </div>
  );
};

export default FrontViewSlider;
