import { Button, Modal } from "react-bootstrap";

export default function ConfirmPropertyActionModal(
  modalCentered,
  setModalCentered,
  modalType,
  onFreeze,
  selectedProperty,
  onDelete,
  onReady
) {
  return (
    <Modal
      className="fade"
      show={modalCentered}
      onHide={setModalCentered}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          {modalType === "unfreeze_property"
            ? "Unfreeze Property"
            : modalType === "freeze_property"
            ? "Freeze Property"
            : modalType === "ready_to_use" || modalType === "not_ready_to_use"
            ? "Convert Ready"
            : "Delete Property"}
        </Modal.Title>
        {/* <Button
            onClick={() => {
              setModalCentered(false);
            }}
            variant=""
            className="btn-close"
          ></Button> */}
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative">
          {" "}
          <h4 className="subtitle">
            {modalType === "unfreeze_property"
              ? "Are you sure you want to unfreeze this property?"
              : modalType === "freeze_property"
              ? "Are you sure you want to freeze this property?"
              : modalType === "ready_to_use"
              ? "Are you sure you want to mark this property as ready to use?"
              : modalType === "not_ready_to_use"
              ? "Are you sure you want to mark this property as not ready to use?"
              : "Are you sure you want to delete this property?"}
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setModalCentered(false);
          }}
          variant="success"
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            if (modalType === "freeze_property") {
              onFreeze(selectedProperty);
              setModalCentered(false);
            } else if (modalType === "unfreeze_property") {
              onFreeze(selectedProperty);
              setModalCentered(false);
            } else if (modalType === "delete_property") {
              onDelete(selectedProperty);
              setModalCentered(false);
            } else if (modalType === "ready_to_use") {
              onReady(selectedProperty);
              setModalCentered(false);
            } else if (modalType === "not_ready_to_use") {
              onReady(selectedProperty);
              setModalCentered(false);
            }
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
