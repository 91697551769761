import toast from "react-hot-toast";
import {
  approveRent,
  getNotApprovedList,
} from "../../services/RentRequestServices";

export const RENT_REQUEST_LIST = "RENT_REQUEST_LIST";
export const REQUEST_START = "REQUEST_START";

export function rentRequestNotApprovedAction() {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });

    getNotApprovedList()
      .then((response) => {
        const data = response.data.data;
        console.log(data);

        dispatch(updateRentRequestList(data));
      })
      .catch((error) => {});
  };
}

export function approveRentRequestAction(id, type) {
  return (dispatch) => {
    approveRent(id, type)
      .then((response) => {
        toast.success("Done successfully");
        dispatch(rentRequestNotApprovedAction());
      })
      .catch((error) => {
        toast.error(`Something went wrong`);
        console.log(error);
      });
  };
}

// FundActions.js

export function updateRentRequestList(data) {
  return {
    type: RENT_REQUEST_LIST,
    payload: data,
  };
}
