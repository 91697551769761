import React, { useEffect, useState } from "react";
import noImage from "../../../images/no-image.jpg";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { getSecurityDepositDetails } from "../../../services/SecurityDepositServices";
import Zoom from "react-medium-image-zoom";

function SecurityDepositsDetails() {
  const nav = useNavigate();
  const path = window.location.pathname.split("/");
  const securityDepositId = path[path.length - 1];
  const [securityDepositData, setSecurityDepositData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get security deposit details from API
    getSecurityDepositDetails(securityDepositId)
      .then((res) => {
        setSecurityDepositData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        nav("/page-error-400");
        setIsLoading(false);
      });
  }, [nav, securityDepositId]);

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  // Conditional Rendering for Payment Method Details
  const renderPaymentDetails = () => {
    const paymentMethod = securityDepositData?.payment_method;

    if (paymentMethod === "cheque") {
      const chequeDetails = securityDepositData?.cheque_details || {};
      return (
        <>
          <li className="list-group-item">
            <span className="mb-0 title">Cheque Date</span> :
            <span className="text-black ms-2">
              {chequeDetails.cheque_date || "N/A"}
            </span>
          </li>
          <li className="list-group-item">
            <span className="mb-0 title">Cheque Amount</span> :
            <span className="text-black ms-2">
              {chequeDetails.cheque_amount || "N/A"}
            </span>
          </li>
          <li className="list-group-item">
            <span className="mb-0 title">Signed By</span> :
            <span className="text-black ms-2">
              {chequeDetails.signed_by || "N/A"}
            </span>
          </li>
        </>
      );
    }

    if (paymentMethod === "online") {
      const onlineDetails = securityDepositData?.online_transfer_details || {};
      return (
        <>
          <li className="list-group-item">
            <span className="mb-0 title">Transaction Date</span> :
            <span className="text-black ms-2">
              {onlineDetails.date || "N/A"}
            </span>
          </li>
          <li className="list-group-item">
            <span className="mb-0 title">Amount</span> :
            <span className="text-black ms-2">
              {onlineDetails.amount || "N/A"}
            </span>
          </li>
          <li className="list-group-item">
            <span className="mb-0 title">Transferred By</span> :
            <span className="text-black ms-2">
              {onlineDetails.name || "N/A"}
            </span>
          </li>
        </>
      );
    }

    if (paymentMethod === "card") {
      const cardDetails = securityDepositData?.card_details || {};
      return (
        <>
          <li className="list-group-item">
            <span className="mb-0 title">Card Amount</span> :
            <span className="text-black ms-2">
              {cardDetails.amount || "N/A"}
            </span>
          </li>
          <li className="list-group-item">
            <span className="mb-0 title">Card Notes</span> :
            <span className="text-black ms-2">
              {cardDetails.notes || "N/A"}
            </span>
          </li>
        </>
      );
    }

    return (
      <li className="list-group-item">
        <span className="mb-0 title">No additional details available</span>
      </li>
    );
  };

  return (
    <>
      <div className="form-head page-titles d-flex  align-items-center ">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Security Deposit List</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/security-deposit-list">Security Deposit List</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={path}>Security Deposit Details</Link>
            </li>
          </ol>
        </div>

        <div className="gap-3 d-flex justify-content-center align-content-center">
          <Link
            onClick={() => window.location.reload()}
            className="btn btn-primary rounded light"
          >
            Refresh
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="front-view-slider mb-sm-5 mb-3 owl-carousel">
                    <div className="items">
                      <div className="front-view">
                        <Zoom>
                          <img
                            src={
                              securityDepositData?.images.length > 0
                                ? securityDepositData?.images
                                : noImage
                            }
                            alt=""
                          />
                        </Zoom>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-md-flex d-block mb-sm-5 mb-4">
                      <div className={`card-body pt-2 pb-3 `}>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <span className="mb-0 title">Voucher number</span> :
                            <span className="text-black ms-2">
                              {securityDepositData?.voucher_number || "N/A"}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Property Name</span> :
                            <span className="text-black ms-2">
                              {securityDepositData?.property?.property_name ||
                                "Name not available"}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Unit Name</span> :
                            <span className="text-black ms-2">
                              {securityDepositData?.unit?.name ||
                                "Name not available"}
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Status</span> :
                            <span className="text-black ms-2">
                              <Badge
                                bg="badge-rounded"
                                className={`${
                                  securityDepositData?.is_refundable
                                    ? "badge-outline-success"
                                    : "badge-outline-danger"
                                }`}
                              >
                                {securityDepositData?.is_refundable
                                  ? "Refundable"
                                  : "Not refundable"}
                              </Badge>
                            </span>
                          </li>
                          <li className="list-group-item">
                            <span className="mb-0 title">Payment Method</span> :
                            <span className="text-black desc-text ms-2">
                              {securityDepositData?.payment_method || "N/A"}
                            </span>
                          </li>
                          <div className="w-100 flex-row justify-content-center align-items-center">
                            <h2 className="subtitle pt-3 w-100 text-center">
                              Payment Details
                            </h2>
                          </div>
                          {renderPaymentDetails()}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecurityDepositsDetails;
