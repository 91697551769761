import { useState } from "react";

export default function ChequeForm({
  bankTransferDetails,
  setBankTransferDetails,
  onSubmit,
}) {
  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };
    if (!value) {
      fieldErrors[name] = `${name.replace("_", " ")} is required`;
    } else {
      delete fieldErrors[name];
    }
    setErrors(fieldErrors);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    Object.keys(bankTransferDetails).forEach((key) => {
      if (!bankTransferDetails[key]) {
        isValid = false;
        newErrors[key] = `${key.replace("_", " ")} is required`;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankTransferDetails((prev) => ({ ...prev, [name]: value })); // This should work if setBankTransferDetails is correctly passed
    validateField(name, value);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Cheque form</h4>
          </div>
          <div className="card-body">
            <form>
              <div className="row">
                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Cheque No <span className="text-danger">*</span>
                  </label>
                  <input
                    value={bankTransferDetails?.cheque_no || ""}
                    onChange={handleInputChange}
                    type="text"
                    className="form-control"
                    name="cheque_no"
                    placeholder="Enter cheque number"
                  />
                  <span className="text-danger">{errors.cheque_no}</span>
                </div>

                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Amount <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    value={bankTransferDetails?.amount}
                    onChange={handleInputChange}
                    className="form-control"
                    name="amount"
                    placeholder="Enter amount here"
                  />
                  <span className="text-danger">{errors.amount}</span>
                </div>

                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    name="date"
                    value={bankTransferDetails?.date}
                    onChange={handleInputChange}
                    className="form-control react-datepicker-popper"
                  />

                  <span className="text-danger">{errors.date}</span>
                </div>

                <div className="mb-3 col-lg-4 col-md-6">
                  <label className="form-label">
                    Transfer Type <span className="text-danger">*</span>
                  </label>
                  <div>
                    <input
                      type="radio"
                      id="incoming"
                      name="type"
                      value="Income"
                      onChange={handleInputChange}
                      checked={bankTransferDetails?.type === "Income"}
                    />
                    <label htmlFor="incoming" className="ms-2">
                      Income
                    </label>

                    <input
                      type="radio"
                      id="outgoing"
                      name="type"
                      value="Expense"
                      className="ms-4"
                      onChange={handleInputChange}
                      checked={bankTransferDetails?.type === "Expense"}
                    />
                    <label htmlFor="outgoing" className="ms-2">
                      Expense
                    </label>
                  </div>
                  <span className="text-danger">{errors.type}</span>
                </div>
              </div>
            </form>

            <div className="row">
              <div className="col-sm-12 pt-3">
                <button
                  type="button"
                  className="btn btn-sm btn-primary me-2"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
