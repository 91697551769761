import { DashboardAction } from "../../../store/actions/DashboardActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(DashboardAction(navigate));
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, [dispatch, navigate]);

  const { dashboard, loading } = useSelector((state) => state.dashboard);

  let country_code = "AED";

  // console.log(dashboard);

  if (loading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }
  console.log(dashboard);

  return (
    <>
      <div className="form-head d-md-flex mb-sm-4 mb-3 align-items-start">
        <div className="me-auto  d-lg-block">
          <h2 className="text-black font-w600">Dashboard</h2>
          <p className="mb-0">Welcome to Land Tech Admin</p>
        </div>
        <Link
          onClick={() => {
            //reload the window the page
            window.location.reload();
          }}
          className="btn btn-primary rounded light"
        >
          Refresh
        </Link>
      </div>

      <div className="row">
        <div className="w-100">
          <div className="row">
            <h4 className="fs-22 text-black text-center py-3">
              Daily statistics
            </h4>

            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bg-success-subtle">
                      <i className="flaticon-381-diamond d-flex justify-content-center align-items-center"></i>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Today's total income</p>
                      <h4 className="mb-0">
                        {country_code}{" "}
                        {dashboard?.todays_income
                          ? parseFloat(dashboard.todays_income).toFixed(2)
                          : "0.00"}
                      </h4>
                      {/* <span className="badge badge-warning">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-warning text-warning">
                      <svg
                        id="icon-orders"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-file-text"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Today's total expense</p>
                      <h4 className="mb-0">
                        {country_code}{" "}
                        {dashboard?.todays_expense
                          ? parseFloat(dashboard?.todays_expense).toFixed(2)
                          : "0.00"}
                      </h4>
                      {/* <span className="badge badge-warning">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-danger text-danger">
                      <svg
                        id="icon-revenue"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-dollar-sign"
                      >
                        <line x1="12" y1="1" x2="12" y2="23"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Today's total rent</p>
                      <h4 className="mb-0">
                        {country_code}{" "}
                        {dashboard?.todays_rent
                          ? parseFloat(dashboard?.todays_rent).toFixed(2)
                          : "0.00"}
                      </h4>
                      {/* <span className="badge badge-warning">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="fs-22 text-black text-center py-3">Stats</h4>
            <div className="col-xl-12">
              <div className="card bg-success light text-white">
                <div className="card-body">
                  <div className="media d-sm-flex d-block align-items-center">
                    <span className="me-4 d-block mb-sm-0 mb-3">
                      <svg
                        width={80}
                        height={80}
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M31.8333 79.1667H4.16659C2.33325 79.1667 0.833252 77.6667 0.833252 75.8333V29.8333C0.833252 29 1.16659 28 1.83325 27.5L29.4999 1.66667C30.4999 0.833332 31.8333 0.499999 32.9999 0.999999C34.3333 1.66667 34.9999 2.83333 34.9999 4.16667V76C34.9999 77.6667 33.4999 79.1667 31.8333 79.1667ZM7.33325 72.6667H28.4999V11.6667L7.33325 31.3333V72.6667Z"
                          fill="white"
                        />
                        <path
                          d="M75.8333 79.1667H31.6666C29.8333 79.1667 28.3333 77.6667 28.3333 75.8334V36.6667C28.3333 34.8334 29.8333 33.3334 31.6666 33.3334H75.8333C77.6666 33.3334 79.1666 34.8334 79.1666 36.6667V76C79.1666 77.6667 77.6666 79.1667 75.8333 79.1667ZM34.9999 72.6667H72.6666V39.8334H34.9999V72.6667Z"
                          fill="white"
                        />
                        <path
                          d="M60.1665 79.1667H47.3332C45.4999 79.1667 43.9999 77.6667 43.9999 75.8334V55.5C43.9999 53.6667 45.4999 52.1667 47.3332 52.1667H60.1665C61.9999 52.1667 63.4999 53.6667 63.4999 55.5V75.8334C63.4999 77.6667 61.9999 79.1667 60.1665 79.1667ZM50.6665 72.6667H56.9999V58.8334H50.6665V72.6667Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <div className="media-body mb-sm-0 me-5 justify-content-evenly">
                      <div className="d-flex justify-content-between ">
                        <h4 className="fs-22 text-white text-center">
                          Total Active Properties
                        </h4>
                        <span className="fs-35  font-w500">
                          {dashboard?.active_properties_count}
                        </span>
                      </div>
                      <div
                        className="progress mt-3 mb-3"
                        style={{ height: 8 }}
                      ></div>
                      <div className="d-flex justify-content-between">
                        <h4 className="fs-22 text-white text-center">
                          Total Inactive Properties
                        </h4>
                        <span className="fs-35 text-danger  font-w500">
                          {dashboard?.inactive_properties_count}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-warning text-warning">
                      <svg
                        id="icon-orders"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-file-text"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Active sales staff</p>
                      <h4 className="mb-0">
                        {dashboard?.active_sales_staff_count}
                      </h4>
                      {/* <span className="badge badge-warning">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-warning text-warning">
                      <svg
                        id="icon-customers"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Active supervisor staff</p>
                      <h4 className="mb-0">
                        {dashboard?.active_supervisor_count}
                      </h4>
                      {/* <span className="badge badge-warning">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-warning text-warning">
                      <svg
                        id="icon-database-widget"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Total Active Units</p>
                      <h4 className="mb-0">{dashboard?.active_units_count}</h4>
                      {/* <span className="badge badge-warning">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-warning text-warning">
                      <svg
                        id="icon-database-widget"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Total inactive Units</p>
                      <h4 className="mb-0 text-danger">
                        {dashboard?.inactive_units_count}
                      </h4>
                      {/* <span className="badge badge-warning">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card bg-info  text-white">
                <div className="card-body">
                  <div className="media d-sm-flex d-block align-items-center">
                    <span className="me-4 d-block mb-sm-0 mb-3">
                      <svg
                        id="icon-customers"
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                    </span>
                    <div className="media-body  mb-sm-0 mb-3 me-5">
                      <h4 className="fs-22 text-white">
                        Total investors count
                      </h4>
                      <div className="progress mt-3 mb-2" style={{ height: 8 }}>
                        {/* <div
                          className="progress-bar bg-white progress-animated"
                          style={{ width: "86%", height: 8 }}
                        >
                          <span className="sr-only">86% Complete</span>
                        </div> */}
                      </div>
                      {/* <span className="fs-14">
                        431 more to break last month record
                      </span> */}
                    </div>
                    <span className="fs-35 font-w500">
                      {dashboard?.total_investors_count}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
